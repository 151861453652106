import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 90vh;
`;

export const ProcedureContainer = styled.div`
  margin-right: -4px;
`;

export const ContainerRightSide = styled.div`
  width: 700px;
  padding: 24px;
  height: 85.5vh;
  overflow-y: auto;
  background-color: red;
`;

// export const CenterColumn = styled.div`
//   background-color: aqua;
//   width: 100%;
//   position: absolute;
//   right: 10%;
//   left: 20%;
// `;

// export const ContentBody = styled.div`
//   padding: 5%;
//   width: 50%;
//   height: 100%;
//   position: absolute;
// `;

export const CenterContainer = styled.div`
  //display: flex;
  flex-direction: column;
  width: 80%;
  padding-right: 30px;
  margin-right: -3px;
`;

export const InternalCenterContainer = styled.div`
  padding: 3%;
`;
