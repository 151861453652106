import { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import { ContainerLineOneTest, ContainerLineTwoTest } from "./styles";

// Function to calculate height based on resolution
function calculateHeightByResolution(resolution: any, aspectRatio: any) {
  const [width, height] = resolution.split("x").map(Number);
  const [aspectWidth, aspectHeight] = aspectRatio.split(":").map(Number);

  const aspectRatioMultiplier = aspectHeight / aspectWidth;
  const calculatedHeight = (width * aspectRatioMultiplier).toFixed(2);

  return parseFloat(calculatedHeight);
}

export default function SkeletonMaster() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const aspectRatio = "16:9"; // You can change this to the desired aspect ratio

  useEffect(() => {
    // Function to update screen resolution on window resize
    const updateScreenResolution = () => {
      setScreenWidth(window.screen.width);
      setScreenHeight(window.screen.height);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenResolution);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenResolution);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: screenWidth,
        height: screenHeight - 250,
        flexWrap: "wrap",
        overflow: "auto",
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      <ContainerLineOneTest>
        <Skeleton
          height={"100%"}
          width={(screenWidth - 40) / 2}
          count={1}
          style={{
            marginTop: 8,
          }}
        />
        <Skeleton
          height={"100%"}
          width={(screenWidth - 40) / 2}
          count={1}
          style={{
            marginTop: 8,
          }}
        />
      </ContainerLineOneTest>
      <ContainerLineTwoTest>
        <Skeleton
          height={"100%"}
          width={(screenWidth - 60) / 4}
          count={1}
          style={{}}
        />
        <Skeleton
          height={"100%"}
          width={(screenWidth - 60) / 4}
          count={1}
          style={{}}
        />
        <Skeleton
          height={"100%"}
          width={(screenWidth - 60) / 4}
          count={1}
          style={{}}
        />
        <Skeleton
          height={"100%"}
          width={(screenWidth - 60) / 4}
          count={1}
          style={{}}
        />
      </ContainerLineTwoTest>
    </div>
  );
}
