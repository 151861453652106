import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Editor } from "@tinymce/tinymce-react";
import { FiRefreshCw } from "react-icons/fi";
import { useDispatch } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { v4 } from "uuid";

import {
  Container,
  OptionListContainer,
  ContainerItem,
  Circle,
  ItemTitle,
  TimeContainer,
  TimeTitle,
  Time,
  ContainerStatus,
  ContainerButtonRefresh,
  StatusItem,
} from "./styles";
import { DropdownGlobal } from "../../../components/DropdownGlobal";
import { usePermissions } from "../../../hooks/permission";
import { useToast } from "../../../hooks/toast";
import { ICustomerTemplate } from "../../../pages/app/CustomerTemplate";
import { Transcription } from "../../../pages/app/hearingDetails";
import { transcription } from "../../../services/app/transcription";
import { customerTemplateService } from "../../../services/customerTemplate";
import { editorInit } from "../../../services/utils/tinyConfiguration";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORM_EXPORT_FILES } from "../../../utils/entities/permission";
import { formatYmd, isVisuallyEmpty } from "../../../utils/format";
import { useQuery } from "../../../utils/get";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import {
  ContainerButton,
  ContainerTemplate,
  NewMarkingButton,
  SpaceButton,
} from "../styles";

export interface ITranscriptionComponent {
  manualTranscriptionRef: MutableRefObject<any>;
  transcriptions: Transcription[] | never[];
  marking: any;
  file: any;
  modelList: Array<ICustomerTemplate>;
  getTranscription: () => Promise<void>;
  transcriptionProvider: string;
}

type TranscriptionModes = "automatico" | "manual";

const optionList = [
  { id: 1, value: "Transcrição manual", type: "manual" },
  { id: 2, value: "Transcrição automática", type: "automatico" },
];

export const TranscriptionComponent: React.FC<ITranscriptionComponent> = ({
  manualTranscriptionRef,
  transcriptions,
  marking,
  file,
  getTranscription,
  modelList,
  transcriptionProvider,
}) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [selectedTranscriptionMode, setSelectedTranscriptionMode] =
    useState<TranscriptionModes | null>(null);
  const [selectTemplate, setSelectTemplate] = useState<any>();
  const [tinyText, setTinyText] = useState<string>("");
  const permissions = usePermissions(FORM_EXPORT_FILES.HEARING_FILE);
  const user = getValueFromlocalStorage("@Kenta:user");
  const query = useQuery();

  const hearingId = query.get("hearingId");
  const passedUser = user ? JSON.parse(user) : null;

  const hasTranscriptions = !!transcriptions.length;
  const firstTranscription = useMemo(
    () => transcriptions[0] || null,
    [transcriptions]
  );
  const isTranscriptionFinished = firstTranscription?.status === 6;
  const isFirstTranscriptionValid =
    !!firstTranscription?.id && !isTranscriptionFinished;

  const shouldRenderModelInput = useMemo(
    () => selectedTranscriptionMode === "automatico" || isTranscriptionFinished,
    [selectedTranscriptionMode, isTranscriptionFinished]
  );

  const selectedModel =
    hasTranscriptions && !!modelList.length
      ? modelList.find((x) => x.id === transcriptions[0].customerTemplateId)
      : { name: "", template: "" };

  const shouldReloadAutoTranscription = useMemo(() => {
    if (transcriptions[0]) {
      let statusVerify = transcriptions[0].status;
      if (statusVerify >= 7 && statusVerify <= 13) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [transcriptions]);

  const getButtonEnabled = () => {
    if (firstTranscription === null) return true;
    if (hasTranscriptions && firstTranscription?.status === 6) return true;
    if (transcriptionProvider === "4" && firstTranscription?.status === 6)
      return true;
    return false;
  };

  const buttonEnabled = getButtonEnabled();

  const isButtonDisabled =
    !buttonEnabled &&
    (!shouldReloadAutoTranscription || !isFirstTranscriptionValid);

  const buttonLabel = shouldReloadAutoTranscription
    ? "Transcrever"
    : !hasTranscriptions
    ? "Iniciar"
    : "Salvar";

  const startTranscription = async () => {
    const existingTranscription = transcriptions[0];
    const content = manualTranscriptionRef.current?.getContent();
    const hasFile = !!file?.id;
    const hearingId = query.get("hearingId");

    const buildTranscriptionData = (isAuto: boolean): any => ({
      id: 0,
      guid: v4(),
      hearingFileId: file.id,
      userId: passedUser.id,
      customerTemplateId: isAuto ? selectTemplate?.id || 0 : 0,
      title: `Transcrição da oitiva nº: ${hearingId}`,
      autoTranscription: isAuto,
      createIn: formatYmd(new Date()),
      updateIn: formatYmd(new Date()),
      status: isAuto ? undefined : 6,
      sendEmail: isAuto,
      active: true,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    });

    const handleUpdateTranscription = async () => {
      if (!content) return;

      if (existingTranscription.autoTranscription) {
        await transcription.patchDisableAutoTranscription(
          existingTranscription.id
        );
      }

      const { success } = await transcription.patch({
        textPlain: content,
        id: existingTranscription.id,
      });

      if (selectTemplate) {
        await transcription.patchTemplate({
          customerTemplateId: selectTemplate.id,
          id: existingTranscription.id,
        });
      }

      if (success) {
        addToast({
          type: "success",
          title: "Transcrição atualizada.",
          description: "Sua transcrição foi atualizada com sucesso.",
        });
        await getTranscription();
      } else {
        addToast({
          type: "warning",
          title: "Transcrição não atualizada",
          description: "A transcrição não pôde ser atualizada.",
        });
      }
    };

    const handleCreateTranscription = async (isAuto: boolean) => {
      if (isAuto && !selectTemplate) {
        return addToast({
          type: "warning",
          title: "Aviso",
          description:
            "Selecione um modelo para criar a transcrição automática.",
        });
      }

      const data = buildTranscriptionData(isAuto);

      dispatch(showLoading());
      await transcription.post("/HearingTranscription/add", data);
      await getTranscription();
      dispatch(hideLoading());

      addToast({
        type: "success",
        title: `Transcrição ${isAuto ? "automática" : "manual"} ${
          existingTranscription ? "atualizada" : "criada"
        }.`,
        description: `Sua transcrição foi ${
          isAuto ? "iniciada" : "criada"
        } com sucesso.`,
      });
    };

    // === EXECUÇÃO ===

    if (existingTranscription?.id) {
      return handleUpdateTranscription();
    }

    if (!hasFile) {
      dispatch(hideLoading());
      return addToast({
        type: "warning",
        title: "Transcrição não criada",
        description: "Não há vídeo para transcrever.",
      });
    }

    if (!passedUser) return;

    const isAuto = selectedTranscriptionMode === "automatico";
    const isManual = selectedTranscriptionMode === "manual";

    if (isAuto || isManual) {
      return handleCreateTranscription(isAuto);
    }
  };

  const TranscriptionStatus = (status: number) => {
    switch (status) {
      case 0:
        return <StatusItem>Na fila</StatusItem>;
      case 1:
        return <StatusItem>Carregando vídeo</StatusItem>;
      case 2:
        return <StatusItem>Extraindo áudio</StatusItem>;
      case 3:
        return <StatusItem>Transferindo áudio</StatusItem>;
      case 4:
        return <StatusItem>Transcrevendo áudio</StatusItem>;
      case 5:
        return <StatusItem>Encerrando</StatusItem>;
      case 6:
        return <StatusItem>Encerrado</StatusItem>;
      case 7:
        return <StatusItem>Cancelado</StatusItem>;
      case 8:
        return <StatusItem>Erro ao carregar vídeo</StatusItem>;
      case 9:
        return <StatusItem>Erro ao extrair áudio</StatusItem>;
      case 10:
        return <StatusItem>Erro ao transferir áudio</StatusItem>;
      case 11:
        return <StatusItem>Erro ao transcrever áudio</StatusItem>;
      case 12:
        return <StatusItem>Erro ao debitar crédito</StatusItem>;
      case 13:
        return <StatusItem>Erro de processamento</StatusItem>;
      case 14:
        return <StatusItem>Saldo insuficiente</StatusItem>;
      case 16:
        return <StatusItem>Provedor não permitido</StatusItem>;
      default:
        return <StatusItem>Na fila</StatusItem>;
    }
  };

  const handleReloadAutoTranscription = useCallback(async () => {
    const { success } = await transcription.patchGeneric(
      "0",
      transcriptions[0].id.toString(),
      "status"
    );

    getTranscription();

    if (success) {
      addToast({
        type: "success",
        title: "Transcrição automatica iniciada.",
        description: "Sua transcrição foi iniciada com sucesso.",
      });
    } else {
      addToast({
        type: "error",
        title: "Transcrição não criada",
        description: "erro inesperado",
      });
    }
  }, [addToast, getTranscription, transcriptions]);

  useEffect(() => {
    if (hasTranscriptions) {
      setTinyText(transcriptions[0].textPlain);
    }
  }, [hasTranscriptions, transcriptions]);

  useEffect(() => {
    (async () => {
      if (selectTemplate && selectTemplate.template) {
        const { body } = await customerTemplateService.SetKeywords({
          hearingId: Number(hearingId),
          customerTemplateId:
            selectTemplate && selectTemplate.id ? selectTemplate.id : 0,
        });

        setTinyText(body);
      }
    })();
  }, [selectTemplate, hearingId]);

  const removeEmptyTexts = (frame: any) => {
    const tagsToClean = ["p", "strong", "span"];
    return tagsToClean.includes(frame.tag) && isVisuallyEmpty(frame.text);
  };

  return (
    <>
      {shouldRenderModelInput && (
        <ContainerTemplate>
          <div style={{ paddingLeft: "20px" }}>
            <DropdownGlobal
              labelName="Modelo"
              options={modelList as any}
              defaultValue={selectedModel ? selectedModel.name : ""}
              showDefaultValue={!!selectedModel && !!selectedModel.name}
              width="320px"
              setSelected={setSelectTemplate}
              wantAnObject
              disabled={isTranscriptionFinished}
            />
          </div>
        </ContainerTemplate>
      )}
      <Container
        hasModel={shouldRenderModelInput}
        hasTranscription={hasTranscriptions}
      >
        {hasTranscriptions ? (
          !isTranscriptionFinished ? (
            <>
              <ContainerButtonRefresh onClick={getTranscription}>
                <FiRefreshCw />
              </ContainerButtonRefresh>
              <ContainerStatus>
                {TranscriptionStatus(
                  Number(transcriptions ? transcriptions[0].status : 0)
                )}
              </ContainerStatus>
            </>
          ) : (
            <Editor
              onKeyDown={(event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                  event.preventDefault();
                  alert("Não é possivel copiar esse texto");
                }
              }}
              ref={manualTranscriptionRef}
              apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
              onInit={(evt, editor) =>
                (manualTranscriptionRef.current = editor)
              }
              init={{
                ...editorInit(permissions.export),
                newline_behavior: "linebreak",
              }}
              initialValue={
                hasTranscriptions && transcriptions[0].textPlain
                  ? transcriptions[0].textPlain
                  : ""
              }
              value={tinyText}
              onEditorChange={(content) => {
                const cleaned = sanitizeHtml(content, {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "img",
                  ]),
                  allowedAttributes: { img: ["src"] },
                  allowedSchemes: ["data", "http", "https"],
                  exclusiveFilter: removeEmptyTexts,
                });
                setTinyText(cleaned);
              }}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
          )
        ) : (
          <>
            <OptionListContainer>
              {optionList.map((item, index) => (
                <ContainerItem
                  key={item.id}
                  onClick={() => {
                    setSelectedTranscriptionMode(
                      item.type as TranscriptionModes
                    );
                  }}
                >
                  <Circle selected={selectedTranscriptionMode === item.type} />
                  <ItemTitle>{item.value}</ItemTitle>
                </ContainerItem>
              ))}
            </OptionListContainer>
            <TimeContainer>
              <TimeTitle>Tempo total: </TimeTitle>
              <Time>
                {marking.playerState && marking.playerState.duration
                  ? marking.playerState.duration
                  : "00:00"}
              </Time>
            </TimeContainer>
          </>
        )}
      </Container>

      <ContainerButton>
        <NewMarkingButton
          width={91}
          onClick={
            shouldReloadAutoTranscription
              ? handleReloadAutoTranscription
              : startTranscription
          }
          disabled={isButtonDisabled}
        >
          <strong>{buttonLabel}</strong>
        </NewMarkingButton>
      </ContainerButton>
    </>
  );
};
