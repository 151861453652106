import api from "../api";

export const storageKentaService = {
  GetAllBlobs: async (customerId: number) => {
    try {
      const { data } = await api.get(
        `/StorageKenta/GetAllBlobs?customerId=${customerId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: true,
        body: {},
      };
    }
  },
};
