import React, { useState, useEffect } from "react";

import {
  DropdownGlobal,
  IDropDown,
} from "../../../../components/DropdownGlobal";
import { InputGeneral } from "../../../../components/Input";
import { InputSwitch } from "../../../../components/InputSwitch";
import { AttendantRoleService } from "../../../../services/attendantRole";
import { getValueFromlocalStorage } from "../../../../utils/sessionStorageEncrypt";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "../../StyleCSS/bodyCreateEdit";

interface AttendantCreateEdit {
  valueEdit?: string;
  onChangeName: (e: string) => unknown;
  active: boolean;
  onChangeSwitch?: (e: boolean) => unknown;
  attendantRoleDropSelected?: any;
  setAttendantRoleDropSelected: (e: number) => unknown;
  attendantRoleName?: string;
  fieldError: boolean;
}

export const CreateEditAttendant = (
  props: AttendantCreateEdit
): JSX.Element => {
  const [dropDownAttendantRole, setDropDownAttendantRole] = useState<
    IDropDown[]
  >([]);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [editAttendantRoleName, setEditAttendantRoleName] = useState(
    props.attendantRoleName
  );

  useEffect(() => {
    (async () => {
      if (props) {
        if (user) {
          const user_parsed = JSON.parse(user);
          const response = await AttendantRoleService.getAll(
            user_parsed.customerId
          );
          const onlyActives = response.data.data.filter((e: any) => e.active);
          setDropDownAttendantRole(onlyActives);
        }
      }
    })();
  }, []);

  return (
    <>
      <Container>
        <ContainerBody>
          {props.onChangeSwitch && (
            <ContainerSwitch>
              <InputSwitch
                text="Ativo"
                checked={props.active}
                onChange={() =>
                  props.onChangeSwitch
                    ? props.onChangeSwitch(!props.active)
                    : null
                }
              />
            </ContainerSwitch>
          )}
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError}
              value={props.valueEdit}
              onChange={(e) => props.onChangeName(e)}
              type="text"
              label="Nome"
              required={true}
            />
          </ContainerInputs>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Tipo de participante"
              options={dropDownAttendantRole}
              selected={props.attendantRoleDropSelected}
              setSelected={props.setAttendantRoleDropSelected}
              error={props.fieldError}
              //setSelected={(e: any) => console.log("alterando participante", e)}
              editDropdown={editAttendantRoleName}
              required={true}
            />
          </ContainerInputs>
        </ContainerBody>
      </Container>
    </>
  );
};
