import React, { useCallback, useEffect, useState } from "react";

import Dropzone from "react-dropzone";

import {
  Container,
  ContainerBody,
  ContainerInputs,
  DropFileContainer,
  DropFileInput,
  DropLocationText,
  ClickLocationText,
  FileTitle,
  ContainerDelete,
  SizeText,
  ContainerDivDropzone,
} from "./style";
import delete_file_disabled from "../../assets/hearingDetails/delete_disable.svg";
import delete_file from "../../assets/hearingDetails/delete_file.svg";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { InputGeneral } from "../../components/Input";
import { useToast } from "../../hooks/toast";

export type IAttachmentChildrenModal = {
  setType: (e: any) => unknown;
  setName: (e: string) => unknown;
  setFile: (e: object | null) => unknown;
  editItem: any;
  isEdit: boolean;
  type: any;
  file: any;
  name: string;
  typeError: boolean;
  nameError: boolean;
  fileError: boolean;
};

export const AttachmentChildrenModal: React.FC<IAttachmentChildrenModal> = ({
  setName,
  setType,
  typeError,
  nameError,
  fileError,
  isEdit,
  editItem,
  name,
  type,
  file,
  setFile,
}): JSX.Element => {
  const [fileName, setFileName] = useState("");
  const [typeSelected, setTypeSelected] = useState<any>({
    "image/*": [".png", ".svg", ".jpeg", ".jpg"],
    "audio/*": [".wav", ".mp3", ".mp4"],
    "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  });
  const { addToast } = useToast();
  const listTypes = [
    { id: 2, value: "Áudio", name: "Áudio" },
    { id: 4, value: "Documento", name: "Documento" },
    { id: 3, value: "Foto", name: "Foto" },
    { id: 5, value: "Vídeo", name: "Vídeo" },
  ];

  useEffect(() => {
    if (editItem) {
      const item = editItem?.filename.split("\\");

      setFileName(item[item.length - 1]);
    }
  }, [editItem]);

  useEffect(() => {
    if (type) {
      switch (type.id) {
        case 2:
          setTypeSelected({
            "audio/*": [".wav", ".mp3", ".mp4"],
          });
          break;
        case 3:
          setTypeSelected({
            "image/*": [],
          });
          break;
        case 4:
          setTypeSelected({
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          });
          break;
        case 5:
          setTypeSelected({
            "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
          });
          break;

        default:
          setTypeSelected({
            "image/*": [".png", ".svg", ".jpeg", ".jpg"],
            "audio/*": [".wav", ".mp3", ".mp4"],
            "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          });
          break;
      }
    }
  }, [type]);

  const onChangeFiles = (e: any) => {
    setFile(e);
  };

  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else {
      return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
    }
  }

  const extension = fileName ? fileName.match(/\.[a-z0-9A-Z]{2,4}/i) : "";

  return (
    <Container>
      <ContainerBody>
        <ContainerInputs>
          <InputGeneral
            maxLength={100}
            value={name}
            onChange={setName}
            label="Nome do arquivo"
            required
            error={nameError}
          />
        </ContainerInputs>
        <ContainerInputs>
          <DropdownGlobal
            defaultValue={
              editItem
                ? listTypes.find((x) => x.id === editItem.type)?.name || ""
                : ""
            }
            setSelected={setType}
            editDropdown={isEdit}
            disabled={isEdit}
            options={listTypes}
            labelName="Tipo de arquivo"
            required={true}
            isNotFileDefault={
              isEdit && editItem
                ? !!listTypes.find((x) => x.id === editItem.type)?.name || false
                : false
            }
            wantAnObject={true}
            error={typeError}
          />
        </ContainerInputs>
        <DropFileContainer error={fileError && !file}>
          {file || isEdit ? (
            <>
              <FileTitle>
                {isEdit ? `${name}${extension}` : file.name}
              </FileTitle>
              <SizeText>
                {(isEdit ? editItem.size : file.size) / (1024 * 1024) >= 1
                  ? isEdit
                    ? formatFileSize(editItem.size)
                    : formatFileSize(file.size)
                  : isEdit
                  ? formatFileSize(editItem.size)
                  : formatFileSize(file.size)}
              </SizeText>
              <ContainerDelete disabled={isEdit} onClick={() => setFile(null)}>
                <img src={isEdit ? delete_file_disabled : delete_file} alt="" />
              </ContainerDelete>
            </>
          ) : (
            <Dropzone
              accept={typeSelected}
              disabled={!type}
              onDrop={(acceptedFiles) => {
                if (!type) {
                  return addToast({
                    type: "warning",
                    title: "Aviso",
                    description:
                      "Para inserir um arquivo é necessário selecionar o tipo primeiro.",
                  });
                }
                onChangeFiles(acceptedFiles[0]);
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <ContainerDivDropzone
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  error={fileError}
                >
                  {isDragActive ? (
                    <ClickLocationText>Solte aqui</ClickLocationText>
                  ) : (
                    <>
                      <DropLocationText>
                        {!type
                          ? "Selecione um arquivo"
                          : "Solte o arquivo aqui ou"}
                      </DropLocationText>
                      <ClickLocationText disabled={!type}>
                        clique para localizar
                      </ClickLocationText>
                    </>
                  )}
                  <DropFileInput {...getInputProps()} />
                </ContainerDivDropzone>
              )}
            </Dropzone>
          )}
        </DropFileContainer>
      </ContainerBody>
    </Container>
  );
};
