import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  width: 380px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  width: 380px;
  height: 22px;
  padding: 12px;
  text-align: center;
`;

export const ContainerBar = styled.div`
  width: 380px;
  height: 22px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const ContainerDescription = styled.div`
  width: 380px;
  height: 40px;
  padding: 0 12px 12px;
  text-align: center;
`;

export const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s;
  background-color: ${stylesGlobalDefault.blue};
  color: ${stylesGlobalDefault.white};
  border: 2px ${stylesGlobalDefault.blue} solid;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    border-color: ${stylesGlobalDefault.white};
    background-color: ${stylesGlobalDefault.blueDark};
    background: ${stylesGlobalDefault.blueDark};
  }
`;
