import { CardContainer, Content, LabelCard, Wrapper } from "./style";
import { IlistReport } from "../../components/Body/ReportArea/dtos";

export function CardReportItem({
  date,
  policeUnitId,
  policeUnitName,
  procedureNumber,
  source,
  type,
  background,
  notPointer,
}: IlistReport) {
  return (
    <CardContainer notPointer={notPointer} background={background}>
      <Wrapper>
        <Content>
          <LabelCard>{policeUnitId}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{policeUnitName}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{date}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{procedureNumber}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{source}</LabelCard>
        </Content>
        <Content>
          <LabelCard>{type}</LabelCard>
        </Content>
      </Wrapper>
    </CardContainer>
  );
}
