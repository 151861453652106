import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const HeadeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${stylesGlobalDefault.blackMenu1};
  padding: 0 25px;
  width: 315px;
  min-height: 80px;
  max-width: 360px;
  position: relative;
`;

export const Title = styled.div`
  width: 200px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  font-size: 18px;
  color: ${stylesGlobalDefault.white};
`;
