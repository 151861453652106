import { useState } from "react";

//Toast
import { useDispatch } from "react-redux";

//Permissões
import { ReportArea } from "../../../components/Body/ReportArea";
import { UserInterface } from "../../../components/Body/ReportArea/dtos";
import { useToast } from "../../../hooks/toast";
import { reportService } from "../../../services/report";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export function ProceduresByPoliceStation() {
  const today = new Date().toISOString().split("T")[0];
  const [element, setElement] = useState<UserInterface>();
  const [listReport, setListReport] = useState<UserInterface[]>([]);
  const [unitId, setUnitId] = useState();
  const user = getValueFromlocalStorage("@Kenta:user");
  const [initialDate, setInitialDate] = useState<any>(today);
  const [endDate, setendDate] = useState<any>(today);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let userCustomerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    userCustomerId = user_parsed.customerId;
  }

  async function onLoadList() {
    if (!userCustomerId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar um usuario",
      });
    }

    if (!unitId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma unidade de policia.",
      });
      return;
    }

    if (!initialDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar a data inicial.",
      });
      return;
    }

    if (!endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar a data final.",
      });
      return;
    }

    if (endDate >= initialDate) {
      dispatch(showLoading());
      const response = await reportService.getAllProceduresByPoliceStation(
        userCustomerId,
        unitId!,
        initialDate,
        endDate
      );
      if (response) {
        if (response.status) {
          setListReport(response.data.data);
        } else {
          addToast({
            type: "error",
            title: "Erro",
            description: response.message,
          });
        }
      }
    } else {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Data final deve ser igual ou maior que a data inicial.",
      });
    }
    dispatch(hideLoading());
  }
  function handleReport() {
    setIsActive(!isActive);
    onLoadList();
  }

  return (
    <>
      <ReportArea
        title={"Procedimentos por delegacia"}
        InputSearchLabel="Buscar por:"
        list={listReport}
        headerAddButtonOnClick={() => {}}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={handleReport}
        setItemReturnClick={setElement}
        setOpenModal={() => {}}
        setIsEdit={isActive}
        permissionName={FORMS_ADMIN.POLICE_UNIT}
        onChangeSituation={setUnitId}
        initialDate={initialDate}
        endDate={endDate}
        setInitialDate={setInitialDate}
        setEndDate={setendDate}
      />
    </>
  );
}
