import React, { useState } from "react";

import { FiEyeOff, FiEye } from "react-icons/fi";

import {
  Container,
  Label,
  InputText,
  ContainerInput,
  ContainerIcon,
  ContainerTitleWithBadge,
  ErrorText,
} from "./styles";
import { CircleBadge } from "../../Badges/Circle";

type IInput = {
  id?: string;
  width?: number;
  height?: number;
  label: string;
  value: string;
  icon?: boolean;
  marginBottom?: string;
  maxLength?: number;
  type?: "text" | "password" | "email";
  error: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: string) => unknown;
  disabled?: boolean;
  inputRef?: any;
  autoFocus?: boolean;
  required?: boolean;
  errorMessage?: string;
};

export const InputAuth: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  marginBottom = "22px",
  error = false,
  type = "text",
  setType,
  icon = false,
  width,
  height,
  inputRef,
  autoFocus,
  required,
  errorMessage,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const activeFocus = () => {
    setFocus(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
  };

  return (
    <>
      <Container marginBottom={marginBottom}>
        <ContainerTitleWithBadge>
          <Label>{label}</Label>
          {required && <CircleBadge radius="50px" widthHeigt="8px" />}
        </ContainerTitleWithBadge>
        <ContainerInput
          width={width}
          height={height}
          notError={!!value}
          error={error}
          focus={focus}
        >
          <InputText
            id={id}
            maxLength={maxLength}
            autoComplete="on"
            autoCapitalize="off"
            disabled={disabled}
            onFocus={activeFocus}
            onBlur={inactiveFocus}
            type={type}
            value={value}
            ref={inputRef}
            onChange={(e) => onChange(e.target.value)}
            autoFocus={autoFocus}
            required={required}
          />
          {icon && setType && (
            <ContainerIcon
              onClick={() => setType(type === "text" ? "password" : "text")}
              type="button"
            >
              {type === "text" ? (
                <FiEyeOff cursor="pointer" color="#7887A9" size={20} />
              ) : (
                <FiEye cursor="pointer" color="#7887A9" size={20} />
              )}
            </ContainerIcon>
          )}
        </ContainerInput>
        <ErrorText>{errorMessage}</ErrorText>
      </Container>
    </>
  );
};
