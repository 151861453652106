export const stringFormat = {
  verifyExistNameInArray: (
    list: Array<any>,
    name: string,
    id?: number | undefined
  ): boolean => {
    const finder = list.find((element: any) => {
      const normalizedElementName = element.name
        .replace(/\s/g, "")
        .toLowerCase();
      const normalizedName = name.replace(/\s/g, "").toLowerCase();
      return normalizedElementName === normalizedName && element.id !== id;
    });

    if (finder) {
      return true;
    }
    return false;
  },

  isEmptyOrSpaces: (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  },

  applyMask: (input: string, pattern: string) => {
    if (!input) return "";
    const value = input.toString().replace(/\D/g, "");
    let formattedValue = "";
    let i = 0;

    for (let p = 0; p < pattern.length; p++) {
      const currentPatternChar = pattern[p];

      if (currentPatternChar === "0") {
        if (i < value.length) {
          formattedValue += value[i];
          i++;
        } else {
          break;
        }
      } else {
        if (i < value.length) {
          formattedValue += currentPatternChar;
        } else {
          break;
        }
      }
    }
    return formattedValue.replace(/\\/g, "");
  },
};
