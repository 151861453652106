import { useEffect, useState } from "react";

import { Container, Title, Wrapper } from "./style";
import CheckIcon from "../../assets/svg/v.svg";

interface Props {
  label?: string;
  checked: () => void;
  style?: any;
  id?: number;
  type?: number;
  value?: any;
  activeId?: any;
}

export function CheckBoxConfidential({
  label,
  checked,
  style,
  id,
  value,
  activeId,
}: Props) {
  const [active, setActive] = useState(false);

  function handleClick() {
    const newActiveValue = !active;
    setActive(newActiveValue);
  }

  useEffect(() => {
    setActive(activeId === id);
  }, [activeId]);

  return (
    <Container style={style} onClick={checked}>
      <Wrapper active={value} onClick={() => handleClick()}>
        <img src={CheckIcon} width={12} alt="" />
      </Wrapper>
      <Title>{label}</Title>
    </Container>
  );
}
