import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CreateEditUnit } from "./CreateEdidtUnit";
import { CreateEditUserUnit } from "./CreateEditUser";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { UnitBody, userService } from "../../../services/users";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { setUserCreateUnit } from "../../../store/modules/userCreateUnit/actions";
import { LogOperation } from "../../../utils/entities/logOperation";

//Toast

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { verifyEmailFormat } from "../../../utils/verifyEmailFormat";

export interface UserInterface {
  id: number;
  name: string;
  email: string;
  password: string;
  picture: null;
  customerId: number;
  emailValidated: true;
  termAccepted: true;
  createIn: string;
  updateIn: string;
  active: boolean;
}
export interface IUserCreateUnit {
  userId: {
    id: number;
    name: string;
    email: string;
    customerId: number;
    termAccepted: boolean;
    active: boolean;
  };
  policeUnitId: {
    id: number;
    name: string;
    description: string;
    customerId: number;
    extraContent: string;
    active: boolean;
  };
  userCustomerProfileId: {
    id: number;
    type: number;
    label: string;
  };
  expireIn: string;
  active: boolean;
}

type ISituation = true | false | undefined;

export function Users() {
  //Atributes
  const [element, setElement] = useState<UserInterface>();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [active, setActive] = useState(true);
  const [extraContent, setExtraContent] = useState<any>();
  const [latLng, setLatLng] = useState<string>("");
  const [expireIn, setExpireIn] = useState<string | null>(null);
  const idUnit = useRef<any>(null);
  const [idProfile, setIdProfile] = useState<any>();
  const [profileLabel, setProfileLabel] = useState<any>();
  const [unitLabel, setunitLabel] = useState<any>();
  const { userCreateUnit } = useSelector((state: any) => state.userCreateUnit);
  const [requiredName, setRequiredName] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredUnitName, setRequiredUnitName] = useState(false);
  const [dropdownError, setDropdownError] = useState(false);
  const [search, setSearch] = useState("");

  const [dateSelected, setDateSelected] = useState();
  const [unitData, setUnitData] = useState([]);

  //error status
  const [fieldError, setFieldError] = useState<boolean>(false);

  const [listUserUnit, setListUserUnit] = useState<UserInterface[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openUnitModal, setOpenUnitModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [index, setIndex] = useState<number | null>(null);
  const [isEditCreatedUnit, setIsEditCreatedUnit] = useState(false);
  const [situation, setSituation] = useState<ISituation>(true || undefined);
  const user = getValueFromlocalStorage("@Kenta:user");

  const navigate = useNavigate();
  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  useEffect(() => {
    setRequiredUnitName(false);

    if (openUnitModal) {
      idUnit.current = null;
    }
  }, [openUnitModal]);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let userCustomerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    userCustomerId = user_parsed.customerId;
  }

  async function onLoadList() {
    dispatch(showLoading());

    const response = await userService.getAll(
      userCustomerId,
      listSearch(situation)
    );

    if (response) {
      if (response.status) {
        setListUserUnit(response.data.data);
      } else {
        addToast({
          type: "warning",
          title: response.message as string,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  async function clearAllComponentStates() {
    setIsEdit(false);
    setName("");
    setEmail("");
    setActive(true);
    setElement(undefined);
    setExtraContent("");
    setLatLng("");
    localStorage.removeItem("@unitData");
    localStorage.removeItem("editUnit");
    setOpenModal(false);
    setOpenUnitModal(false);
    setFieldError(false);
    dispatch(
      setUserCreateUnit({
        errorName: false,
        errorEmail: false,
      })
    );
  }

  async function openModalClearAdd() {
    dispatch(showLoading());

    try {
      const response = await api.get(
        `/UserCustomer/CheckAddPermission?customerId=${userCustomerId}`
      );
      if (response?.data?.ok) {
        dispatch(hideLoading());

        return setOpenModal(true);
      } else {
        dispatch(hideLoading());
        addToast({
          type: "error",
          title: response?.data?.message,
        });
      }
    } catch (error) {}
    clearAllComponentStates();
  }

  async function onClick(item: UserInterface) {
    setIsEdit(true);
    setName(item.name);
    setActive(item.active);
    setElement(item);
    setOpenModal(true);
    setExtraContent(item);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: userCustomerId,
      active: active,
      password: null,
      name: name,
      email: email,
      termAccepted: true,
      emailValidated: true,
      createIn: isEdit ? element?.createIn : new Date(),
      updateIn: new Date(),
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }
  function editUserBody() {
    return [
      {
        op: "replace",
        value: name,
        path: "/Name",
      },
      {
        op: "replace",
        value: active,
        path: "/Active",
      },
      {
        op: "2",
        value: LogOperation.getDevice(),
        path: "device",
      },
      {
        op: "2",
        value: LogOperation.getMachineIP(),
        path: "ip",
      },
      {
        op: "2",
        value: LogOperation.getPoliceUnitId(),
        path: "logPoliceUnitId",
      },
      {
        op: "2",
        value: LogOperation.getUserId(),
        path: "logUserId",
      },
    ];
  }

  function createUnitBody() {
    return {
      id: idUnit.current,
      userId: extraContent?.id,
      policeUnitLabel: unitLabel,
      policeUnitId: idUnit.current,
      userCustomerProfileId: idProfile,
      userCustomerProfileLabel: profileLabel,
      profileLabel: profileLabel,
      expireIn: dateSelected ? `${dateSelected}T23:59:59` : null,
      active: active,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  function createPostUnitBodyList(userId: number) {
    const createdUnitList = unitData;
    let idUnit = getValueFromlocalStorage("@Kenta:unidade_selecionada");
    let unitId = JSON.parse(idUnit!);

    if (!createdUnitList) {
      return [];
    }

    return createdUnitList.map((unitInList: any) => {
      return {
        id: 0,
        userId: userId,
        unitId: unitId?.unitId,
        policeUnitId: unitInList.policeUnitId,
        userCustomerProfileId: unitInList.userCustomerProfileId,
        expireIn: unitInList.expireIn ?? dateSelected ?? null,
        active: active,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };
    });
  }

  async function createUpdateUnitBody() {
    return {
      id: userCreateUnit?.userNewData?.id,
      userId: userCreateUnit?.userNewData?.userId?.id,
      policeUnitId: userCreateUnit?.userNewData?.policeUnitId,
      userCustomerProfileId: userCreateUnit?.userNewData?.userCustomerProfileId,
      expireIn: null,
      active: userCreateUnit?.userNewData?.active,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function post() {
    try {
      if (!name || !email) {
        dispatch(
          setUserCreateUnit({
            userData: userCreateUnit?.userData,
            errorName: !name,
            errorEmail: !email,
          })
        );

        addToast({
          type: "warning",
          title: "Criar usuários",
          description:
            "Para criar um novo usuário é necessário preencher os campos destacados.",
        });

        setRequiredName(!!name);
        setRequiredEmail(!!email);
        return;
      }

      if (!verifyEmailFormat(email)) {
        addToast({
          type: "error",
          title: "Email inválido",
          description: "Verifique se o email digitado é válido",
        });

        setRequiredEmail(true);
        dispatch(
          setUserCreateUnit({
            userData: userCreateUnit?.userData,
            errorEmail: true,
          })
        );

        return;
      }

      if (!userCreateUnit?.userData) {
        addToast({
          type: "warning",
          title: "Criar usuários",
          description:
            "Para criar um novo usuário é necessário vincular a uma unidade.",
        });
        return;
      }

      dispatch(showLoading());
      const resultPost = await userService.post("", createBody());

      const responseGetAll = await userService.getAll(userCustomerId, 1);

      if (!responseGetAll.status) {
        addToast({
          type: "warning",
          title: responseGetAll.message as string,
        });

        return;
      }

      let filteredUser = responseGetAll.data.data.find(
        (userInList: any) => userInList.email === email
      );

      const resultUnitPost = await userService.postUnit(
        createPostUnitBodyList(filteredUser.id)
      );

      if (resultUnitPost?.status === true) {
        addToast({
          type: "success",
          title: "Unidade cadastrada.",
          description: "Unidade foi cadastrada com sucesso.",
        });
        clearAllComponentStates();
        await onLoadList();
      } else {
        addToast({
          type: "error",
          title: "Falha ao cadastrar unidade.",
        });
      }
      if (resultPost.status === true) {
        addToast({
          type: "success",
          title: "Usuário cadastrado.",
          description: "Usuário foi cadastrada com sucesso.",
        });

        clearAllComponentStates();
        await onLoadList();
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPost.message,
        });

        return dispatch(hideLoading());
      }

      setOpenModal(false);
    } finally {
      dispatch(hideLoading());
    }
  }

  async function postUnit() {
    if (!idProfile) {
      let toastDescriptionText =
        "Para criar uma nova unidade é necessário preencher os campos destacados.";

      addToast({
        type: "warning",
        title: "Criar unidade",
        description: toastDescriptionText,
      });
      setDropdownError(true);
      return;
    }
    setDropdownError(false);

    if (isEdit || isEditCreatedUnit) {
      const response = createUnitBody();

      const pushList: Array<any> = [];

      if (
        userCreateUnit &&
        userCreateUnit.userData &&
        userCreateUnit.userData.length
      ) {
        userCreateUnit.userData.forEach((element: any, ind: number) => {
          if (ind === index) {
            return pushList.push(response);
          }
          pushList.push(element);
        });

        localStorage.removeItem("editUnit");
      }
      dispatch(
        setUserCreateUnit({
          userData: pushList,
        })
      );
    } else {
      localStorage.removeItem("editUnit");

      dispatch(
        setUserCreateUnit({
          userData: [...(userCreateUnit.userData ?? []), createUnitBody()],
        })
      );
    }

    setOpenUnitModal(false);
    setIsEditCreatedUnit(false);
    dispatch(hideLoading());
    dispatch(hideLoading());
  }

  async function put() {
    try {
      await userService.upDate(editUserBody(), extraContent?.id);
    } catch (error) {
      console.log(error);
    }

    const resultPost = await userService.put(createUpdateUnitBody());

    if (resultPost?.status === true) {
      addToast({
        type: "success",
        title: "Usuário atualizado",
        description: "Unidade foi atualizada com sucesso.",
      });
      clearAllComponentStates();
      await onLoadList();
    } else {
      addToast({
        type: "error",
        title: "Falha ao atualizar usuário.",
      });
    }

    // await userService.postUnit([createUnitBody()]);
    dispatch(showLoading());

    setOpenModal(false);
    dispatch(hideLoading());
  }

  async function deleteUser() {
    alert("click deleteProcedureCustomerSource");
  }

  const closeModal = () => {
    setOpenUnitModal(false);
    setIsEdit(false);
    setIsEditCreatedUnit(false);
    setDropdownError(false);
    idUnit.current = null;
  };

  function clearURLParams() {
    navigate(window.location.pathname, { replace: true });
  }

  const handleSitualtion = async (value: any) => {
    const filter = getValueFromlocalStorage("filter");

    if (value !== filter) {
      clearURLParams();
    }

    await localStorage.setItem("filter", value);

    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listUserUnit.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  let situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation
  );

  return (
    <>
      <AdminArea
        title={"Usuário"}
        InputSearchLabel="Buscar por:"
        searchText={search}
        situation={situation}
        InputSearchOnChange={(text) => setSearch(text)}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={(item: any) => onClick(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.POLICE_UNIT}
        onChangeSituation={(value: any) => handleSitualtion(value)}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clearAllComponentStates()}
        isEdit={isEdit}
        title={isEdit ? "Editando usuário " : "Criar novo usuário"}
        onClickAplicar={isEdit ? put : post}
        showIconDelete={false}
      >
        <CreateEditUserUnit
          isEdit={isEdit}
          name={name}
          email={email}
          setName={setName}
          setEmail={setEmail}
          openUnitModal={() => setOpenUnitModal(true)}
          onChangeName={(e) => setName(e)}
          onChangeEmail={(e) => setEmail(e)}
          extraContent={extraContent}
          onChangeExtraContent={(e) => setExtraContent(e)}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          latLng={latLng}
          onChangeLatLng={(e) => setLatLng(e)}
          userData={listUserUnit}
          fieldError={fieldError}
          customerId={userCustomerId}
          userId={extraContent?.id}
          nameError={requiredName}
          emailError={requiredEmail}
          unitData={setUnitData}
          isEditCreatedUnit={isEditCreatedUnit}
          setIsEditCreatedUnit={setIsEditCreatedUnit}
        />
      </ModalRight>
      <ModalRight
        isOpen={openUnitModal}
        setIsOpen={closeModal}
        isEdit={isEdit}
        title={"Dados da unidade"}
        onClickAplicar={postUnit}
        showIconDelete={false}
        onClickDelete={deleteUser}
        scroll={true}
      >
        <CreateEditUnit
          isEdit={isEdit}
          isEditCreatedUnit={isEditCreatedUnit}
          name={name}
          email={email}
          onChangeName={setName}
          onChangeEmail={setEmail}
          extraContent={extraContent}
          setIndex={setIndex}
          onChangeExtraContent={(e) => setExtraContent(e)}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          latLng={latLng}
          onChangeLatLng={(e) => setLatLng(e)}
          setUnitId={(value: any) => {
            idUnit.current = value;
          }}
          fieldError={fieldError}
          dropdownError={dropdownError}
          customerId={userCustomerId}
          userId={extraContent?.id}
          unitId={idUnit}
          profileId={idProfile}
          newDate={(value: any) => setDateSelected(value)}
          setProfileId={setIdProfile}
          setProfileLabel={setProfileLabel}
          setUnitLabel={setunitLabel}
          expireIn={expireIn}
          setExpireIn={setExpireIn}
          unitNameError={requiredUnitName}
        />
      </ModalRight>
    </>
  );
}
