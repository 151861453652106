//import { useEffect, useRef, useState } from "react";
import { Container, Title, Wrapper } from "./style";
import CheckIcon from "../../assets/svg/v.svg";
interface Props {
  label?: string;
  onSelectProfileId: (id: number) => unknown;
  selectProfileId: number;
  style?: any;
  item: any;
  type?: number;
  isAllItem?: boolean;
  value?: any;
  activeId?: any;
}
export function RadiusGlobal({
  label,
  item,
  style,
  onSelectProfileId,
  selectProfileId,
  isAllItem = false,
}: Props) {
  return (
    <Container
      style={style}
      onClick={() => onSelectProfileId(isAllItem ? item : item.id)}
    >
      <Wrapper id="radius" active={item.id === selectProfileId}>
        <img src={CheckIcon} width={12} alt="" />
      </Wrapper>
      <Title>{label}</Title>
    </Container>
  );
}
