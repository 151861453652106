import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const HearingService = {
  deleteHearingDto: async (body: any) => {
    try {
      const { data } = await api.delete("/Hearing/DeleteObject", body);

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
  deleteHearingDelete: async (id: number) => {
    try {
      const { data } = await api.delete(`/Hearing/Delete?id=${id}`);

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
  deleteHearing: async (authToken: string | null, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o dado correto",
      };
    }

    try {
      const bodyDeleteHearing = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];
      const { data } = await api.patch(
        `Hearing/Update?id=${hearingId}`,
        bodyDeleteHearing
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao deletar o registro.",
      };
    }
  },
  createHearingRecord: async (authToken: string, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(`Hearing/CreateMeeting`, {
        params: {
          hearingId: hearingId,
        },
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  getByHearingId: async (authToken: string, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(`HearingTokens/GetByHearingId`, {
        params: {
          hearingId: hearingId,
        },
      });

      let dataResponse = {
        token: data.authToken,
        sessionToken: data.deviceToken,
        urlModerator: data.internalLink,
        urlAttendee: data.externalLink,
        videoRoom: {
          id: data.roomId,
        },
      };

      return {
        status: true,
        data: dataResponse,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  getHearingById: async (hearingId: number) => {
    try {
      const { data } = await api.get(
        `/HearingFile/GetAllByHearingId?hearingId=${hearingId}`
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao pegar o video gravado.",
      };
    }
  },
  stopVideoRecording: async (
    roomId: number,
    authToken: string,
    deviceToken: string
  ) => {
    try {
      if (!authToken || !deviceToken) {
        return {
          status: false,
          message: "Autenticação inválida.",
        };
      }

      const { data } = await api.post(
        `/Hearing/StopVideoRecord`,
        {},
        {
          params: {
            roomId: roomId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao pegar o video gravado.",
      };
    }
  },
  startVideoRecording: async (
    roomId: number,
    authToken: string,
    deviceToken: string
  ) => {
    try {
      if (!authToken || !deviceToken) {
        return {
          status: false,
          message: "Autenticação inválida.",
        };
      }

      const { data } = await api.post(
        `/Hearing/StartVideoRecord`,
        {},
        {
          params: {
            roomId: roomId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getHearingDto: async (hearingId: number) => {
    try {
      const { data } = await api.get(
        `/Hearing/GetHearingDTO?hearingId=${hearingId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: true,
        body: {},
      };
    }
  },
  renewTokens: async (authToken: string, roomId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }
    if (!roomId) {
      return {
        status: false,
        message: "Não é possivel fazer o renew token sem o roomid .",
      };
    }

    try {
      const { data } = await api.get(`Hearing/RenewTokens?roomId=${roomId}`);

      let dataResponse = {
        authToken: data.authToken,
        deviceToken: data.deviceToken,
      };

      return {
        status: true,
        data: dataResponse,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  logoutUna: async () => {
    try {
      await api.get(
        `${process.env.REACT_APP_UNABASEURL}/una/auth/v1/logout`,
        {}
      );
    } catch (error) {}
  },
};
