import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CreateEditAttendant } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import { AttendantService } from "../../../services/attendant";
import { AttendantRoleService } from "../../../services/attendantRole";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";

//Toast

//Permissões

import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

type ISituation = true | false | undefined;

export interface AttendantInterface {
  id: number;
  customerId: number;
  attendantRoleId: number;
  active: boolean;
  name: string;
  foreignKey?: string;
  createIn: Date;
  updateIn: Date;
}

export function Attendant() {
  const [listAttendant, setListAttendant] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameAttendant, setNameAttendant] = useState<string>("");
  const [element, setElement] = useState<AttendantInterface>();
  const [active, setActive] = useState(true);
  const [attendantRoleId, setAttendantRoleId] = useState<number>(0);
  const [attendantRoleName, setAttendantRoleName] = useState<string>("");
  const user = getValueFromlocalStorage("@Kenta:user");
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<ISituation>(true || undefined);

  const [fieldError, setFieldError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response = await AttendantService.getAll(
      customerId,
      listSearch(situation)
    );
    if (response) {
      if (response.status) {
        setListAttendant(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  function clear() {
    setIsEdit(false);
    setNameAttendant("");
    setAttendantRoleId(0);
    setActive(true);
    setElement(undefined);
    setAttendantRoleName("");
    setOpenModal(false);
    setFieldError(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  async function onClickAttendant(item: AttendantInterface) {
    await getNameAttendantRole(item.attendantRoleId);
    setIsEdit(true);
    setNameAttendant(item.name);
    setActive(item.active);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      attendantRoleId:
        attendantRoleId === 0 ? element?.attendantRoleId : attendantRoleId,
      active: active,
      name: nameAttendant,
      foreignKey: element?.foreignKey,
      createIn: isEdit ? element?.createIn : new Date(),
      updateIn: new Date(),
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function getNameAttendantRole(attendatRoleId: number) {
    const selectRole = await AttendantRoleService.getById(attendatRoleId);
    setAttendantRoleName(selectRole.data.data.name);
  }

  async function postAttendant() {
    if (
      !nameAttendant ||
      stringFormat.isEmptyOrSpaces(nameAttendant) ||
      attendantRoleId === 0
    ) {
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para criar um participante é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    dispatch(showLoading());

    const resultPost = await AttendantService.post("", createBody());
    if (resultPost.status === true) {
      addToast({
        type: "success",
        title: "Sucesso",
        description: "Participante foi cadastrado com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPost.status) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPost.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "O nome já existe na base de dados",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function putAttendant() {
    if (!nameAttendant || stringFormat.isEmptyOrSpaces(nameAttendant)) {
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para atualizar um participante é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    dispatch(showLoading());

    const existName = stringFormat.verifyExistNameInArray(
      listAttendant,
      nameAttendant,
      element?.id
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return dispatch(hideLoading());
    }

    const resultPut = await AttendantService.put("", createBody());
    if (resultPut.status === true) {
      addToast({
        type: "success",
        title: "Participante atualizado",
        description: "Participante foi atualizado com sucesso.",
      });
      clear();
      await onLoadList();
      if (resultPut.status === true) {
        await onLoadList();
      } else {
        if (!resultPut.status) {
          addToast({
            type: resultPut.severity,
            title: "Erro",
            description: resultPut.message,
          });
        }
      }
    }
    dispatch(hideLoading());
  }

  async function deleteAttendant() {
    alert("click deleteProcedureCustomerSource");
  }
  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listAttendant.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  let situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation
  );

  return (
    <>
      <AdminArea
        title={"Participantes"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={(item: any) => onClickAttendant(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.ATTENDANT}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={isEdit ? "Editando participante " : "Criar participante "}
        onClickAplicar={isEdit ? putAttendant : postAttendant}
        showIconDelete={false}
        onClickDelete={deleteAttendant}
      >
        <CreateEditAttendant
          onChangeName={setNameAttendant}
          valueEdit={nameAttendant}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          setAttendantRoleDropSelected={setAttendantRoleId}
          attendantRoleName={attendantRoleName}
          fieldError={fieldError}
        />
      </ModalRight>
    </>
  );
}
