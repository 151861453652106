import { BrowserRouter } from "react-router-dom";
import { Router } from "routers";

import GlobalStyle from "global/styles";

import { LoadingToast } from "components/LoadingToast";
import "react-tooltip/dist/react-tooltip.css";

// import DevModeBadge from "components/DevMode";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <LoadingToast />
      <Router />
      {/*  <DevModeBadge /> */}
    </BrowserRouter>
  );
};

export default App;
