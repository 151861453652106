import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { CreateEditPoliceUnit } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";

//Toast

import { useToast } from "../../../hooks/toast";
import { PoliceUnitService } from "../../../services/policeUnit";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

type ISituation = true | false | undefined;
export interface PoliceUnitInterface {
  id: number;
  foreignKey: string;
  name: string;
  description: string;
  city: string;
  address: string;
  addressNumber: number;
  phone: string;
  regional: boolean;
  regionalCode: number;
  customerId: number;
  //lat: DoubleRange;
  //lon: DoubleRange;
  lat: number;
  lon: number;
  extraContent: string;
  createIn: Date;
  updateIn: Date;
  active: boolean;
  type: number;
}

export function PoliceUnit() {
  //Atributes
  const [element, setElement] = useState<PoliceUnitInterface>();
  const [name, setName] = useState<string>("");
  const [active, setActive] = useState(true);
  const [description, setDescription] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [addressNumber, setAddressNumber] = useState<number>(0);
  const [phone, setPhone] = useState<string>("");
  const [regional, setRegional] = useState<boolean>(false);
  const [regionalCode, setRegionalCode] = useState<number>(0);
  const [lat, setLat] = useState<number>(0);
  const [lon, setLon] = useState<number>(0);
  const [extraContent, setExtraContent] = useState<string>("");
  const [latLng, setLatLng] = useState<string>("");
  const [foreignKey, setForeignKey] = useState<string>("");

  //error status
  const [fieldError, setFieldError] = useState<boolean>(false);

  const [listPoliceUnit, setListPoliceUnit] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<ISituation>(true || undefined);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response = await PoliceUnitService.getAll(
      customerId,
      listSearch(situation)
    );
    if (response) {
      if (response.status) {
        setListPoliceUnit(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  async function clear() {
    setIsEdit(false);
    setName("");
    setActive(true);
    setElement(undefined);

    setDescription("");
    setCity("");
    setAddress("");
    setAddressNumber(0);
    setPhone("");
    setRegional(true);
    setRegionalCode(0);
    setLat(0);
    setLon(0);
    setExtraContent("");
    setLatLng("");
    setForeignKey("");

    setOpenModal(false);
    setFieldError(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  async function onClick(item: PoliceUnitInterface) {
    setIsEdit(true);
    setName(item.name);
    setActive(item.active);
    setDescription(item.description);
    setCity(item.city);
    setAddress(item.address);
    setAddressNumber(item.addressNumber);
    setPhone(item.phone);
    setRegional(item.regional);
    setRegionalCode(item.regionalCode);
    setLat(item.lat);
    setLon(item.lon);
    setExtraContent(item.extraContent);
    setForeignKey(item.foreignKey);

    setLatLng(item.lat + "," + item.lon);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      active: active,
      name: name.trimStart().trimEnd(),
      description: description.trimStart().trimEnd(),
      city: city.trimStart().trimEnd(),
      address: address.trimStart().trimEnd(),
      addressNumber: addressNumber,
      phone: phone ? phone.replace(/\D/g, "") : "",
      regional: regional,
      regionalCode: regionalCode,
      lat: 0, //lat,
      lon: 0, //lon,
      extraContent:
        !extraContent || stringFormat.isEmptyOrSpaces(extraContent)
          ? null
          : extraContent.trimStart().trimEnd(),
      //foreignKey: v4(),
      foreignKey:
        !foreignKey || stringFormat.isEmptyOrSpaces(foreignKey)
          ? null
          : foreignKey.trimStart().trimEnd(),
      //foreignKey: foreignKey,
      //extraContent: extraContent,
      createIn: isEdit ? element?.createIn : new Date(),
      updateIn: new Date(),
      type: isEdit ? element?.type : 0,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  function validateFilds() {
    if (!name || stringFormat.isEmptyOrSpaces(name)) {
      return false;
    } else if (!description || stringFormat.isEmptyOrSpaces(description)) {
      return false;
    } else if (!city || stringFormat.isEmptyOrSpaces(city)) {
      return false;
    } else if (!address || stringFormat.isEmptyOrSpaces(address)) {
      return false;
    } else if (!addressNumber) {
      return false;
    } else if (!phone || stringFormat.isEmptyOrSpaces(phone)) {
      return false;
    } else {
      return true;
    }
  }

  //Não está altenando os States de LAT e LON
  function splitLatLng() {
    try {
      let valueFormat = latLng.split(",");
      let latAux = parseFloat(valueFormat[0].trimEnd().trimStart());
      let lngAux = parseFloat(valueFormat[1].trimEnd().trimStart());

      setLat(latAux);
      setLon(lngAux);

      return true;
    } catch (e) {
      console.log("ocorreu erro", e);
      return false;
    }
  }

  async function post() {
    if (!validateFilds()) {
      addToast({
        type: "warning",
        title: "Unidade de polícia",
        description:
          "Para criar uma unidade de polícia é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    if (!addressNumber || addressNumber < 1) {
      addToast({
        type: "warning",
        title: "Unidade de polícia",
        description: "Número deve ser maior que zero.",
      });
      setFieldError(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(listPoliceUnit, name);

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return;
    }

    // if(!splitLatLng()){
    //   addToast({
    //     type: "error",
    //     title: "Erro no formato do campo latitude, longitude",
    //   });
    //   return;
    // }

    dispatch(showLoading());
    setOpenModal(false);

    const resultPost = await PoliceUnitService.post("", createBody());
    if (resultPost.status === true) {
      addToast({
        type: "success",
        title: "Unidade de policia cadastrada",
        description: "Unidade de policia foi cadastrada com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPost.status) {
        addToast({
          type: resultPost.severity,
          title: "Erro",
          description: resultPost.message,
        });
      }
      addToast({
        type: "error",
        title: "Falha ao criar a unidade de policia.",
      });
    }

    dispatch(hideLoading());
  }

  async function put() {
    if (!validateFilds()) {
      addToast({
        type: "warning",
        title: "Unidade de polícia",
        description:
          "Para atualizar uma unidade de polícia é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    if (!addressNumber || addressNumber < 1) {
      addToast({
        type: "warning",
        title: "Unidade de polícia",
        description: "Número deve ser maior que zero.",
      });
      setFieldError(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listPoliceUnit,
      name,
      element?.id
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return;
    }

    // if(!splitLatLng()){
    //   addToast({
    //     type: "error",
    //     title: "Erro no formato do campo latitude, longitude",
    //   });
    //   return;
    // }
    dispatch(showLoading());

    setOpenModal(false);
    const resultPut = await PoliceUnitService.put("", createBody());
    if (resultPut.status === true) {
      addToast({
        type: "success",
        title: "Unidade de policia atualizada",
        description: "A unidade de policia foi atualizada com sucesso.",
      });
      await clear();
      await onLoadList();
    } else {
      if (!resultPut.status) {
        addToast({
          type: resultPut.severity,
          title: "Erro",
          description: resultPut.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao atualizar a unidade de policia",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function deleteP() {
    alert("click deleteProcedureCustomerSource");
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listPoliceUnit.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  let situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation
  );

  return (
    <>
      <AdminArea
        title={"Unidades de polícia"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={(item: any) => onClick(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.POLICE_UNIT}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={
          isEdit ? "Editando unidade de polícia " : "Criar unidade de polícia "
        }
        onClickAplicar={isEdit ? put : post}
        showIconDelete={false}
        onClickDelete={deleteP}
      >
        <CreateEditPoliceUnit
          isEdit={isEdit}
          name={name}
          onChangeName={(e) => setName(e)}
          description={description}
          onChangeDescription={setDescription}
          city={city}
          onChangeCity={(e) => setCity(e)}
          address={address}
          onChangeAddress={(e) => setAddress(e)}
          addressNumber={addressNumber}
          onChangeAddressNumber={(e) => setAddressNumber(e)}
          phone={phone}
          onChangePhone={(e) => setPhone(e)}
          regional={regional}
          onChangeRegional={() => setRegional(!regional)}
          regionalCode={regionalCode}
          onChangeRegionalCode={(e) => setRegionalCode(e)}
          extraContent={extraContent}
          onChangeExtraContent={(e) => setExtraContent(e)}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          latLng={latLng}
          onChangeLatLng={(e) => setLatLng(e)}
          foreignKey={foreignKey}
          onChangeForeignKey={(e) => setForeignKey(e)}
          fieldError={fieldError}
          //errors
        />
      </ModalRight>
    </>
  );
}
