import React from "react";

import { format } from "date-fns";

import { Container, DateContainer } from "./styles";
import { stringFormat } from "../../utils/stringFormat";
import { ProcedureItemSidebar } from "../ProcedureItemSidebar";
import { TitleSidebar } from "../TitleSidebar";

export type IProcedureDataSidebar = {
  itemData?: any;
  onEditItem?: () => void;
  pattern: string;
};

export const ProcedureDataSidebar: React.FC<IProcedureDataSidebar> = ({
  itemData,
  onEditItem,
  pattern,
}) => {
  return (
    <Container>
      <TitleSidebar
        titleSize={18}
        title={`Procedimento ${
          itemData
            ? stringFormat.applyMask(itemData.procedureNumber, pattern)
            : "######"
        }`}
        onClickEdit={onEditItem}
      />
      <ProcedureItemSidebar
        title="Origem"
        description={itemData ? itemData.procedureCustomerSourceName : "Nada"}
      />
      <DateContainer>
        <ProcedureItemSidebar
          title="Data de criação"
          description={
            itemData
              ? format(new Date(itemData.expectedDate), "dd/MM/yyyy")
              : "nada"
          }
        />
        <ProcedureItemSidebar
          title="Prazo"
          description={
            itemData
              ? format(new Date(itemData.deadLineDate), "dd/MM/yyyy")
              : "nada"
          }
        />
      </DateContainer>
    </Container>
  );
};
