import styled from "styled-components";

interface IContainer {
  hasTranscription?: boolean;
}

export const Container = styled.div<IContainer>`
  // height: ${(props) => (props.hasTranscription ? "62%" : "77%")};
  // padding: ${(props) => (props.hasTranscription ? 0 : 24)}px;

  // @media screen and (min-height: 848px) {
  //   height: ${(props) => (props.hasTranscription ? "67.5%" : "65%")};
  // }

  // @media screen and (min-height: 665px) and (max-height: 848px) {
  //   height: ${(props) => (props.hasTranscription ? "58%" : "65%")};
  // }
`;

export const FooterContainer = styled.div`
  display: flex;
  height: 40px;
  background: #d1dbe3;
  padding: 24px 30px;
  width: calc(100% - 54px);
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  width: 91px;
  height: 34px;
  font-size: 11px;
  border: 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d9bf0;
  cursor: pointer;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  padding: 0px 40px;
`;

export const ContainerTemplate = styled.div`
  width: 100%;
  height: 60vh;

  @media screen and (min-width: 1600px) {
    height: 70vh;
  }
`;

export const StatusText = styled.div`
  width: 100%;
  font-size: 14px;

  text-align: center;
  font-weight: 500;
  color: rgb(120, 135, 169);
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;
