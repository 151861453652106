import { forwardRef, useImperativeHandle, useState } from "react";

import { SHA1 as sha1 } from "crypto-js";
import { useDispatch } from "react-redux";

import {
  Container,
  ContainerBar,
  ContainerDescription,
  ContainerTitle,
} from "./style";
import { useToast } from "../../hooks/toast";
import { PasswordService } from "../../services/password";
import { LogOperation } from "../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import { InputAuth } from "../Login/Input";
import BarComponent from "../StrongBar";
import { Typhography } from "../Typhography";

type InputType = "email" | "password" | "text" | undefined;
type StrengthLevel = "weak" | "medium" | "strong";

interface ChangePasswordProps {
  isLogged: boolean;
  oldPassword: string;
  setOldPassword: (e: string) => any;
  newPassword: string;
  setNewPassword: (e: string) => any;
  confirmPassword: string;
  setConfirmPassword: (e: string) => any;
  closeModal: () => void;
}

export const ChangePassword = forwardRef((props: ChangePasswordProps, ref) => {
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [strengthLevel, setStrengthLevel] = useState<StrengthLevel>("weak");
  const [passwordType, setPasswordType] = useState<{
    oldPassword?: InputType;
    newPassword?: InputType;
    confirmPassword?: InputType;
  }>({
    oldPassword: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const storedPassword = getValueFromlocalStorage("@Kenta:password");

  const { addToast } = useToast();

  function evaluatePasswordStrength(password: string): boolean {
    if (password.length < 8) {
      setStrengthLevel("weak");
      return false;
    }

    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()\-_=+[\]{};':"\\|,.<>/?]/.test(password);

    if (!hasLowercase || !hasUppercase || !hasDigit || !hasSpecialChar) {
      setStrengthLevel("medium");
      return false;
    }
    setStrengthLevel("strong");
    return true;
  }

  const isValidForm = () => {
    let errors = false;

    setPasswordError("");
    setConfirmPasswordError("");

    if (!props.oldPassword && !props.newPassword && !props.confirmPassword) {
      return false;
    }

    if (!props.oldPassword && props.isLogged) {
      setOldPasswordError("A senha atual não pode estar vazia.");
      errors = true;
    }

    if (!props.newPassword) {
      setPasswordError("A nova senha não pode estar vazia.");
      errors = true;
    }

    if (!props.confirmPassword) {
      setConfirmPasswordError("Confirme a nova senha.");
      errors = true;
    }

    if (sha1(props.newPassword).toString().toUpperCase() === storedPassword) {
      setPasswordError(
        "Para continuar insira a senha atual correta, de acordo com as regras determinadas acima."
      );
      errors = true;
    }

    if (props.newPassword && !evaluatePasswordStrength(props.newPassword)) {
      setPasswordError("A nova senha não atende aos critérios de segurança.");
      errors = true;
    }

    if (
      props.newPassword &&
      props.confirmPassword &&
      props.newPassword !== props.confirmPassword
    ) {
      setConfirmPasswordError("As senhas não correspondem.");
      errors = true;
    }

    return !errors;
  };

  async function handleSubmitNewPassword() {
    const isValid = isValidForm();

    if (!isValid) return;

    try {
      const hashedNewPassword = sha1(props.newPassword)
        .toString()
        .toUpperCase();
      const user = getValueFromlocalStorage("@Kenta:user");
      if (user) {
        const userParsed = JSON.parse(user);
        const body = {
          id: userParsed.id,
          name: userParsed.name,
          email: userParsed.email,
          password: hashedNewPassword,
          picture: userParsed.picture,
          customerId: userParsed.customerId,
          emailValidated: true,
          termAccepted: true,
          createIn: userParsed.createIn,
          updateIn: userParsed.updateIn,
          active: true,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        const { status, message } = await PasswordService.resetPasswordBody(
          body
        );

        if (!status) {
          setPasswordError("Erro ao redefinir senha: " + message);
        } else {
          props.setOldPassword("");
          props.setNewPassword("");
          props.setConfirmPassword("");
          addToast({
            type: "success",
            title: "Sucesso",
            description: "Senha redefinida com sucesso!",
          });
          props.closeModal();
        }
      }
    } catch (err: any) {
      addToast({
        type: "warning",
        title: "Erro",
        description: err?.response?.data?.message || "Erro desconhecido.",
      });
    }
  }

  const updatePasswordType = (field: string, type: InputType) => {
    setPasswordType((prevState) => ({ ...prevState, [field]: type }));
  };

  const handleInputChange = (
    field: string,
    value: string,
    setter: (value: string) => void,
    setError: (value: string) => void
  ) => {
    setter(value);
    setError("");

    if (field === "newPassword") {
      evaluatePasswordStrength(value);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmitNewPassword,
  }));

  return (
    <Container>
      <ContainerTitle>
        <Typhography fontStyle="bold" size="very-bigger">
          Redefinir senha
        </Typhography>
      </ContainerTitle>
      <ContainerDescription>
        <Typhography fontStyle="regular" size="medium">
          Dica: utilize letras maiúsculas, minúsculas, números e caracteres
          especiais (@ & $ % #) para criar uma senha forte.
        </Typhography>
      </ContainerDescription>

      {props.isLogged ? (
        <InputAuth
          id="signIn_password"
          maxLength={200}
          width={380}
          label="Senha atual"
          icon={true}
          errorMessage={oldPasswordError}
          setType={(type: InputType) => updatePasswordType("oldPassword", type)}
          type={passwordType.oldPassword}
          value={props.oldPassword}
          error={!!oldPasswordError}
          onChange={(value) =>
            handleInputChange(
              "oldPassword",
              value,
              props.setOldPassword,
              setOldPasswordError
            )
          }
        />
      ) : null}
      <InputAuth
        id="new_password"
        maxLength={200}
        width={380}
        label="Nova senha"
        icon={true}
        setType={(type: InputType) => updatePasswordType("newPassword", type)}
        type={passwordType.newPassword}
        value={props.newPassword}
        error={!!passwordError}
        errorMessage={passwordError}
        onChange={(value) =>
          handleInputChange(
            "newPassword",
            value,
            props.setNewPassword,
            setPasswordError
          )
        }
      />

      <ContainerBar>
        <BarComponent strongLevel={strengthLevel} />
      </ContainerBar>

      <InputAuth
        id="repeat_password"
        maxLength={200}
        width={380}
        icon={true}
        setType={(type: InputType) =>
          updatePasswordType("confirmPassword", type)
        }
        type={passwordType.confirmPassword}
        label="Confirmar nova senha"
        value={props.confirmPassword}
        errorMessage={confirmPasswordError}
        error={!!confirmPasswordError}
        onChange={(value) =>
          handleInputChange(
            "confirmPassword",
            value,
            props.setConfirmPassword,
            setConfirmPasswordError
          )
        }
      />
    </Container>
  );
});
