import { configureStore } from "@reduxjs/toolkit";

import rootReducer, { ThunkExtraArg } from "./rootReducer";

export const configureAppStore = (addToast: ThunkExtraArg["addToast"]) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { addToast },
        },
      }),
  });
};

export type AppStore = ReturnType<typeof configureAppStore>;
export type AppDispatch = AppStore["dispatch"];

let store: AppStore;

export const initializeStore = (addToast: ThunkExtraArg["addToast"]) => {
  store = configureAppStore(addToast);
  return store;
};

export const getStore = () => store;
