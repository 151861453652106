//STATUS DA OITIVA
// Agendado = "A";
// Finalizado = "E";
// Publicado = "P";
// Notificado = "N";
// Em_andamento = "G";
// Inconsistente = "O";
// Iniciando = "I";
// Erro = "R";
// Cancelado = "C";
// Analisando = "Y";
// Processando = "S";
// Downloading = "D";
// Uploading = "U";
// Process_test = "Z";

import { useState, useCallback, useEffect, useMemo } from "react";

import { MdOutbox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//imagens
import { usePermissions } from "hooks/permission";
import { useToast } from "hooks/toast";

import { hideLoading, showLoading } from "store/modules/login/actions";

import { DownloadFilesModal } from "features/DownloadFiles";

import { ItemDetailLine } from "components/Hearing/ItemsDetails";

import { storageBaseUrl } from "services/api";
import { HearingService } from "services/hearing";
import { NotifyService } from "services/notify";

import DownloadIcon from "assets/hearingDetails/download-c.svg";
import Attach from "assets/svg/attach.png";
import CameraIcon from "assets/svg/CameraIcon.svg";
import EditIcon from "assets/svg/EditIcon.svg";
import EyeIcon from "assets/svg/EyeIcon.svg";
import FileIcon from "assets/svg/FileIcon.svg";
import PencilIcon from "assets/svg/PencilIcon.svg";

import { FORM_EXPORT_FILES } from "utils/entities/permission";
import { getValueFromlocalStorage } from "utils/sessionStorageEncrypt";

import { Container, VideoContainer, ProcessingVideoContainer } from "./style";

type HearingDetailsProps = {
  //colocar o tipo correto depois
  hearing: Hearing;
  procedureItem: any;
  onClickExcluir: () => unknown;
  onClickEdit: () => unknown;
  loadingList: () => unknown;
};

interface Hearing {
  active: boolean;
  attendantName: string;
  attendantRole: string;
  duration: number;
  expectedDate: string;
  exportCount: number;
  finishDate: string;
  foreignKey: string;
  guid: string;
  hearingTypeId: number;
  hearingTypeName: string;
  userId: string;
  id: number;
  policeUnitId: number;
  procedureCustomerAttendantId: number;
  procedureCustomerId: number;
  startDate: string;
  status: string;
}

export const HearingDetail = ({
  hearing,
  onClickEdit,
  procedureItem,
  loadingList,
}: HearingDetailsProps): JSX.Element => {
  const token = getValueFromlocalStorage("@Kenta:access_Token");
  const user = getValueFromlocalStorage("@Kenta:user");
  const [hearingVideo, setHearingVideo] = useState("");
  const [hasHearingVideo, setHasHearingVideo] = useState(false);
  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const { policeUnit } = useSelector((state: any) => state.policeUnit);

  const havePermissionDownloads = usePermissions(
    FORM_EXPORT_FILES.HEARING_FILE
  );

  const navigate = useNavigate();

  const getHearingVideo = useCallback(async () => {
    const unit = policeUnit;

    const container = unit ? unit.containerName : "santacatarina";

    if (hearing.id) {
      dispatch(showLoading());
      const { data, status } = await HearingService.getHearingById(
        Number(hearing.id)
      );

      if (status && data.length) {
        const hearingRecord = data.find((x: any) => {
          if (x.type === 1) {
            if (x.upload) {
              setHasHearingVideo(true);
            }
          }
          return x.type === 1;
        });

        if (hearingRecord) {
          setHearingVideo(
            `${storageBaseUrl}/${container}/${hearingRecord.filename.replace(
              /\\/gi,
              "/"
            )}`
          );
          dispatch(hideLoading());
        }
      } else {
        dispatch(hideLoading());
      }

      if (!status) {
        dispatch(hideLoading());
      }
    }
  }, [dispatch, hearing, policeUnit]);

  const handleNotify = useCallback(async () => {
    dispatch(showLoading());
    const { message, success } = await NotifyService.post({
      hearingId: hearing.id,
    });

    if (success) {
      addToast({
        type: "success",
        title: "Notificação enviada",
        description: message,
      });
      loadingList();
      return dispatch(hideLoading());
    }

    if (!success) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: message,
      });

      dispatch(hideLoading());
    }
  }, [dispatch, hearing.id, addToast, loadingList]);

  const hearingDetails = useCallback(async () => {
    if (hearing) {
      navigate(
        `hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem?.id}&startOn=1`
      );
    }
  }, [hearing, navigate, procedureItem?.id]);

  const hearingDetailsMarking = useCallback(async () => {
    if (hearing) {
      navigate(
        `hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem?.id}&startOn=2`
      );
    }
  }, [hearing, navigate, procedureItem?.id]);

  const hearingDetailsTranscription = useCallback(async () => {
    if (hearing) {
      navigate(
        `hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem?.id}&startOn=3`
      );
    }
  }, [hearing, navigate, procedureItem?.id]);

  const hearingDetailsAta = useCallback(async () => {
    if (hearing) {
      navigate(
        `hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem?.id}&startOn=4`
      );
    }
  }, [hearing, navigate, procedureItem?.id]);

  const hearingDetailsFiles = useCallback(async () => {
    if (hearing) {
      navigate(
        `hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem?.id}&startOn=1`
      );
    }
  }, [hearing, navigate, procedureItem?.id]);

  const rejoinHearingSession = useCallback(async () => {
    if (token && procedureItem?.id) {
      dispatch(showLoading());

      let responseRenewToken: any = null;

      const response = await HearingService.createHearingRecord(
        token,
        hearing.id
      );

      if (response?.data?.videoRoom?.id) {
        responseRenewToken = await HearingService.renewTokens(
          token,
          response.data.videoRoom?.id
        );
      }

      if (responseRenewToken.data.deviceToken) {
        document.cookie = `${responseRenewToken.data.token}; path=/; secure; samesite=none`;
        document.cookie = `${responseRenewToken.data.sessionToken}; path=/; secure; samesite=none`;
      } else {
        document.cookie = `${response.data.token}; path=/; secure; samesite=none`;
        document.cookie = `${response.data.sessionToken}; path=/; secure; samesite=none`;
      }

      navigate(
        `/dashboard/hearingRecord/una?hearingId=${procedureItem?.id}&hearingFileId=${hearing?.id}`,
        {
          state: {
            ...procedureItem,
            hearingId: hearing.id,
            urlModerator: response.data.urlModerator,
            urlAttendee: response.data.urlAttendee,
            videoRoomId: response.data.videoRoom.id,
            authToken: response.data.token,
            deviceToken: response.data.sessionToken,
            backToHearing: true,
          },
        }
      );

      dispatch(hideLoading());
    } else {
      addToast({
        type: "error",
        title: "Não foi possivel fazer a gravação",
        description: "Verifique se a oitiva foi criada corretamente",
      });
      dispatch(hideLoading());
    }
  }, [token, procedureItem, hearing, dispatch, addToast, navigate]);

  const canReenterHearing = useMemo(() => {
    return hearing.status === "G" || hearing.status === "I";
  }, [hearing.status]);

  const isCurrentUserNotOwner = useMemo(() => {
    if (!user) {
      return false;
    }

    try {
      const parsedUser = JSON.parse(user);
      const isNotOwner = parsedUser?.id !== hearing.userId;
      return isNotOwner;
    } catch (error) {
      console.error("Failed to parse user from localStorage:", error);
      return false;
    }
  }, [user, hearing.userId]);

  const onClickDownloadFiles = useCallback(() => {
    setIsOpenModalDownload(true);
  }, []);

  const disableByHearingStatus = useCallback((hearing: Hearing) => {
    const disabledStatuses = ["S", "C", "U", "I", "G", "D"];
    return !!hearing && disabledStatuses.includes(hearing.status);
  }, []);

  const listLines = useMemo(
    () => [
      {
        icon: EyeIcon,
        label: "Visualização completa",
        disabled: disableByHearingStatus(hearing),
        onClick: hearingDetails,
        permission: true,
        hr: false,
      },
      {
        icon: EditIcon,
        label: "Marcações",
        disabled: disableByHearingStatus(hearing),
        onClick: hearingDetailsMarking,
        permission: true,
        hr: false,
      },
      {
        icon: FileIcon,
        label: "Transcrição",
        disabled: disableByHearingStatus(hearing),
        onClick: hearingDetailsTranscription,
        permission: true,
        hr: false,
      },
      {
        icon: Attach,
        label: "Arquivos",
        disabled: disableByHearingStatus(hearing),
        onClick: hearingDetailsFiles,
        permission: true,
        hr: false,
      },
      {
        icon: FileIcon,
        label: "Ata",
        disabled: disableByHearingStatus(hearing),
        onClick: hearingDetailsAta,
        permission: true,
        hr: false,
      },
      {
        icon: DownloadIcon,
        label: "Downloads",
        disabled: disableByHearingStatus(hearing),
        onClick: onClickDownloadFiles,
        permission: havePermissionDownloads.export,
        hr: true,
      },
      {
        icon: CameraIcon,
        label:
          (canReenterHearing ? "Reentrar na " : "Gravar nova") +
          "oitiva do participante",
        disabled: isCurrentUserNotOwner,
        onClick: () => {
          if (hearing.status === "D") {
            addToast({
              type: "error",
              title: "Ação não executada",
              description:
                "Só é possivel gravar a oitiva com status igual a aguardando",
            });
          } else {
            rejoinHearingSession();
          }
        },
        permission: hearing.status !== "D" && canReenterHearing,
        hr: false,
      },
      {
        icon: PencilIcon,
        label: "Editar informações",
        disabled: disableByHearingStatus(hearing),
        onClick: onClickEdit,
        permission: true,
        hr: false,
      },
      {
        icon: MdOutbox,
        label: "Notificar",
        disabled: disableByHearingStatus(hearing),
        onClick: handleNotify,
        permission: hearing.status === "X",
        isIconNotificateMd: true,
        hr: false,
      },
    ],
    [
      hearing,
      havePermissionDownloads.export,
      canReenterHearing,
      isCurrentUserNotOwner,
      disableByHearingStatus,
      hearingDetails,
      hearingDetailsMarking,
      hearingDetailsTranscription,
      hearingDetailsFiles,
      hearingDetailsAta,
      onClickDownloadFiles,
      onClickEdit,
      handleNotify,
      addToast,
      rejoinHearingSession,
    ]
  );

  useEffect(() => {
    (async () => {
      setHearingVideo("");
      setHasHearingVideo(false);
      await getHearingVideo();
    })();
  }, [getHearingVideo, hearing]);

  return (
    <Container>
      {hearingVideo &&
        hasHearingVideo &&
        (hearing.status === "E" ||
          hearing.status === "N" ||
          hearing.status === "X") && (
          <VideoContainer>
            <video
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
              onContextMenu={(event) => event.preventDefault()}
            >
              <source src={hearingVideo} type="video/mp4" />
              Desculpe, seu navegador não tem suporte o player de vídeo.
            </video>
          </VideoContainer>
        )}

      {hearing.status === "S" && (
        <ProcessingVideoContainer>
          <strong>Processando...</strong>
        </ProcessingVideoContainer>
      )}

      {listLines.map((element: any) =>
        element.permission ? (
          <ItemDetailLine
            icon={
              element.isIconNotificateMd ? (
                <element.icon color="#7887A9" size="24px" />
              ) : (
                <img src={element.icon} alt={element.label} />
              )
            }
            label={element.label}
            disabled={element.disabled}
            onClick={element.onClick}
            hr={element.hr}
          />
        ) : null
      )}

      {hearing && havePermissionDownloads.export ? (
        <DownloadFilesModal
          hearingId={hearing.id}
          isOpenDownloadsModal={isOpenModalDownload}
          setIsOpenDownloadsModal={(isOpen) => {
            setIsOpenModalDownload(isOpen);
          }}
        />
      ) : null}
    </Container>
  );
};
