import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { Container, DateContainer, EmptyList } from "./styles";
import { ProcedureAttendant } from "../../features/ProcedureAttendant";
import { useToast } from "../../hooks/toast";
import { CreateEditAttendant } from "../../pages/app/attendant/CreateEdit";
import { AttendandoViculeService } from "../../pages/app/attendant/vincule";
import api from "../../services/api";
import { AttendantService } from "../../services/attendant";
import { procedureCustomerAttendantService } from "../../services/procedureCustomerAttendant";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { setAttendantsList } from "../../store/modules/procedure/actions";
import { LogOperation } from "../../utils/entities/logOperation";
import { listOperation } from "../../utils/listsOperations";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../utils/stringFormat";
import { AttendantItem } from "../AttendantItem";
import { ModalConfirmation } from "../ModalConfirmation";
import ModalRight from "../ModalRight";
import { TitleSidebar } from "../TitleSidebar";

interface IProcedureAttendantSidebar {
  itemData: any;
}

export const ProcedureAttendantSidebar: React.FC<
  IProcedureAttendantSidebar
> = ({ itemData }) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [nameAttendant, setNameAttendant] = useState<string>("");
  const [selected, setSelected] = useState<number | null>();
  const [listAttendant, setListAttendant] = useState<any>([]);
  const [attendantRoleId, setAttendantRoleId] = useState<number>(0);
  const [attendantRoleName, setAttendantRoleName] = useState<string>("");
  const [selectedCustomerAttendantId, setSelectedCustomerAttendantId] =
    useState<number | undefined>();
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);
  const [attendantList, setAttendantList] = useState<any[]>([]);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const user = getValueFromlocalStorage("@Kenta:user");

  const getAttedantListToSelect = async () => {
    const parserUser = user ? JSON.parse(user) : null;
    if (user && parserUser) {
      const { body, success } =
        await AttendantService.getAttendantByProcedureRoleType({
          procedureCustomerTypeId: itemData.procedureCustomerTypeId,
        });

      if (success) {
        let list: Array<any> = [];

        body.forEach((element: any) => {
          list.push({
            ...element,
            name: `${element.name} - ${element.role}`,
          });
        });

        setAttendantList(list);
      }
    }
  };

  const getAttedantList = async () => {
    const { data, status } = await api.get(
      `/Attendant/GetAllByProcedureCustomerId?ProcedureCustomerId=${itemData.id}`
    );

    if (status) {
      setListAttendant(data.data);
      dispatch(setAttendantsList(data.data));
    }
  };

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    (async () => {
      await getAttedantList();
      await getAttedantListToSelect();
    })();
  }, [itemData.id]);

  const onVinculeAttendat = async () => {
    if (selected) {
      dispatch(showLoading());
      const { message, status, data } = await AttendandoViculeService.vincule({
        attendantId: selected,
        extraData: null,
        procedureCustomerId: String(itemData.id),
      });

      if (!status) {
        addToast({
          type: "warning",
          title: "Vincular participante",
          description: "Participante já vinculado no procedimento",
        });

        dispatch(hideLoading());
        return;
      }

      addToast({
        type: "success",
        title: "Vincular participante",
        description: "O participante foi vinculado com sucesso",
      });

      setShowModal(false);
      await getAttedantList();
      await getAttedantListToSelect();
      if (data)
        await LogOperation.postLogOperation(
          "ProcedureCustomerAttendant",
          1,
          data
        );
      dispatch(hideLoading());
    } else {
      dispatch(hideLoading());

      addToast({
        type: "warning",
        title: "Selecione um participante",
        description:
          "Para vincular um participante é necessário selecionar um primeiro",
      });
    }
  };

  const clear = async () => {
    setNameAttendant("");
    setAttendantRoleId(0);
    setAttendantRoleName("");
    setOpenModal(false);
    setSelectedCustomerAttendantId(undefined);
    setShowModalConfirmation(false);
  };

  const postAttendant = async () => {
    if (
      !nameAttendant ||
      stringFormat.isEmptyOrSpaces(nameAttendant) ||
      attendantRoleId === 0
    ) {
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para criar um participante é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    dispatch(showLoading());

    const resultPost = await AttendantService.post("", {
      id: 0,
      customerId: customerId,
      attendantRoleId: attendantRoleId,
      active: true,
      name: nameAttendant,
      createIn: new Date(),
      updateIn: new Date(),
    });
    if (resultPost.status === true) {
      addToast({
        type: "success",
        title: "Participante cadastrado",
        description: "Participante foi cadastrado com sucesso.",
      });
      clear();
      await getAttedantList();
    } else {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      setFieldError(false);
    }

    await getAttedantList();
    await getAttedantListToSelect();
    dispatch(hideLoading());
  };

  const onSelectAttendantRemove = (id: number) => {
    setSelectedCustomerAttendantId(id);
    setShowModalConfirmation(true);
  };

  const onClickDesvincule = async () => {
    if (selectedCustomerAttendantId) {
      setShowModalConfirmation(false);
      dispatch(showLoading());
      const { success, body } =
        await procedureCustomerAttendantService.unbinding(
          selectedCustomerAttendantId
        );
      dispatch(hideLoading());
      if (success && body) {
        addToast({
          title: "Participante desvinculado",
          description: body,
          type: "success",
        });

        await getAttedantList();
        await getAttedantListToSelect();
        await LogOperation.postLogOperation(
          "ProcedureCustomerAttendant",
          3,
          selectedCustomerAttendantId
        );
        clear();
      }

      const isWarning =
        (success && !body) || body?.includes("não pode ser desvinculado");

      if (isWarning) {
        addToast({
          title: "Participante não desvinculado",
          description: body,
          type: "warning",
        });

        clear();
      } else if (!success) {
        addToast({
          title: "Participante não desvinculado",
          description: body,
          type: "error",
        });

        clear();
      }
    }
  };

  const handleListAttendantLinked = () => {
    let resultIds = listAttendant.map((a: any) => a.id);
    return listOperation.returnNewListItems(attendantList, resultIds);
  };

  const onChangeName = (e: string) => {
    setNameAttendant(e);
    setFieldError(!e.trim());
  };

  return (
    <Container>
      <TitleSidebar
        titleSize={16}
        quantity={listAttendant.length}
        title="Participantes"
        onAddSidebar={() => setShowModal(true)}
        onShow={() => setShow(!show)}
        show={show}
      />
      {show && (
        <DateContainer>
          {listAttendant.length > 0 ? (
            listAttendant.map((item: any, index: number) => (
              <AttendantItem
                key={index}
                item={item}
                type={item.type}
                name={item.name}
                role={item.role}
                id={item.id}
                setSelectAttendant={onSelectAttendantRemove}
              />
            ))
          ) : (
            <EmptyList>Nenhum(a) participante</EmptyList>
          )}
        </DateContainer>
      )}
      <ModalRight
        onClickAplicar={onVinculeAttendat}
        setIsOpen={() => setShowModal(false)}
        title={false ? "Editar participante" : "Vincular participante"}
        isOpen={showModal}
        onClickDelete={() => console.log("remover")}
        showIconDelete={false}
        isEdit={false}
      >
        <ProcedureAttendant
          setSelected={setSelected}
          selected={selected}
          itemData={itemData}
          //attendantList={attendantList}
          attendantList={handleListAttendantLinked()}
          modalOpenNewParticipant={() => setOpenModal(true)}
        />
      </ModalRight>
      <ModalRight
        id="adm_participante"
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={false}
        title={"Criar participante"}
        onClickAplicar={postAttendant}
        showIconDelete={false}
        onClickDelete={() => {}}
      >
        <CreateEditAttendant
          onChangeName={onChangeName}
          valueEdit={nameAttendant}
          active={true}
          setAttendantRoleDropSelected={setAttendantRoleId}
          attendantRoleDropSelected={
            attendantRoleId === 0 ? null : attendantRoleId
          }
          attendantRoleName={attendantRoleName}
          fieldError={fieldError}
        />
      </ModalRight>
      <ModalConfirmation
        onClose={() => {
          setShowModalConfirmation(false);
        }}
        title="Desvincular participante"
        titleBody="Deseja desvincular o participante?"
        subtitleBody="Esta ação não poderá ser desfeita"
        labelButton="Desvincular"
        onClickButton={onClickDesvincule}
        isOpen={showModalConfirmation}
      />
    </Container>
  );
};
