import { Fragment, useEffect, useRef, useState } from "react";

import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";

import { transcriptionProviderList } from "./constans";
import { ShortCuts } from "./shortcutsCustom/index";
import {
  BodyWaterMark,
  ContainerWaterMaker,
  ContainerSelectTypeMark,
  BoxType1,
  BoxType2,
  BoxType3,
  BoxType4,
  ContainerModalRight,
  ContainerCheckboxList,
  DropFileContainer,
  ContainerServiceIntegrationConfig,
  ContainerDivDropzone,
  ClickLocationText,
  DropLocationText,
  DropFileInput,
} from "./style";
import { AdminArea } from "../../../components/Body/AdminArea";
import { CheckBoxGlobal } from "../../../components/CheckBoxGlobal";
import { DropdownGlobal, IDropDown } from "../../../components/DropdownGlobal";
import { InputGeneral } from "../../../components/Input";
import { InputGeneralFile } from "../../../components/InputFileGlobal";
import { InputAuth } from "../../../components/Login/Input";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import { SystemConfigurationService } from "../../../services/systemConfiguration/index";

//Toast
import { updateGlobalConfigurationsAction } from "../../../store/modules/globalConfiguration/actions";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//own styled
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import {
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "../StyleCSS/bodyCreateEdit";

interface IShortcut {
  name: string;
  value: string;
}

interface SystemWatermark {
  active: boolean;
  createIn: string; // ISO date string
  customerId: number;
  device: string | null;
  filepath: string;
  id: number;
  ip: string | null;
  label: string;
  layout: number;
  logPoliceUnitId: number;
  logUserId: number;
  provider: number;
  updateIn: string; // ISO date string
}

export function SystemConfiguration() {
  const watermarkRef = useRef<SystemWatermark | null>(null);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [fieldError, setFieldError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const [valueTranscriptionLanguage, setTranscriptionLanguage] =
    useState("pt-BR");

  let listYesOrNoAux: Array<IDropDown> = [
    { name: "Não", id: 0 },
    { name: "Sim", id: 1 },
  ];

  const [selectedYesOrNoId, setSelectedYesOrNoId] = useState<number>(-1);
  const [selectedYesOrNoList, setSelectedYesOrNoList] = useState<
    Array<IDropDown>
  >([]);
  const [selectedYesOrNoName, setSelectedYesOrNoName] = useState<string>("");

  let listTranscriptionProviderAux: Array<IDropDown> = [
    { name: "MConf", id: 0 },
    { name: "Digitro", id: 1 },
  ];

  const [selectedTranscriptionProvider, setSelectedTranscriptionProvider] =
    useState<string>("");

  const [selectedProviderId, setSelectedProviderId] = useState<number>(-1);
  const [selectedProviderList, setSelectedProviderList] = useState<
    Array<IDropDown>
  >([]);
  const [selectedProviderName, setSelectedProviderName] = useState<string>("");

  let listTimesAux: Array<IDropDown> = [
    { name: "30 minutos", id: 30 },
    { name: "60 minutos", id: 60 },
  ];

  const [selectedAppSessionId, setSelectedAppSessionId] = useState<number>(-1);
  const [selectedAppSessionList, setSelectedAppSessionList] = useState<
    Array<IDropDown>
  >([]);
  const [selectedAppSessioName, setSelectedAppSessionName] =
    useState<string>("");

  let listDaysAux: Array<IDropDown> = [
    { name: "30 dias", id: 30 },
    { name: "60 dias", id: 60 },
  ];

  const [selectedDaysToGetProcedureId, setSelectedDaysToGetProcedureId] =
    useState<number>(-1);
  const [selectedDaysToGetProcedureList, setSelectedDaysToGetProcedureList] =
    useState<Array<IDropDown>>([]);
  const [selectedDaysToGetProcedureName, setSelectedDaysToGetProcedureName] =
    useState<string>("");

  //File System
  let listProviderAux: Array<IDropDown> = [
    { name: "Azure", id: 0 },
    { name: "Sistema de arquivos", id: 1 },
  ];

  const [selectedStorageProviderId, setSelectedStorageProviderId] =
    useState<number>(0);
  const [selectedStorageProviderList, setSelectedStorageProviderList] =
    useState<Array<IDropDown>>([]);
  const [selectedStorageProviderName, setSelectedStorageProviderName] =
    useState<string>("");

  const [valueFileSystemUser, setFileSystemUser] = useState<string>("");
  const [valueFileSystemPassword, setFileSystemPassword] = useState<string>("");
  const [valueFileSystemPath, setFileSystemPath] = useState<string>("");

  //Azure
  const [valueAzureURL, setAzureURL] = useState<string>("");
  const [valueStorageName, setStorageName] = useState<string>("");
  const [valueStorageStringConnection, setStorageStringConnection] =
    useState<string>("");

  //SHORTCUTS
  //para o boby
  const [playStop, setPlayStop] = useState("");
  const [actionBackTime, setActionBackTime] = useState("");
  const [actionNextTime, setActionNextTime] = useState("");
  const [timeBackNext, setTimeBackNext] = useState("");
  const [slowSpeed, setSlowSpeed] = useState("");
  const [fastSpeed, setFastSpeed] = useState("");

  //-------------------------------------------------
  //para controle
  let listFirstShortcut: Array<IDropDown> = [
    { name: "Nenhum", id: 0 },
    { name: "Alt", id: 1 },
    { name: "Shift", id: 2 },
    { name: "Ctrl", id: 3 },
  ];

  const [selectedListFirstShortcut, setSelectedListFirstShortcut] = useState<
    Array<IDropDown>
  >([]);

  let listSecondShortcut: Array<IDropDown> = [
    { name: "Espaço", id: 0 },
    { name: "F1", id: 1 },
    { name: "F2", id: 2 },
    { name: "F3", id: 3 },
    { name: "F4", id: 4 },
    { name: "F5", id: 5 },
    { name: "F6", id: 6 },
    { name: "F7", id: 7 },
    { name: "F8", id: 8 },
    { name: "F9", id: 9 },
    { name: "F10", id: 10 },
    { name: "F11", id: 11 },
    { name: "F12", id: 12 },
    { name: "Seta esquerda", id: 13 },
    { name: "Seta cima", id: 14 },
    { name: "Seta direita", id: 15 },
    { name: "Seta baixo", id: 16 },
  ];

  const [selectedListSecondShortcut, setSelectedListSecondShortcut] = useState<
    Array<IDropDown>
  >([]);

  let listSecondsToNextBack: Array<IDropDown> = [
    { name: "5 segundos", id: 5 },
    { name: "10 segundos", id: 10 },
    { name: "20 segundos", id: 20 },
    { name: "30 segundos", id: 30 },
    { name: "60 segundos", id: 60 },
  ];

  const [selectedListSecondsToNextBack, setSelectedListSecondsToNextBack] =
    useState<Array<IDropDown>>([]);

  const [valueTimeBackNextName, setTimeBackNextName] = useState<string>(
    listSecondsToNextBack[0].name
  );
  const [valueTimeBackNextId, setTimeBackNextId] = useState<number>(5);

  const [valuePlayStopFirstName, setPlayStopFirstName] = useState<string>(
    listFirstShortcut[0].name
  );
  const [valuePlayStopFirstId, setPlayStopFirstId] = useState<number>(0);

  const [valuePlayStopSecondName, setPlayStopSecondName] = useState<string>(
    listSecondShortcut[0].name
  );
  const [valuePlayStopSecondId, setPlayStopSecondId] = useState<number>(0);

  const [valueActionBackTimeFirstName, setActionBackTimeFirstName] =
    useState<string>(listFirstShortcut[0].name);
  const [valueActionBackTimeFirstId, setActionBackTimeFirstId] =
    useState<number>(0);

  const [valueActionBackTimeSecondName, setActionBackTimeSecondName] =
    useState<string>(listSecondShortcut[0].name);
  const [valueActionBackTimeSecondId, setActionBackTimeSecondId] =
    useState<number>(0);

  const [valueActionNextTimeFirstName, setActionNextTimeFirstName] = useState(
    listFirstShortcut[0].name
  );
  const [valueActionNextTimeFirstId, setActionNextTimeFirstId] =
    useState<number>(0);

  const [valueActionNextTimeSecondName, setActionNextTimeSecondName] =
    useState<string>(listSecondShortcut[0].name);
  const [valueActionNextTimeSecondId, setActionNextTimeSecondId] =
    useState<number>(0);

  const [valueSlowSpeedFirstName, setSlowSpeedFirstName] = useState<string>(
    listFirstShortcut[0].name
  );
  const [valueSlowSpeedFirstId, setSlowSpeedFirstId] = useState<number>(0);

  const [valueSlowSpeedSecondName, setSlowSpeedSecondName] = useState<string>(
    listSecondShortcut[0].name
  );
  const [valueSlowSpeedSecondId, setSlowSpeedSecondId] = useState<number>(0);

  const [valueFastSpeedFirstName, setFastSpeedFirstName] = useState<string>(
    listFirstShortcut[0].name
  );
  const [valueFastSpeedFirstId, setFastSpeedFirstId] = useState<number>(0);

  const [valueFastSpeedSecondName, setFastSpeedSecondName] = useState<string>(
    listSecondShortcut[0].name
  );
  const [valueFastSpeedSecondId, setFastSpeedSecondId] = useState<number>(0);

  //=====================================================================================

  let listPagination: Array<IDropDown> = [
    { name: "5", id: 5 },
    { name: "10", id: 10 },
    { name: "20", id: 20 },
    { name: "50", id: 50 },
    { name: "100", id: 100 },
  ];

  const [selectedListPagination, setSelectedListPagination] = useState<
    Array<IDropDown>
  >([]);

  const [valuePaginationName, setPaginationName] = useState<string>(
    listPagination[0].name
  );
  const [valuePaginationId, setPaginationId] = useState<number>(5);

  async function onLoadList() {
    const response = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    if (response) {
      if (response.status) {
        var listFilter = response.data.filter((x: any) => x.customerId !== 0);

        for (let i = 0; i < listFilter.length; i++) {
          if (listFilter[i].name === "AppSession") {
            //let number = parseInt(listFilter[i].value);
            //setAppSession(number);
            if (listFilter[i].value === "30") {
              setSelectedAppSessionId(30);
              setSelectedAppSessionList(listTimesAux);
              setSelectedAppSessionName(listTimesAux[0].name);
            }
            if (listFilter[i].value === "60") {
              setSelectedAppSessionId(60);
              setSelectedAppSessionList(listTimesAux);
              setSelectedAppSessionName(listTimesAux[1].name);
            }
          }
          if (listFilter[i].name === "DaysToGetProcedure") {
            if (listFilter[i].value === "30") {
              setSelectedDaysToGetProcedureId(30);
              setSelectedDaysToGetProcedureList(listDaysAux);
              setSelectedDaysToGetProcedureName(listDaysAux[0].name);
            }
            if (listFilter[i].value === "60") {
              setSelectedDaysToGetProcedureId(60);
              setSelectedDaysToGetProcedureList(listDaysAux);
              setSelectedDaysToGetProcedureName(listDaysAux[1].name);
            }
          }
          if (listFilter[i].name === "FacialRecognition") {
            if (listFilter[i].value === "true" || listFilter[i].value === "1") {
              setSelectedYesOrNoId(1);
              setSelectedYesOrNoList(listYesOrNoAux);
              setSelectedYesOrNoName(listYesOrNoAux[1].name);
            }
            if (
              listFilter[i].value === "false" ||
              listFilter[i].value === "0"
            ) {
              setSelectedYesOrNoId(0);
              setSelectedYesOrNoList(listYesOrNoAux);
              setSelectedYesOrNoName(listYesOrNoAux[0].name);
            }
          }
          if (listFilter[i].name === "TranscriptionProvider") {
            const transcriptionProviderName = transcriptionProviderList.find(
              (x) => x.id === Number(listFilter[i].value)
            )?.name;
            setSelectedTranscriptionProvider(transcriptionProviderName ?? "");
          }
          if (listFilter[i].name === "TranscriptionLanguage") {
            setTranscriptionLanguage(listFilter[i].value);
          }
          if (listFilter[i].name === "PaginationList") {
            let paginationListAuxId: number = parseInt(
              listFilter[i].value as string
            );
            setPaginationId(paginationListAuxId);

            let paginationListAuxName = listPagination.find(
              (x) => x.id === paginationListAuxId
            )?.name;

            setPaginationName(
              paginationListAuxName === undefined ? "5" : paginationListAuxName
            );
          }
          if (listFilter[i].name === "FileSystemPath") {
            setFileSystemPath(listFilter[i].value);
          }
          if (listFilter[i].name === "FileSystemUser") {
            setFileSystemUser(listFilter[i].value);
          }
          if (listFilter[i].name === "FileSystemPassword") {
            setFileSystemPassword(listFilter[i].value);
          }
          //Azure
          if (listFilter[i].name === "StorageName") {
            setStorageName(listFilter[i].value);
          }
          if (listFilter[i].name === "StorageStringConnection") {
            setStorageStringConnection(listFilter[i].value);
          }
          if (listFilter[i].name === "AzureURL") {
            setAzureURL(listFilter[i].value);
          }
          //Provider (0 => azure, 1 => filesystem)
          if (listFilter[i].name === "StorageProvider") {
            if (listFilter[i].value === "0") {
              setSelectedStorageProviderList(listProviderAux);
              setSelectedStorageProviderName(listProviderAux[0].name);
              setSelectedStorageProviderId(0);
            } else {
              setSelectedStorageProviderList(listProviderAux);
              setSelectedStorageProviderName(listProviderAux[1].name);
              setSelectedStorageProviderId(1);
            }
          }
          //ShortCuts
          if (listFilter[i].name === "Shortcuts") {
            let listShortcuts: Array<IShortcut> = JSON.parse(
              listFilter[i].value
            );
            if (listShortcuts.length > 0) {
              separateFirstAndSecond(listShortcuts);
            }
          }
          // Prazo
          if (listFilter[i].name === "CustomerProcedureDeadline") {
            if (listFilter[i].value === "0") {
              setDeadlineOff(true);
            } else {
              setDeadlineOff(false);
            }
          }
        }
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }

    dispatch(hideLoading());
    return [];
  }

  function serializeShortcuts() {
    let joinTimeBackNext = valueTimeBackNextId.toString();
    let joinPlayStop = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valuePlayStopFirstId,
      listSecondShortcut,
      valuePlayStopSecondId
    );
    let joinActionBackTime = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueActionBackTimeFirstId,
      listSecondShortcut,
      valueActionBackTimeSecondId
    );
    let joinActionNextTime = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueActionNextTimeFirstId,
      listSecondShortcut,
      valueActionNextTimeSecondId
    );
    let joinSlowSpeed = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueSlowSpeedFirstId,
      listSecondShortcut,
      valueSlowSpeedSecondId
    );
    let joinFastSpeed = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueFastSpeedFirstId,
      listSecondShortcut,
      valueFastSpeedSecondId
    );

    let playStopAux: IShortcut = { name: "playStop", value: joinPlayStop };

    let actionBackTimeAux: IShortcut = {
      name: "actionBackTime",
      value: joinActionBackTime,
    };

    let actionNextTimeAux: IShortcut = {
      name: "actionNextTime",
      value: joinActionNextTime,
    };
    let timeBackNextAux: IShortcut = {
      name: "timeBackNext",
      value: joinTimeBackNext,
    };
    let slowSpeedAux: IShortcut = { name: "slowSpeed", value: joinSlowSpeed };
    let fastSpeedAux: IShortcut = { name: "fastSpeed", value: joinFastSpeed };

    let auxList: Array<IShortcut> = [];
    auxList.push(playStopAux);
    auxList.push(actionBackTimeAux);
    auxList.push(actionNextTimeAux);
    auxList.push(timeBackNextAux);
    auxList.push(slowSpeedAux);
    auxList.push(fastSpeedAux);

    setLocalstorageToShortcuts(auxList);

    return JSON.stringify(auxList);
  }

  function setLocalstorageToShortcuts(listShortcuts: Array<IShortcut>) {
    for (let i = 0; i < listShortcuts.length; i++) {
      if (listShortcuts[i].name === "playStop") {
        localStorage.setItem("GlobalPlayStop", listShortcuts[i].value);
      }
      if (listShortcuts[i].name === "actionNextTime") {
        localStorage.setItem("GlobalActionNextTime", listShortcuts[i].value);
      }
      if (listShortcuts[i].name === "actionBackTime") {
        localStorage.setItem("GlobalActionBackTime", listShortcuts[i].value);
      }
      if (listShortcuts[i].name === "slowSpeed") {
        localStorage.setItem("GlobalSlowSpeed", listShortcuts[i].value);
      }
      if (listShortcuts[i].name === "fastSpeed") {
        localStorage.setItem("GlobalFastSpeed", listShortcuts[i].value);
      }
      if (listShortcuts[i].name === "timeBackNext") {
        localStorage.setItem("GlobalTimeBackNext", listShortcuts[i].value);
      }
    }
  }

  function joinShortcutsFirstAndSecond(
    listFirstShortcut: Array<IDropDown>,
    valueFirstId: number,
    listSecondShortcut: Array<IDropDown>,
    valueSecondId: number
  ) {
    let stringToValue = "";
    if (valueFirstId !== 0) {
      stringToValue = listFirstShortcut[valueFirstId].name + "+";
    }

    if (valueSecondId === 13) {
      stringToValue += "ArrowLeft";
    } else if (valueSecondId === 14) {
      stringToValue += "ArrowUp";
    } else if (valueSecondId === 15) {
      stringToValue += "ArrowRight";
    } else if (valueSecondId === 16) {
      stringToValue += "ArrowDown";
    } else {
      stringToValue += listSecondShortcut[valueSecondId].name;
    }

    return stringToValue;
  }

  function separateFirstAndSecond(listShortcuts: Array<IShortcut>) {
    let playStopAux = listShortcuts.find((x) => x.name === "playStop")?.value;
    let playStopAuxSplit = playStopAux?.split("+");
    mapShortcutState(
      playStopAuxSplit,
      setPlayStopFirstId,
      setPlayStopFirstName,
      setPlayStopSecondId,
      setPlayStopSecondName
    );

    let actionBackTimeAux = listShortcuts.find(
      (x) => x.name === "actionBackTime"
    )?.value;
    let actionBackTimeSplit = actionBackTimeAux?.split("+");

    mapShortcutState(
      actionBackTimeSplit,
      setActionBackTimeFirstId,
      setActionBackTimeFirstName,
      setActionBackTimeSecondId,
      setActionBackTimeSecondName
    );

    let actionNextTimeAux = listShortcuts.find(
      (x) => x.name === "actionNextTime"
    )?.value;
    let actionNextTimeSplit = actionNextTimeAux?.split("+");
    mapShortcutState(
      actionNextTimeSplit,
      setActionNextTimeFirstId,
      setActionNextTimeFirstName,
      setActionNextTimeSecondId,
      setActionNextTimeSecondName
    );

    let slowSpeedAux = listShortcuts.find((x) => x.name === "slowSpeed")?.value;
    let slowSpeedSplit = slowSpeedAux?.split("+");
    mapShortcutState(
      slowSpeedSplit,
      setSlowSpeedFirstId,
      setSlowSpeedFirstName,
      setSlowSpeedSecondId,
      setSlowSpeedSecondName
    );

    let fastSpeedAux = listShortcuts.find((x) => x.name === "fastSpeed")?.value;
    let fastSpeedSplit = fastSpeedAux?.split("+");
    mapShortcutState(
      fastSpeedSplit,
      setFastSpeedFirstId,
      setFastSpeedFirstName,
      setFastSpeedSecondId,
      setFastSpeedSecondName
    );

    let timeBackNextAux = listShortcuts.find(
      (x) => x.name === "timeBackNext"
    )?.value;

    let timeBackNextAuxId: number =
      timeBackNextAux === "" ? 0 : parseInt(timeBackNextAux as string);
    setTimeBackNextId(timeBackNextAuxId);
    let timeBackNextAuxName = listSecondsToNextBack.find(
      (x) => x.id === timeBackNextAuxId
    )?.name;
    setTimeBackNextName(
      timeBackNextAuxName === undefined ? "5 segundos" : timeBackNextAuxName
    );
  }

  function mapShortcutState(
    array: string[] | undefined,
    setOneId: (e: number) => unknown,
    setOneName: (e: string) => unknown,
    setTwoId: (e: number) => unknown,
    setTwoName: (e: string) => unknown
  ) {
    if (array?.length === 1) {
      let name2: string = array[0];
      let getIdList = listSecondShortcut.find((x) => x.name === name2)?.id;
      let id: number = getIdList === undefined ? 0 : (getIdList as number);
      setTwoId(id);
      setTwoName(mapArrowsShortcut(name2));

      // setando "nenhum" no primeiro drop quando atalho é apenas uma tecla
      let shortcutNenhum = listFirstShortcut[0];
      setOneId(Number(shortcutNenhum.id));
      setOneName(shortcutNenhum.name);
    } else if (array?.length === 2) {
      let name1: string = array[0];
      let getIdList1 = listFirstShortcut.find((x) => x.name === name1)?.id;
      let id1: number = getIdList1 === undefined ? 0 : (getIdList1 as number);
      setOneId(id1);
      setOneName(name1);

      let name2: string = array[1];
      let name22 = mapArrowsShortcut(name2);
      let getIdList2 = listSecondShortcut.find((x) => x.name === name22)?.id;

      let id2: number = getIdList2 === undefined ? 0 : (getIdList2 as number);
      setTwoId(id2);
      setTwoName(name22);
    }
  }

  function mapArrowsShortcut(shortcut: string) {
    if (shortcut === "ArrowLeft") {
      shortcut = "Seta esquerda";
    } else if (shortcut === "ArrowUp") {
      shortcut = "Seta cima";
    } else if (shortcut === "ArrowRight") {
      shortcut = "Seta direita";
    } else if (shortcut === "ArrowDown") {
      shortcut = "Seta baixo";
    }
    return shortcut;
  }

  type typesInput = "email" | "password" | "text" | undefined;

  // Marcos Here
  const [configurationsList, setConfigurationsList] = useState<any>([]);
  const [labelList, setLabelList] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [filterList, setFilterList] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [passwordType, setPasswordType] = useState<typesInput>("password");
  const [itemEdit, setItemEdit] = useState<any>();
  const [editedValue, setEditedValue] = useState<any>("");

  const [filePathMark, setFilePathMark] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [typeMark, setTypeMark] = useState(1);
  const [valeuRegisterPerPage, setValeuRegisterPerPage] = useState<number>(10);

  const [deadlineOff, setDeadlineOff] = useState<boolean>(false);

  const getAllConfigurationsByCustomerId = async () => {
    const { data, status } = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    if (status) {
      const list = data.filter((item: any) => item.visible);
      dispatch(updateGlobalConfigurationsAction(data));
      setConfigurationsList(list);
      setFilterList(list);
    }
  };

  const getWaterMark = async () => {
    const { data, success } = await SystemConfigurationService.getWatermark(
      customerId
    );
    watermarkRef.current = data;
    if (success) {
      setTypeMark(data.layout);
      setLabelList(data.label.split(";"));
      setFilePathMark(data.filepath);
    }
  };

  useEffect(() => {
    getAllConfigurationsByCustomerId();
    getWaterMark();
  }, []);

  useEffect(() => {
    setSelectedListFirstShortcut(listFirstShortcut);
    setSelectedListSecondShortcut(listSecondShortcut);
    setSelectedListSecondsToNextBack(listSecondsToNextBack);
    setSelectedListPagination(listPagination);
    onLoadList();
  }, [showModal]);

  const putConfiguration = async () => {
    if (
      (!editedValue && editedValue !== 0) ||
      (itemEdit.name === "LogoCustomer" && Number(editedValue) === 1 && !file)
    ) {
      setFieldError(true);
      return addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para atualizar a configuração é necessário preencher os campos destacados",
      });
    }

    if (
      itemEdit.name === "ServiceIntegrationConfig" &&
      (!apiurl ||
        !authuser ||
        !authpassword ||
        !jwtendpoint ||
        !notifyendpoint ||
        !requestendpoint)
    ) {
      setFieldError(true);
      return addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para atualizar a configuração é necessário preencher os campos destacados",
      });
    }

    const newData = {
      apiurl,
      authuser,
      authpassword,
      jwtendpoint,
      notifyendpoint,
      requestendpoint,
    };

    setFieldError(false);
    dispatch(showLoading());
    await SystemConfigurationService.patch(
      itemEdit.name === "Shortcuts"
        ? serializeShortcuts()
        : itemEdit.name === "LogoCustomer"
        ? customerLogo
        : itemEdit.name === "ServiceIntegrationConfig"
        ? JSON.stringify(newData)
        : editedValue,
      itemEdit.id
    );

    if (itemEdit.name === "PaginationList") {
      localStorage.setItem("PaginationList", editedValue);
      setValeuRegisterPerPage(Number(editedValue));
    }

    if (itemEdit.name === "CustomerProcedureDeadline") {
      localStorage.setItem("CustomerProcedureDeadline", editedValue);
    }

    if (itemEdit.name === "Watermark") {
      const labelListString = labelList.join(";");

      if (file) {
        Resizer.imageFileResizer(
          file,
          80,
          102,
          "PNG",
          100,
          0,
          async (fileResized: any) => {
            await SystemConfigurationService.uploadFile(fileResized);
          },
          "file",
          50,
          102
        );
      }
      if (watermarkRef.current) {
        await SystemConfigurationService.updateWatermark(
          watermarkRef.current?.id,
          labelListString || null,
          typeMark,
          filePathMark
        );
      }
    }

    if (itemEdit.name === "LogoCustomer") {
      setFile(null);
    }

    await getAllConfigurationsByCustomerId();
    setSelectedListFirstShortcut(listFirstShortcut);
    setSelectedListSecondShortcut(listSecondShortcut);
    setSelectedListSecondsToNextBack(listSecondsToNextBack);
    setSelectedListPagination(listPagination);
    await onLoadList();
    setShowModal(false);
    dispatch(hideLoading());

    addToast({
      type: "success",
      title: "Configuração atualizada",
      description: "A configuração foi atualizada com sucesso",
    });
  };

  const closeAll = () => {
    setShowModal(false);
    setFieldError(false);
    setCustomerLogo(null);
    setEditedValue("0");
    dispatch(hideLoading());
    setApiUrl("");
    setAuthUser("");
    setAuthPassword("");
    setJwtendpoint("");
    setNotifyEndpoint("");
    setRequestendpoint("");
    setFile(null);
  };

  const selectType = (type: number) => {
    setTypeMark(type);
  };

  const [customerLogo, setCustomerLogo] = useState(null);
  const [apiurl, setApiUrl] = useState("");
  const [authuser, setAuthUser] = useState("");
  const [authpassword, setAuthPassword] = useState("");
  const [jwtendpoint, setJwtendpoint] = useState("");
  const [notifyendpoint, setNotifyEndpoint] = useState("");
  const [requestendpoint, setRequestendpoint] = useState("");

  const onPressEdit = (item: any) => {
    if (
      item.name === "Watermark" ||
      item.name === "FacialRecognition" ||
      item.name === "NotificationAuto" ||
      item.name === "TranscriptionSummary"
    ) {
      setSelectedYesOrNoName(item.value === "1" ? "Sim" : "Não");
    }

    if (item.name === "ServiceIntegrationConfig") {
      const sisp_object: {
        apiurl: string;
        authuser: string;
        authpassword: string;
        jwtendpoint: string;
        notifyendpoint: string;
        requestendpoint: string;
      } = JSON.parse(item.value);

      setApiUrl(sisp_object.apiurl);
      setAuthUser(sisp_object.authuser);
      setAuthPassword(sisp_object.authpassword);
      setJwtendpoint(sisp_object.jwtendpoint);
      setNotifyEndpoint(sisp_object.notifyendpoint);
      setRequestendpoint(sisp_object.requestendpoint);
    }

    setItemEdit(item);
    setEditedValue(item.value);

    if (item.name === "LogoCustomer") {
      setSelectedYesOrNoName(!!item.value ? "Sim" : "Não");
      setCustomerLogo(item.value);

      if (!item.value) setEditedValue("0");
    }

    setShowModal(true);
  };

  const onChangeFiles = (image: any) => {
    if (image && image.type !== "image/png") {
      setFile(null);
      return addToast({
        type: "warning",
        title: "Aviso",
        description: `Tipo de arquivo invalido é necessário escolher um arquivo .png`,
      });
    }

    if (image) {
      Resizer.imageFileResizer(
        image,
        20,
        100,
        "PNG",
        100,
        0,
        async (fileResized: any) => {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            setCustomerLogo(e.target.result);
          };
          reader.readAsDataURL(fileResized);
        },
        "file",
        20,
        100
      );
    }
    setFile(image);
  };

  const whatIsEdit = (name: string) => {
    switch (name) {
      case "AppSession":
        return (
          <DropdownGlobal
            labelName={itemEdit.description}
            options={listTimesAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            showDefaultValue
            defaultValue={
              listTimesAux.find((x) => x.id === Number(itemEdit.value))?.name
            }
          />
        );
      case "DaysToGetProcedure":
        return (
          <DropdownGlobal
            labelName={"Período máximo de pesquisa"}
            options={listDaysAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedDaysToGetProcedureName}
            defaultValue={selectedDaysToGetProcedureName}
          />
        );
      case "AzureURL":
        return (
          <InputGeneral
            error={fieldError}
            label={itemEdit.description}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
          />
        );
      case "StorageName":
        return (
          <InputGeneral
            error={fieldError}
            label={"Nome do armazenamento"}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
          />
        );
      case "FacialRecognition":
        return (
          <DropdownGlobal
            labelName={"Reconhecimento facial"}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "Watermark":
        return (
          <ContainerWaterMaker>
            <DropdownGlobal
              labelName={"Adicionar marca d'agua"}
              options={listYesOrNoAux}
              error={fieldError}
              setSelected={(e: any) => {
                setEditedValue(String(e));
              }}
              required={false}
              editDropdown={selectedYesOrNoName}
              defaultValue={selectedYesOrNoName}
            />
            {(selectedYesOrNoName === "1" || editedValue === "1") && (
              <Fragment>
                <InputGeneralFile
                  label="Logomarca (upload imagem png 80x102)"
                  type="file"
                  onChange={(file: any) => setFile(file)}
                  defaultValue={filePathMark}
                />
                <BodyWaterMark>
                  <span>Layout</span>
                  <ContainerSelectTypeMark>
                    <BoxType1 onClick={() => selectType(1)}>
                      <div>
                        <input
                          defaultChecked={typeMark === 1}
                          checked={typeMark === 1}
                          onChange={() => selectType(1)}
                          type="radio"
                        />
                        <span>Legenda</span>
                      </div>
                    </BoxType1>
                    <BoxType2 onClick={() => selectType(2)}>
                      <div>
                        <input
                          defaultChecked={typeMark === 2}
                          checked={typeMark === 2}
                          onChange={() => selectType(2)}
                          type="radio"
                        />
                        <span>Legenda</span>
                      </div>
                    </BoxType2>
                  </ContainerSelectTypeMark>
                  <ContainerSelectTypeMark>
                    <BoxType3 onClick={() => selectType(3)}>
                      <div>
                        <span>Legenda</span>
                        <input
                          defaultChecked={typeMark === 3}
                          checked={typeMark === 3}
                          onChange={() => selectType(3)}
                          type="radio"
                        />
                      </div>
                    </BoxType3>
                    <BoxType4 onClick={() => selectType(4)}>
                      <div>
                        <span>Legenda</span>
                        <input
                          defaultChecked={typeMark === 4}
                          checked={typeMark === 4}
                          onChange={() => selectType(4)}
                          type="radio"
                        />
                      </div>
                    </BoxType4>
                  </ContainerSelectTypeMark>
                </BodyWaterMark>
                <ContainerCheckboxList>
                  <CheckBoxGlobal
                    checked={labelList.includes("PoliceUnitName")}
                    id={1}
                    label="Identificação da unidade de polícia"
                    onChecked={
                      labelList.includes("PoliceUnitName")
                        ? () =>
                            setLabelList(
                              labelList.filter(
                                (x: string) => x !== "PoliceUnitName"
                              )
                            )
                        : () => setLabelList([...labelList, "PoliceUnitName"])
                    }
                  />
                  <CheckBoxGlobal
                    checked={labelList.includes("ProcedureNumber")}
                    id={2}
                    label="Número do procedimento"
                    onChecked={
                      labelList.includes("ProcedureNumber")
                        ? () =>
                            setLabelList(
                              labelList.filter(
                                (x: string) => x !== "ProcedureNumber"
                              )
                            )
                        : () => setLabelList([...labelList, "ProcedureNumber"])
                    }
                  />
                  <CheckBoxGlobal
                    checked={labelList.includes("HearingId")}
                    id={3}
                    label="Número da oitiva"
                    onChecked={
                      labelList.includes("HearingId")
                        ? () =>
                            setLabelList(
                              labelList.filter((x: string) => x !== "HearingId")
                            )
                        : () => setLabelList([...labelList, "HearingId"])
                    }
                  />
                  <CheckBoxGlobal
                    checked={labelList.includes("ExpectedDate")}
                    id={4}
                    label="Data da oitiva"
                    onChecked={
                      labelList.includes("ExpectedDate")
                        ? () =>
                            setLabelList(
                              labelList.filter(
                                (x: string) => x !== "ExpectedDate"
                              )
                            )
                        : () => setLabelList([...labelList, "ExpectedDate"])
                    }
                  />
                </ContainerCheckboxList>
              </Fragment>
            )}
          </ContainerWaterMaker>
        );
      case "LogoCustomer":
        return (
          <ContainerWaterMaker>
            <DropdownGlobal
              labelName={"Adicionar logo"}
              options={listYesOrNoAux}
              error={fieldError}
              setSelected={(e: any) => {
                setEditedValue(String(e));
                e === "Não" && setCustomerLogo(null);
              }}
              required={Number(editedValue) === 1}
              editDropdown={selectedYesOrNoName}
              defaultValue={selectedYesOrNoName}
            />
            {editedValue !== "0" && editedValue !== null && (
              <DropFileContainer error={true}>
                <Dropzone
                  accept={{ "image/*": [".png", ".svg", ".jpeg", ".jpg"] }}
                  onDrop={(acceptedFiles) => {
                    onChangeFiles(acceptedFiles[0]);
                  }}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <ContainerDivDropzone
                      {...getRootProps()}
                      isDragActive={isDragActive}
                      error={!file && fieldError}
                    >
                      {isDragActive ? (
                        <ClickLocationText>Solte aqui</ClickLocationText>
                      ) : (
                        <>
                          {customerLogo && (
                            <img
                              src={customerLogo}
                              style={{
                                marginBottom: 10,
                                height: 50,
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          )}
                          <DropLocationText>
                            Solte o arquivo aqui ou
                          </DropLocationText>
                          <ClickLocationText>
                            clique para localizar
                          </ClickLocationText>
                        </>
                      )}
                      <DropFileInput {...getInputProps()} />
                    </ContainerDivDropzone>
                  )}
                </Dropzone>
              </DropFileContainer>
            )}
          </ContainerWaterMaker>
        );
      case "ServiceIntegrationConfig":
        return (
          <>
            <InputGeneral
              error={fieldError}
              label={"Url da api"}
              value={apiurl}
              onChange={(e) => setApiUrl(e)}
              required={true}
              width={381}
              maxLength={499}
            />
            <InputGeneral
              error={fieldError}
              label={"Usuário"}
              value={authuser}
              onChange={(e) => setAuthUser(e)}
              required={true}
              width={381}
              maxLength={499}
            />
            <InputAuth
              id="singIn_password"
              maxLength={499}
              error={fieldError}
              label="Senha"
              autoFocus={false}
              onChange={(password: string) => {
                setAuthPassword(password);
              }}
              value={authpassword}
              setType={(type: typesInput) => setPasswordType(type)}
              icon={true}
              type={passwordType}
              width={381}
            />
            <InputGeneral
              error={fieldError}
              label={"Endpoint jwt"}
              value={jwtendpoint}
              onChange={(e) => setJwtendpoint(e)}
              required={true}
              width={381}
              maxLength={499}
            />
            <InputGeneral
              error={fieldError}
              label={"Endpoing de notificação"}
              value={notifyendpoint}
              onChange={(e) => setNotifyEndpoint(e)}
              required={true}
              width={381}
              maxLength={499}
            />
            <InputGeneral
              error={fieldError}
              label={"Endpoint de requisição"}
              value={requestendpoint}
              onChange={(e) => setRequestendpoint(e)}
              required={true}
              width={381}
              maxLength={499}
            />
          </>
        );
      case "FileSystemPassword":
        return (
          <InputAuth
            error={fieldError}
            label={itemEdit.description}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            setType={(type: typesInput) => setPasswordType(type)}
            icon={true}
            type={passwordType}
            maxLength={499}
          />
        );
      case "FileSystemPath":
        return (
          <InputGeneral
            error={fieldError}
            label={itemEdit.description}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
            startFocus={true}
          />
        );
      case "FileSystemUser":
        return (
          <InputGeneral
            error={fieldError}
            label={"Usuário"}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
            startFocus={false}
          />
        );
      case "PaginationList":
        return (
          <DropdownGlobal
            labelName={"Registros por página nas listagens"}
            options={selectedListPagination}
            error={fieldError}
            setSelected={(e: any) => setEditedValue(e)}
            required={false}
            editDropdown={valuePaginationName}
            defaultValue={valuePaginationName}
          />
        );
      case "Shortcuts":
        return (
          <ShortCuts
            playStop={playStop}
            setPlayStop={setPlayStop}
            actionBackTime={actionBackTime}
            setActionBackTime={setActionBackTime}
            actionNextTime={actionNextTime}
            setActionNextTime={setActionNextTime}
            timeBackNext={timeBackNext}
            setTimeBackNext={setTimeBackNext}
            slowSpeed={slowSpeed}
            setSlowSpeed={setSlowSpeed}
            fastSpeed={fastSpeed}
            setFastSpeed={setFastSpeed}
            fieldError={fieldError}
            listSecondsToNextBack={selectedListSecondsToNextBack}
            listFirstShortcut={selectedListFirstShortcut}
            listSecondShortcut={selectedListSecondShortcut}
            setTimeBackNextId={setTimeBackNextId}
            setPlayStopFirstId={setPlayStopFirstId}
            setPlayStopSecondId={setPlayStopSecondId}
            setActionBackTimeFirstId={setActionBackTimeFirstId}
            setActionBackTimeSecondId={setActionBackTimeSecondId}
            setActionNextTimeFirstId={setActionNextTimeFirstId}
            setActionNextTimeSecondId={setActionNextTimeSecondId}
            setFastSpeedFirstId={setFastSpeedFirstId}
            setFastSpeedSecondId={setFastSpeedSecondId}
            setSlowSpeedFirstId={setSlowSpeedFirstId}
            setSlowSpeedSecondId={setSlowSpeedSecondId}
            valueTimeBackNextName={valueTimeBackNextName}
            valuePlayStopFirstName={valuePlayStopFirstName}
            valuePlayStopSecondName={valuePlayStopSecondName}
            valueActionBackTimeFirstName={valueActionBackTimeFirstName}
            valueActionBackTimeSecondName={valueActionBackTimeSecondName}
            valueActionNextTimeFirstName={valueActionNextTimeFirstName}
            valueActionNextTimeSecondName={valueActionNextTimeSecondName}
            valueFastSpeedFirstName={valueFastSpeedFirstName}
            valueFastSpeedSecondName={valueFastSpeedSecondName}
            valueSlowSpeedFirstName={valueSlowSpeedFirstName}
            valueSlowSpeedSecondName={valueSlowSpeedSecondName}
          />
        );
      case "StorageProvider":
        return (
          <DropdownGlobal
            labelName={itemEdit.description}
            options={selectedStorageProviderList}
            error={fieldError}
            setSelected={(e: any) => setEditedValue(e)}
            required={false}
            editDropdown={selectedStorageProviderName}
            defaultValue={selectedStorageProviderName}
          />
        );
      case "StorageStringConnection":
        return (
          <InputGeneral
            error={fieldError}
            label={itemEdit.description}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
          />
        );
      case "CustomerProcedureDeadline":
        return (
          <ContainerBody>
            <ContainerInputs>
              <InputGeneral
                id="CustomerProcedureDeadlineIdInput"
                error={fieldError}
                label={itemEdit.description}
                value={editedValue}
                onChange={(e) => setEditedValue(e)}
                required={true}
                disabled={deadlineOff}
                type="number"
                width={381}
                maxLength={499}
              />
            </ContainerInputs>
            <ContainerSwitch>
              <CheckBoxGlobal
                id={itemEdit.id}
                checked={deadlineOff}
                label="Indeterminado"
                onChecked={() => {
                  setDeadlineOff(!deadlineOff);
                  setEditedValue(0);
                  setFieldError(false);
                }}
              />
            </ContainerSwitch>
          </ContainerBody>
        );
      case "NotificationAuto":
        return (
          <DropdownGlobal
            labelName={"Notificação automática"}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "TranscriptionProvider":
        return (
          <DropdownGlobal
            labelName={itemEdit.description}
            options={transcriptionProviderList}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            showDefaultValue
            editDropdown={selectedTranscriptionProvider}
            defaultValue={selectedTranscriptionProvider}
          />
        );
      case "TranscriptionSummary":
        return (
          <DropdownGlobal
            labelName={itemEdit.description}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "ProcedureMask":
        return (
          <InputGeneral
            error={fieldError}
            label={"Máscara para número do procedimento"}
            value={editedValue}
            onChange={(e) => setEditedValue(e)}
            required={true}
            width={381}
            maxLength={499}
          />
        );
      default:
        return <div />;
    }
  };

  useEffect(() => {
    let paginationLocal = localStorage.getItem("PaginationList");

    paginationLocal !== null
      ? setValeuRegisterPerPage(parseInt(paginationLocal))
      : setValeuRegisterPerPage(10);
  }, []);

  useEffect(() => {
    if (search) {
      setFilterList(
        configurationsList.filter((x: any) =>
          x.description.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilterList(configurationsList);
    }
  }, [search]);

  return (
    <>
      <AdminArea
        title={"Configurações globais"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text: any) => setSearch(text)}
        situation={true}
        list={filterList}
        headerAddButtonOnClick={() => {}}
        headerTrashButtonOnClick={() => {}}
        onClick={(item: any) => onPressEdit(item)}
        setItemReturnClick={() => {}}
        setOpenModal={setShowModal}
        setIsEdit={setItemEdit}
        permissionName={FORMS_ADMIN.SYSTEM_CONFIGURATION}
        onChangeSituation={() => {}}
        showCreateButton={false}
        showStatus={false}
        displayAtivoFilter={false}
        description={true}
      />
      <ModalRight
        isOpen={showModal}
        setIsOpen={closeAll}
        title={
          itemEdit && itemEdit.name === "Shortcuts"
            ? itemEdit.description
            : "Editar configuração"
        }
        buttonLabel="Aplicar"
        haveFooter={true}
        onClickAplicar={putConfiguration}
      >
        <ContainerModalRight>
          {itemEdit && itemEdit.name ? whatIsEdit(itemEdit.name) : <div />}
        </ContainerModalRight>
      </ModalRight>
    </>
  );
}
