import styled, { css } from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface IPropsContainer {
  width?: string;
  height?: string;
}
interface IProps {
  error?: boolean;
  focus?: boolean;
  selected: boolean;
  disable: boolean;
  height?: string;
}

export const DropDownContainer = styled("div")<IPropsContainer>`
  width: ${(props) => (props.width ? props.width : "370px")};
  margin-bottom: 22px;
`;

export const Label = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: #666;
  margin-bottom: 4.5px;
`;

export const DropDownHeader = styled("div")<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8em;
  padding: 12px 16px;
  font-weight: 500;
  margin: 0;
  margin-top: 4.5px;
  font-size: 18px;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#000" : stylesGlobalDefault.gray)};
  border: ${(props) =>
    props.error
      ? "2px solid " + stylesGlobalDefault.inputsBorderFocusError
      : "2px solid " + stylesGlobalDefault.inputsBorderFocusOff};
  border-radius: 4px;
  background: ${stylesGlobalDefault.white};

  cursor: pointer;
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  height: ${(props) => (props.height ? props.height : "auto")};

  ${(props) =>
    props.focus &&
    css`
      border: 2px solid ${stylesGlobalDefault.blue};
    `}
  span {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  height: 24px;
`;

export const DropDownListContainer = styled("div")`
  width: 36%;
  position: relative;
  user-select: none;
`;

export const DropDownList = styled("ul")<IPropsContainer>`
  padding: 0;
  margin: 0;
  width: ${(props) => (props.width ? props.width : "385px")};
  max-height: 200px;
  background: ${stylesGlobalDefault.white};
  //border: 2px solid #e5e5e5;
  border: 2px solid ${stylesGlobalDefault.grayLight};
  box-sizing: border-box;
  color: #7887a9;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  z-index: 99;

  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${stylesGlobalDefault.blue};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  position: absolute;
  transform: translateY(0px);
`;

interface ItemProps {
  selected: boolean;
}

export const ListItem = styled("li")<ItemProps>`
  list-style: none;
  padding: 10px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${stylesGlobalDefault.black};
  display: flex;
  justify-content: space-between;

  &:hover {
    background: ${stylesGlobalDefault.blueLight};
  }
  background-color: ${(props) =>
    props.selected ? stylesGlobalDefault.blueLight : stylesGlobalDefault.white};
`;
