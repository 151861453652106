import styled from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

interface IProps {
  error?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  width: 200px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.strong`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: ${stylesGlobalDefault.gray};
`;

export const Input = styled.input`
  border: ${(props: IProps) =>
    props.error ? "2px solid #e83845" : "2px solid #d1dbe3"};
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
