import { IDevicesParams } from "./interface";
import api from "../api";

const baseApi = "/UserCustomerPreferences";

export const customerPreferencesService = {
  add: async (params: IDevicesParams) => {
    try {
      const { data } = await api.post(`${baseApi}/Add`, params);
      return {
        success: true,
        message: "",
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message,
        body: null,
      };
    }
  },
  put: async (params: IDevicesParams) => {
    try {
      const { data } = await api.put(`${baseApi}/Update`, params);
      return {
        success: true,
        message: "",
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message,
        body: null,
      };
    }
  },
  GetByUserId: async (userId: string | number) => {
    try {
      const { data } = await api.get(`${baseApi}/GetByUserId?userId=${userId}`);
      return {
        success: true,
        message: "",
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message,
        body: null,
      };
    }
  },
};
