import { useEffect, useState } from "react";

import VMasker from "vanilla-masker";

import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { InputGeneral } from "../../../../components/Input";
import { useToast } from "../../../../hooks/toast";
import { SispServices } from "../../../../services/sisp";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";

interface ProcedureCustomerSourceCreateEdit {
  year: string;
  procedureNumber: string;
  procedureList: Array<any>;
  unitList: Array<any>;
  setYear: (e: string) => unknown;
  setProcedureNumber: (e: string) => unknown;
  setSelectedProcedure: (e: number) => unknown;
  setSelectedUnit: (e: number) => unknown;
  setUnitList: (e: any) => unknown;
  setProcedureList: (e: any) => unknown;
  fieldError: boolean;
}

export const ImportModal = (
  props: ProcedureCustomerSourceCreateEdit
): JSX.Element => {
  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      const { success, body, message } =
        await SispServices.getAllPoliceUnitByCustomerId();
      const {
        body: data,
        success: ok,
        message: text,
      } = await SispServices.getAllProcedureTypeByCustomerId();

      if (success) {
        props.setUnitList(body);
      }

      if (ok) {
        props.setProcedureList(data);
      }

      if (!success || !ok) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: message || text,
        });
      }
    })();
  }, []);

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Tipo de procedimento"
              defaultValue={""}
              setSelected={(e: any) => props.setSelectedProcedure(e)}
              required
              width="375px"
              height="24px"
              options={props.procedureList}
              wantAnObject
              error={props.fieldError}
            />
          </ContainerInputs>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Unidade Sisp"
              defaultValue={""}
              setSelected={(e: any) => props.setSelectedUnit(e)}
              required
              width="375px"
              height="24px"
              options={props.unitList}
              wantAnObject
              error={props.fieldError}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={
                props.fieldError
                  ? props.year
                    ? props.year.length < 4
                    : true
                  : false
              }
              value={props.year}
              maxLength={4}
              onChange={(e) => props.setYear(VMasker.toNumber(e))}
              type="text"
              label="Ano"
              required={true}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={props.fieldError && !props.procedureNumber}
              value={props.procedureNumber}
              onChange={(e) => props.setProcedureNumber(VMasker.toNumber(e))}
              type="text"
              label="Número do procedimento"
              required={true}
            />
          </ContainerInputs>
        </ContainerBody>
      </Container>
    </>
  );
};
