import { AxiosError } from "axios";

import { HearingTypeInterface } from "../../pages/app/HearingType";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import api from "../api";
import { returnResponse } from "../utils";

function validToken() {
  const authToken = getValueFromlocalStorage("@Kenta:access_Token");

  if (!authToken) {
    return false;
  } else {
    return true;
  }
}

export const HearingTypeService = {
  postHearingType: async (authToken: string | null, hearingType: any) => {
    // if (validToken()) {
    //   return {
    //     status: false,
    //     message: 'Não é possível adicionar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = {
        ...hearingType,
      };
      const { data } = await api.post<any>(`/HearingType/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  putHearingType: async (authToken: string | null, hearingType: any) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }

    try {
      const body = {
        ...hearingType,
      };
      const { data } = await api.put<HearingTypeInterface>(
        `/HearingType/Update`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  deleteHearingType: async (
    authToken: string | null,
    hearingTypeId: number
  ) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/HearingType/update?id=${hearingTypeId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
