import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  selected: boolean;
}

interface IContainerStatus {
  status: "warning" | "success" | "error" | "inactive";
}

const statusCode = {
  warning: "#F0CA68",
  success: "#30cd82",
  error: "#EB5861",
  inactive: "#A9C3C9",
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 304px;
  height: 45px;
  background: ${(props: IProps) => (props.selected ? "#E4F1FA" : "#ECF0F3")};
  border: ${(props: IProps) =>
    props.selected ? "2px solid #1d9bf0" : "2px solid #ECF0F3"};
  border-radius: 8px;
  padding: 20px;
  margin: 4px 0;
  cursor: pointer;

  &:hover {
    border: 2px solid ${stylesGlobalDefault.blue};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-left: 8px; */
`;

export const Title = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-size: 14px;
  font-weight: 500;
  color: ${stylesGlobalDefault.black};
`;

export const Description = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin-top: 10.5px;
  color: ${stylesGlobalDefault.gray};
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 29px;
`;

export const DateTitle = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const ContainerSchedule = styled.div<IContainerStatus>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: ${(props) => statusCode[props.status]};
  border-radius: 20px;
  margin-top: 11px;
`;

export const Scheduled = styled.span<IContainerStatus>`
  font-size: 10px;
  text-transform: uppercase;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  color: ${(props) =>
    props.status === "warning"
      ? stylesGlobalDefault.black
      : stylesGlobalDefault.white};
  font-style: normal;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
