import React, { useEffect } from "react";

import {
  Container,
  //body
  ContainerBody,
  ContainerTitleWithNumber,
  Title,
} from "./styles";
import { getValueFromlocalStorage } from "../../../features/forms/utils/sessionStorageEncrypt";
import { usePermissions } from "../../../hooks/permission";
import "./styles.css";

import { AddButton } from "../../AddButton";
import { QuantityComponent } from "../../../components/QuantityComponent";
import { TitleContainer } from "../../../components/Toast/styles";
import { CardAdminItem } from "../../../features/CardAdminItem";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { DropdownGlobal } from "../../DropdownGlobal";
import { InputGeneral } from "../../Input";

import { useSelector } from "react-redux";

import { ReactPaginateKenta } from "../../ReactPaginate";

import { useNavigate } from "react-router-dom";

type AdmProps = {
  title: string;
  InputSearchLabel: string;
  InputSearchEdit?: string;
  InputSearchOnChange?: (text: string) => any;
  list: Array<any>;
  situation: any;
  headerAddButtonOnClick: () => unknown;
  headerTrashButtonOnClick: () => unknown;
  onClick: (item: any) => void;
  setItemReturnClick: any;
  setOpenModal: any;
  searchText?: string;
  setIsEdit: any;
  permissionName: string;
  focusInputSearch?: boolean;
  onChangeSituation?: any;
  showCreateButton?: boolean;
  displayAtivoFilter?: boolean;
  showStatus?: boolean;
  description?: boolean;
  showSearch?: boolean;
};

export function AdminArea({
  title,
  InputSearchLabel,
  InputSearchEdit,
  situation,
  InputSearchOnChange,
  list,
  headerAddButtonOnClick,
  headerTrashButtonOnClick,
  onClick,
  permissionName,
  searchText,
  focusInputSearch,
  onChangeSituation,
  showCreateButton = true,
  displayAtivoFilter = true,
  showStatus = true,
  description = false,
  showSearch = true,
}: AdmProps) {
  const { loading } = useSelector((state: any) => state.loading);
  const { create, edit, read } = usePermissions(permissionName);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const options = [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 2,
      name: "Inativo",
    },
    {
      id: 3,
      name: "Todos",
    },
  ];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const listComponent = read
    ? list.map((item: any, index: number) => (
        <CardAdminItem
          key={index}
          id={item.id}
          name={description === true ? item.description : item.name}
          active={item.active}
          onClickEditAction={() => (edit ? onClick(item) : null)}
          disable={edit ? false : true}
          showStatus={showStatus}
        />
      ))
    : [];

  function clearURLParams() {
    navigate(window.location.pathname, { replace: true });
  }

  const onChange = (value: any) => {
    const filter = getValueFromlocalStorage("filter");
    if (typeof value === "number" && value !== Number(filter)) clearURLParams();
    onChangeSituation(value!);
  };

  const getSituationLabel = () => {
    if (situation === true) return "Ativo";
    if (situation === false) return "Inativo";
    return "Todos";
  };

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title>{title}</Title>
            <QuantityComponent value={list.length > 0 ? list.length : 0} />
          </ContainerTitleWithNumber>
          {create && showCreateButton && (
            <AddButton onClick={headerAddButtonOnClick} />
          )}
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        {showSearch && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <InputGeneral
              label={InputSearchLabel}
              onChange={InputSearchOnChange!}
              value={searchText}
              width={400}
              mt="1"
              startFocus={focusInputSearch}
            />
            {displayAtivoFilter ? (
              <div style={{ marginLeft: 40 }}>
                <DropdownGlobal
                  labelName="Situaçāo"
                  options={options}
                  setSelected={(value: any) => onChange(value)}
                  defaultValue={getSituationLabel()}
                  showDefaultValue
                  height="24px"
                  width="140px"
                  required={false}
                />
              </div>
            ) : null}
          </div>
        )}
        {!loading ? (
          <ReactPaginateKenta list={listComponent} title={title} />
        ) : null}
      </ContainerBody>
    </Container>
  );
}
