import { useState } from "react";

//Toast
import { useDispatch } from "react-redux";

import { ReportArea } from "../../../components/Body/ReportArea";
import { useToast } from "../../../hooks/toast";
import { reportService } from "../../../services/report";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export interface UserInterface {
  id: number;
  name: string;
  email: string;
  password: string;
  picture: null;
  customerId: number;
  emailValidated: true;
  termAccepted: true;
  createIn: string;
  updateIn: string;
  active: boolean;
}
export interface IUserCreateUnit {
  userId: {
    id: number;
    name: string;
    email: string;
    customerId: number;
    termAccepted: boolean;
    active: boolean;
  };
  policeUnitId: {
    id: number;
    name: string;
    description: string;
    customerId: number;
    extraContent: string;
    active: boolean;
  };
  userCustomerProfileId: {
    id: number;
    type: number;
    label: string;
  };
  expireIn: string;
  active: boolean;
}

export function ProceduresByCity() {
  const [element, setElement] = useState<UserInterface>();
  const [listReport, setListReport] = useState<UserInterface[]>([]);
  const [unitId, setUnitId] = useState();
  const user = getValueFromlocalStorage("@Kenta:user");
  const [initialDate, setInitialDate] = useState<any>("");
  const [endDate, setendDate] = useState<any>("");
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let userCustomerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    userCustomerId = user_parsed.customerId;
  }

  async function onLoadList() {
    dispatch(showLoading());
    const response = await reportService.getAllProceduresByCity(
      userCustomerId,
      initialDate,
      endDate
    );
    if (response) {
      if (response.status) {
        setListReport(response.data.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
  }
  function handleReport() {
    setIsActive(!isActive);
    onLoadList();
  }

  return (
    <>
      <ReportArea
        title={"Procedimentos por cidade"}
        InputSearchLabel="Buscar por:"
        list={listReport}
        headerAddButtonOnClick={() => {}}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={handleReport}
        setItemReturnClick={setElement}
        setOpenModal={() => {}}
        setIsEdit={false}
        permissionName={FORMS_ADMIN.POLICE_UNIT}
        onChangeSituation={setUnitId}
        initialDate={initialDate}
        endDate={endDate}
        setInitialDate={setInitialDate}
        setEndDate={setendDate}
      />
    </>
  );
}
