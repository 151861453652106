export const videoConstraints: VideoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
  deviceId: {
    exact: "default",
  },
};

export type VideoConstraints = {
  width: number;
  height: number;
  facingMode: string;
  deviceId:
    | {
        exact: string;
      }
    | undefined;
};
