import React from "react";

import {
  Background,
  Container,
  ContainerTextCenter,
  Content,
  Icon,
  IconsContainer,
  TopBar,
} from "./styles";
import microIcon from "../../assets/login/micro.svg";
import videoIcon from "../../assets/login/video.svg";

interface Props {
  children: React.ReactNode;
}

export const AuthScreen: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Background>
        <ContainerTextCenter>
          <TopBar />
          <strong>Agilidade e segurança</strong>
          <span>na coleta de informações</span>
          <IconsContainer>
            <Icon>
              <img src={microIcon} alt="" />
            </Icon>
            <Icon>
              <img src={videoIcon} alt="" />
            </Icon>
          </IconsContainer>
        </ContainerTextCenter>
      </Background>
      <Content>{children}</Content>
    </Container>
  );
};
