import { shade } from "polished";
import styled, { css } from "styled-components";
interface IProps {
  playing: boolean;
}

interface ISelecteRate {
  selected: boolean;
}

export const PlayerContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  object-fit: cover;

  display: block;
  width: 100%;
  height: 90px;
  background: #3e424e;

  @media only screen and (max-width: 1087px) {
    max-width: 998px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  width: 100%;
`;

export const LeftButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 42px;
`;
export const CenterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
`;
export const RightButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 42px;
`;

export const TimeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: ${(props) =>
    props.disabled
      ? "2px solid rgba(120, 135, 169, 0.5)"
      : "2px solid #1D9BF0"};
  background: none;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background: ${shade(0.2, "#1D9BF0")};
        border: 2px solid #fff;
      `}

    ${(props) =>
      props.disabled &&
      css`
        cursor: default;
      `}
  }
`;
export const PlayButton = styled.button<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${(props) => (props.playing ? "none" : "#1D9BF0")};
  border: ${(props) =>
    props.playing ? "2px solid #e98d0b" : "2px solid #fff"};
  margin: 0 16px;

  &:hover {
    background: ${(props) => (props.playing ? "none" : shade(0.2, "#1D9BF0"))};
  }
`;

export const Time = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-left: 30px;
`;

export const ButtonIcon = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
`;

export const ButtonIconVolume = styled.div`
  display: flex;
  background: none;
  border: 0;
  transition: 0.3s;

  img {
    width: 20px;
    height: 18px;
  }

  #volume_container {
    position: absolute;
    cursor: default;
    opacity: 0;
    display: none;
    margin-top: -10px;
    border-radius: 8px;
    padding: 10px 10px 10px 25px;
  }

  #volume {
    accent-color: #e98d0b;
    cursor: pointer;
  }

  #volume {
  }

  &:hover {
    #volume_container {
      transition: 0.3s;
      position: absolute;
      opacity: 1;
      display: block;
    }
  }
`;

export const PlaybackRateMenu = styled.div`
  position: absolute;
  margin-top: -230px;
  margin-left: -100px;
  width: 120px;
  background: #ffffff;
  border: 2px solid #d1dbe3;
  border-radius: 4px;
  position: absolute;
  padding: 4px;
`;

export const ItemRateSelected = styled.strong<ISelecteRate>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background: #e4f1fa;
  }

  ${(props) =>
    props.selected &&
    css`
      background: #e4f1fa;
    `}
`;

export const PlaybackRate = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-right: 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    #playbackList {
      transition: 0.3s;
      opacity: 1;
      display: block;
    }
  }
`;

export const PlaybackRateContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: none;
  border: 0;
  transition: 0.3s;

  #playbackList {
    display: none;
    opacity: 0;
  }

  &:hover {
    #playbackList {
      display: block;
      opacity: 1;
    }
  }
`;
