import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import Active from "../../../assets/svg/active.svg";
import Inactive from "../../../assets/svg/inactive.svg";
import { ChartContainer } from "../ChartContainer";
import {
  Column,
  ContainerIcon,
  ContainerItem,
  Divider,
} from "../ChartContainer/styles";
interface ChartProps {
  active: number;
  inactive: number;
}

ChartJS.register(ArcElement, Tooltip, Legend);

export function UsersSituationChart({ active, inactive }: ChartProps) {
  const data = {
    labels: ["Ativo", "Inativo"],
    datasets: [
      {
        data: [active, inactive],
        backgroundColor: ["#4CAF50", "#FF5252"],
      },
    ],
  };

  return (
    <ChartContainer title="Situação dos usuários">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Divider
          style={{
            background: "#CCC",
          }}
        />
        <ContainerItem>
          <Column>
            <ContainerIcon
              style={{
                backgroundColor: "#05C448",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="" src={Active} width={20} height={20} />
            </ContainerIcon>
            <span
              style={{
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              Ativos
            </span>
          </Column>
          <Column>
            <span
              style={{
                fontSize: 12,
              }}
            >
              {active}
            </span>
          </Column>
        </ContainerItem>
        <Divider />
        <ContainerItem>
          <Column>
            <ContainerIcon
              style={{
                backgroundColor: "#CCC",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="" src={Inactive} width={20} height={20} />
            </ContainerIcon>
            <span
              style={{
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              Inativos
            </span>
          </Column>
          <Column>
            <span
              style={{
                fontSize: 12,
              }}
            >
              {inactive}
            </span>
          </Column>
        </ContainerItem>
        <Divider />
      </div>
    </ChartContainer>
  );
}
