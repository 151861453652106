import styled from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
`;

export const Title = styled.h3`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${stylesGlobalDefault.blackMenu1};
`;

export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
`;

//Body
export const ContainerBody = styled.div`
  /* width: 100% - 48px; */
  height: auto;
  overflow: hidden;
  padding: 0 24px;
  padding-bottom: 20px;
`;

export const ContainerSearch = styled.div`
  width: 60%;
  height: 100px;
  padding-top: 40px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerInput = styled.div`
  width: 80%;
  height: 100px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px -40px;
  width: 100% - 40px;
  background: ${stylesGlobalDefault.backgroundListItemColor};
  margin-bottom: 4px;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid ${stylesGlobalDefault.backgroundListItemColor};
  cursor: pointer;

  &:hover {
    border: 2px solid #1d9bf0;
  }
`;

export const LabelCard = styled.label``;

//List
export const ContainerList = styled.div`
  width: 100%;
  height: 78vh;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (min-height: 850px) {
    height: 82vh;
  }
`;
