import { PoliceUnit } from "../../../../utils/entities/policeUnit";
import { PoliceUnitTypes } from "../actions/actios.types";

interface PoliceUnitState {
  policeUnit: PoliceUnit | null;
}

const initialState: PoliceUnitState = {
  policeUnit: null,
};

const policeUnitReducer = (
  state = initialState,
  action: any
): PoliceUnitState => {
  switch (action.type) {
    case PoliceUnitTypes.SET_POLICE_UNIT:
      return {
        ...state,
        policeUnit: action.payload,
      };
    default:
      return state;
  }
};
export default policeUnitReducer;
