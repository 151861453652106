import React from "react";

import { Container, ItemsContainer } from "./styles";
import { ProcedureAttendantSidebar } from "../ProcedureAttendantSidebar";
import { ProcedureDataSidebar } from "../ProcedureDataSidebar";
import { ScheduledHearingSidebar } from "../ScheduledHearingSidebar";

type IRightSidebar = {
  itemData: any | null;
  onEditItem?: () => void;
  onClickAddSidebar?: any;
  onClickGravarOitiva: (item: any) => void;
  onClickEditarOitiva: (item: any) => void;
  loadingList: () => unknown;
  pattern: string;
};

export const RightSidebar: React.FC<IRightSidebar> = ({
  itemData,
  onEditItem,
  onClickAddSidebar,
  onClickGravarOitiva,
  onClickEditarOitiva,
  loadingList,
  pattern,
}) => {
  return (
    <Container>
      <ItemsContainer>
        <ProcedureDataSidebar
          pattern={pattern}
          onEditItem={onEditItem}
          itemData={itemData}
        />
      </ItemsContainer>
      <ItemsContainer>
        <ScheduledHearingSidebar
          loadingList={loadingList}
          onClickGravarOitiva={(item: any) => onClickGravarOitiva(item)}
          onClickEditarOitiva={(item: any) => onClickEditarOitiva(item)}
          dateOitivas={itemData.hearingList}
          onClickAddSidebar={onClickAddSidebar}
        />
      </ItemsContainer>
      <ItemsContainer>
        <ProcedureAttendantSidebar itemData={itemData} />
      </ItemsContainer>
    </Container>
  );
};
