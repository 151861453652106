import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { Container, ContainerBody, ContainerList, Title } from "./styles";
import { ButtonGlobal } from "../../../components/ButtonGlobal/ButtonGlobal";
import { DropdownGlobal } from "../../../components/DropdownGlobal";
import { InputDateGlobal } from "../../../components/InputDateGlobal";
import { ListEmpty } from "../../../components/ListEmpty";
import { QuantityComponent } from "../../../components/QuantityComponent";
import { ReactPaginateKenta } from "../../../components/ReactPaginate";
import { TitleContainer } from "../../../components/Toast/styles";
import { CardReportLogOperation } from "../../../features/CardReportLogOperation";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { useToast } from "../../../hooks/toast";
import { reportService } from "../../../services/report";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { ContainerTitleWithNumber } from "../profiles/styles";

//Load

export function LogOperations() {
  const today = new Date().toISOString().split("T")[0];
  const [listOfLogs, setListOfLogs] = useState([]);
  const [initialDate, setInitialDate] = useState<string>(today);
  const [loading, setLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>(today);
  const user = getValueFromlocalStorage("@Kenta:user");
  let customerId: any = null;
  const dispatch = useDispatch();
  const { addToast } = useToast();

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }
  const [dispositivo, setDispositivo] = useState("Todos");
  const [operacao, setOperacao] = useState(12);
  const inputRefNew = React.useRef<HTMLInputElement>(null);
  const options = [
    {
      id: 0,
      name: "API",
    },
    {
      id: 2,
      name: "Celular",
    },
    {
      id: 1,
      name: "PC",
    },
    {
      id: 3,
      name: "Todos",
    },
  ];
  const optionsOperation = [
    {
      id: 1,
      name: "Inserção",
    },
    {
      id: 2,
      name: "Atualização",
    },
    {
      id: 3,
      name: "Exclusão",
    },
    {
      id: 4,
      name: "Login",
    },
    {
      id: 5,
      name: "Logoff",
    },
    {
      id: 6,
      name: "Exportação",
    },
    {
      id: 7,
      name: "Importação",
    },
    {
      id: 8,
      name: "Gravação",
    },
    {
      id: 9,
      name: "Visualização",
    },
    {
      id: 10,
      name: "Publicação",
    },
    {
      id: 11,
      name: "Validação",
    },
    {
      id: 12,
      name: "Todos",
    },
  ];

  useEffect(() => {
    if (inputRefNew.current) {
      inputRefNew.current.focus();
    }
  }, []);

  const logsOperation = listOfLogs.map((item: any, index: number) => (
    <CardReportLogOperation
      userName={item.userName}
      operation={item.operation}
      systemObject={item.systemObject}
      dateTime={item.date}
      notPointer
    />
  ));

  async function searchReportLogs() {
    if (!customerId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa de um customer id",
      });
      return;
    }
    if (!dispositivo) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar um dispositivo",
      });
      return;
    }

    if (!operacao) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma operação",
      });
      return;
    }

    if (initialDate > endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Data inicial não pode ser maior que a data final",
      });
      return;
    }
    dispatch(showLoading());
    setLoading(true);
    let device = dispositivo === "Todos" ? null : dispositivo;
    let operation = operacao === 12 ? null : operacao;

    setListOfLogs([]);
    const response = await reportService.getAllLogOperations(
      customerId,
      initialDate + " 00:00",
      endDate + " 23:59",
      operation,
      device
    );

    if (response && response.data && response.data.data)
      setListOfLogs(response.data.data);

    dispatch(hideLoading());
    setLoading(false);
  }

  function onChangeDispositivo(value: any) {
    setDispositivo(value);
  }
  function onChangeOperacao(value: any) {
    setOperacao(value);
  }

  function isCurrentDateOlder(date: Date, dateToCheck: Date): boolean {
    // Compare the current date with the given date
    if (dateToCheck.getTime() > date.getTime()) {
      return true; // The current date is older
    }

    return false; // The current date is not older
  }

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title>{"Log de operações"}</Title>
            <QuantityComponent
              value={listOfLogs.length > 0 ? listOfLogs.length : 0}
            />
          </ContainerTitleWithNumber>
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <>
            <div style={{ marginLeft: 0, marginTop: 40 }}>
              <DropdownGlobal
                labelName="Dispositivo"
                options={options}
                setSelected={(value: any) => {
                  if (typeof value === "string") onChangeDispositivo(value);
                }}
                defaultValue={"Todos"}
                showDefaultValue
                height="24px"
                width="140px"
                required={false}
              />
            </div>
            <div style={{ marginLeft: 20, marginTop: 40 }}>
              <DropdownGlobal
                labelName="Operação"
                options={optionsOperation}
                defaultValue={"Todos"}
                showDefaultValue
                setSelected={(value: any) => {
                  if (typeof value === "number") onChangeOperacao(value);
                }}
                //   defaultValue={
                //     situation ? "Ativo" : situation === false ? "Inativo" : "Todos"
                //   }
                //   showDefaultValue
                height="24px"
                width="140px"
                required={false}
              />
            </div>
            <div style={{ marginLeft: 20, marginTop: 40 }}>
              <InputDateGlobal
                label="Data inicial"
                required={false}
                type={"date"}
                value={initialDate}
                onChange={setInitialDate}
                disabled={false}
              />
            </div>
            <div style={{ marginLeft: 20 }}>
              <InputDateGlobal
                label="Data Final"
                required={false}
                type={"date"}
                value={endDate}
                // onChange={(event) => {
                //   if (
                //     isCurrentDateOlder(new Date(initialDate), new Date(event))
                //   )
                //     setEndDate(event);
                //   console.log("data fim", endDate)
                // }}
                onChange={setEndDate}
                disabled={false}
              />
            </div>
          </>
          <div style={{ marginLeft: 12, marginBottom: 28 }}>
            <ButtonGlobal
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={() => searchReportLogs()}
            >
              {" "}
              Gerar relatório
            </ButtonGlobal>
          </div>
        </div>
        <ContainerList>
          {!loading ? (
            listOfLogs && listOfLogs.length > 0 ? (
              <>
                <CardReportLogOperation
                  userName={"Usuário"}
                  operation={"Operação"}
                  systemObject={"Objeto"}
                  dateTime={"Data"}
                  notPointer
                  background
                />
                <ReactPaginateKenta
                  height="50vh"
                  list={logsOperation}
                  title="Logs de operações"
                />
              </>
            ) : (
              <ListEmpty title="relatório" typeImg="file" />
            )
          ) : null}
        </ContainerList>
      </ContainerBody>
    </Container>
  );
}
