import { AxiosError } from "axios";

import { Procedure, ProcedureFilterSearch, ProcedureResponse } from "./types";
import api from "../api";
import { returnResponse } from "../utils";

export const ProcedureService = {
  getAllByPoliceUnit: async (unitId: number) => {
    try {
      const { data } = await api.get<ProcedureResponse>(
        `/ProcedureCustomer/GetAllByFilters`,
        {
          params: {
            policeUnitid: unitId,
            active: true,
          },
        }
      );

      return {
        status: true,
        data: ProcedureService.sortProcedureByDate(data.data),
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  sortProcedureByDate: (procedureList: Procedure[]) => {
    return procedureList.sort(
      (a, b) =>
        new Date(b.expectedDate).getTime() - new Date(a.expectedDate).getTime()
    );
  },
  getAllByFilters: async (
    policeUnitId: number,
    filter: ProcedureFilterSearch
  ) => {
    if (!policeUnitId) {
      return {
        status: false,
        message: "Não é possivel buscar sem a unidade de policia",
      };
    }

    try {
      let params: any = {
        policeUnitId: policeUnitId,
      };

      if (filter) {
        params = {
          ...params,
          ...filter,
        };
      } else {
        params = {
          ...params,
        };
        params.active = true;
      }

      const { data } = await api.get<ProcedureResponse>(
        `/ProcedureCustomer/GetAllByFilters`,
        {
          params,
        }
      );

      return {
        status: true,
        data: ProcedureService.sortProcedureByDate(data.data),
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
