import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const ContainerProgressBar = styled.div`
  width: 30px;
  height: 30px;
`;

export const FileTitle = styled.span`
  font-size: 14px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 400;
`;

export const ConcludedIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;
