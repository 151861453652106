import { useState, useEffect, useRef, useCallback } from "react";

import { useDispatch } from "react-redux";

import { ChangePassword } from "../../components/ChangePassword";
import PhotoWebCam from "../../components/PhotoWebCam";
import {
  Container,
  ContainerBody,
} from "../../pages/app/StyleCSS/bodyCreateEdit";
import { hideLoading, showLoading } from "../../store/modules/login/actions";

interface IMyAccount {
  onClickModal: boolean;
  setOnClickModal: (e: boolean) => void;
  changePicture: boolean;
  setChangePicture: (e: boolean) => void;
  closeModal: () => void;
}

interface ChangePasswordRef {
  handleSubmitNewPassword: () => Promise<void>;
}

interface UploadImageRef {
  handleUploadImage: () => Promise<void>;
}

export const MyAccount = ({
  onClickModal,
  setOnClickModal,
  changePicture,
  setChangePicture,
  closeModal,
}: IMyAccount) => {
  const [passwordOld, setPasswordOld] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const changePasswordRef = useRef<ChangePasswordRef | null>(null);
  const uploadImageRef = useRef<UploadImageRef | null>(null);

  const dispatch = useDispatch();

  const handleSubmitForm = useCallback(async () => {
    try {
      dispatch(showLoading());
      await changePasswordRef.current?.handleSubmitNewPassword();
      await uploadImageRef.current?.handleUploadImage();
      setOnClickModal(false);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      console.error("Error submitting form:", error);
    }
  }, [setOnClickModal, dispatch]);

  useEffect(() => {
    if (onClickModal) handleSubmitForm();
  }, [handleSubmitForm, onClickModal]);

  return (
    <Container>
      <ContainerBody>
        <PhotoWebCam
          ref={uploadImageRef}
          changePicture={changePicture}
          setChangePicture={setChangePicture}
        />
        <ChangePassword
          isLogged
          oldPassword={passwordOld}
          setOldPassword={setPasswordOld}
          newPassword={password}
          setNewPassword={setPassword}
          confirmPassword={passwordConfirm}
          setConfirmPassword={setPasswordConfirm}
          ref={changePasswordRef}
          closeModal={closeModal}
        />
      </ContainerBody>
    </Container>
  );
};
