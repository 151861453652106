import { AxiosError } from "axios";

import { LogOperation } from "../../utils/entities/logOperation";
import api from "../api";
import { returnResponse } from "../utils";

export const HearingMinuteServices = {
  add: async ({
    hearingId,
    content,
    customerTemplateId,
  }: {
    hearingId: string;
    content: string;
    customerTemplateId: number;
  }) => {
    try {
      const { data } = await api.post("/HearingMinute/Add", {
        hearingId: hearingId,
        content,
        customerTemplateId,
        updateIn: new Date(),
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      });

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  update: async ({
    id,
    hearingId,
    content,
    customerTemplateId,
  }: {
    id: string;
    hearingId: string;
    content: string;
    customerTemplateId: any;
  }) => {
    try {
      const { data } = await api.put("/HearingMinute/Update", {
        id,
        hearingId,
        content,
        customerTemplateId: customerTemplateId,
        updateIn: new Date(),
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      });

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllByHearingId: async ({ hearingId }: { hearingId: string }) => {
    try {
      const { data } = await api.get(
        `/HearingMinute/GetAllByHearingId?hearingId=${hearingId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  patchTemplate: async ({
    customerTemplateId,
    id,
  }: {
    customerTemplateId: number;
    id: number;
  }) => {
    try {
      const patchFile = [
        {
          op: "replace",
          value: customerTemplateId,
          path: "customerTemplateId",
        },
      ];

      const { data } = await api.patch(
        `/HearingMinute/Update?id=${id}`,
        patchFile
      );

      return {
        success: true,
        body: data.data,
        message: "successo",
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
};
