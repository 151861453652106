import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const ContainerCheckBoxMultipleIds = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
`;

export const TitleCheckBoxMultipleIds = styled.label`
  color: ${stylesGlobalDefault.black};
  align-items: start;
  text-align: start;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: bold;
`;

export const SpaceCheckBoxMultipleIds = styled.label`
  font-size: 14px;
  display: flex;
  height: 36px;
`;
