import {
  HIDE_LOADING,
  LOGIN_USER,
  SELECT_PROFILE,
  SHOW_LOADING,
  USER_DATA,
} from "../actions/actios.types";

const INITIAL_STATE: any = {
  user: null,
  profile: null,
  loading: false,
  userData: {
    userId: null,
  },
};

const loading = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.user,
      };
    case SHOW_LOADING:
      return { ...state, loading: true };
    case HIDE_LOADING:
      return { ...state, loading: false };
    case SELECT_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    case USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    default:
      return state;
  }
};

export default loading;
