/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";

import { FiRefreshCw } from "react-icons/fi";
import ReactLoading from "react-loading";

import {
  Container,
  HeaderContainer,
  MarkingTime,
  MarkingTitle,
  MarkingDescription,
  HeaderTextContainer,
  ItemContainer,
  ContainerDelete,
  IconsContainer,
  Empty,
} from "./styles";
import deleteIcon from "../../../assets/hearingDetails/delete.svg";
import editIcon from "../../../assets/hearingDetails/edit.svg";
import hideIcon from "../../../assets/hearingDetails/hide.svg";
import showIcon from "../../../assets/hearingDetails/show.svg";
import plusIcon from "../../../assets/procedure/plus.svg";
import { useToast } from "../../../hooks/toast";
import { IMarkingType } from "../../../pages/app/hearingDetails";
import { markingService } from "../../../services/app/marking";
import {
  ContainerButton,
  NewMarkingButton,
  SubContainerButton,
} from "../styles";
import { ContainerButtonRefresh } from "../Transcription/styles";
//Toast

type IMarkingContent = {
  marking: IMarkingType;
  setOpenModal: (bool: boolean) => unknown;
  onDeleteMarking: (marking: any) => unknown;
  onEditMarking: (marking: any) => unknown;
  data: Array<any>;
  currentIndex: number;
  setCurrentIndex: (number: number) => unknown;
  isBeginin?: boolean;
  processCuts: () => void;
  onClickRefreshStatusCuts: () => void;
};

export const MarkingContent: React.FC<IMarkingContent> = ({
  marking,
  onDeleteMarking,
  onEditMarking,
  setOpenModal,
  processCuts,
  data = [],
  currentIndex,
  setCurrentIndex,
  onClickRefreshStatusCuts,
  isBeginin,
}) => {
  const [height, setHeight] = useState(innerHeight);
  const { addToast } = useToast();
  const [loadingStatus, setLoadingStatus] = useState(false);

  function retornarStatus(status: any) {
    switch (status) {
      case -1:
        return "Aguardando";
      case 0:
        return "Na fila";
      case 1:
        return "Carregando vídeo";
      case 2:
        return "Cortando vídeo";
      case 3:
        return "Cortado";
      case 4:
        return "Erro ao carregar video";
      case 5:
        return "Erro ao cortar video";
      case 6:
        return "Erro no upload do video";
      default:
        return "Aguardando";
    }
  }
  function secondsToTime(seconds: number) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    var formattedMinutes = ("0" + minutes).slice(-2);
    var formattedSeconds = ("0" + remainingSeconds).slice(-2);
    var timeString = formattedMinutes + ":" + formattedSeconds;
    return timeString;
  }
  useEffect(() => {
    let userAgent = window.navigator.userAgent;

    if (userAgent.match(/Windows/i)) {
      setHeight(innerHeight + 8);
    } else {
      setHeight(innerHeight);
    }
  }, [innerHeight, innerWidth]);

  const handleProcessOrReloadCuts = () => {
    if (hasErrorInCuts()) {
      reloadCuts();
    } else {
      processCuts();
    }
  };

  const hasErrorInCuts = () => {
    if (data.length > 0) {
      let hasErrors = data.find((x) => x.status >= 4 && x.status <= 6);
      if (hasErrors) {
        return true;
      }
      return false;
    }
  };

  async function reloadCuts() {
    try {
      let listAux: Array<any> = [];
      data.forEach((element) => {
        listAux.push({ ...element, status: 0 });
      });

      for (let i = 0; i < listAux.length; i++) {
        await markingService.patch(
          listAux[i].id.toString(),
          "Status",
          listAux[i].status.toString()
        );
      }

      await refresgCuts();
    } catch {
      addToast({
        type: "error",
        title: "Erro ao fazer marcação",
        description: "Ocorreu um erro inesperado",
      });
      return false;
    }
  }

  async function refresgCuts() {
    setLoadingStatus(true);
    await onClickRefreshStatusCuts();
    setLoadingStatus(false);
  }

  return (
    <>
      <ContainerButtonRefresh
        onClick={async () => {
          refresgCuts();
        }}
      >
        {loadingStatus ? (
          <ReactLoading type="spin" color="#5a5a5a" width={20} height={20} />
        ) : (
          <FiRefreshCw />
        )}
      </ContainerButtonRefresh>
      <Container height={height}>
        {data.length ? (
          data.map((item, index) => (
            <ItemContainer selected={index === currentIndex} key={item.id}>
              <HeaderContainer>
                <MarkingTime
                  onClick={() => marking.toggleMarkingTime(item.offSet1)}
                >
                  {secondsToTime(item.offSet1)}-{secondsToTime(item.offSet2)}
                </MarkingTime>
                <HeaderTextContainer>
                  <MarkingTitle>{retornarStatus(item.status)}</MarkingTitle>

                  <IconsContainer>
                    {index === currentIndex && (
                      <ContainerDelete>
                        <img
                          alt=""
                          onClick={() => onEditMarking(item)}
                          src={editIcon}
                        />
                        <img
                          alt=""
                          onClick={() => onDeleteMarking(item)}
                          src={deleteIcon}
                        />
                      </ContainerDelete>
                    )}
                    <img
                      alt=""
                      onClick={() =>
                        setCurrentIndex(index === currentIndex ? -1 : index)
                      }
                      src={index === currentIndex ? hideIcon : showIcon}
                    />
                  </IconsContainer>
                </HeaderTextContainer>
              </HeaderContainer>
              {index === currentIndex && (
                <MarkingDescription>{item.subject}</MarkingDescription>
              )}
            </ItemContainer>
          ))
        ) : (
          <center>
            <Empty>Nenhuma marcação criada</Empty>
          </center>
        )}
      </Container>

      {/* <ContainerButton>
        <NewMarkingButton
          style={{
            background: "#6fab7f",
          }}
          onClick={handleProcessOrReloadCuts}
        >
          <strong>
            {hasErrorInCuts() ? "Reprocessar" : "Processar cortes"}
          </strong>
        </NewMarkingButton>
        <NewMarkingButton
          style={{
            background: isBeginin ? "#1D9BF0" : "#f5c242",
          }}
          onClick={() => {
            if (marking.playerState.playbackRate > 1) {
              addToast({
                type: "warning",
                title: "Impossível fazer marcação",
                description:
                  "Para fazer uma marcação é necessário o video está em velocidade 1x",
              });
            } else {
              if (marking.playerState.playing) {
                marking.toggleVideoPlay();
              }
              setOpenModal(true);
            }
          }}
        >
          <img src={plusIcon} alt="" />{" "}
          <strong style={{ marginLeft: 10 }}>
            {isBeginin ? "Nova marcação" : "Finalizar"}
          </strong>
        </NewMarkingButton>
      </ContainerButton> */}

      <ContainerButton>
        <SubContainerButton>
          <NewMarkingButton
            style={{ background: "#6fab7f" }}
            onClick={handleProcessOrReloadCuts}
          >
            <strong>
              {hasErrorInCuts() ? "Reprocessar" : "Processar cortes"}
            </strong>
          </NewMarkingButton>

          <NewMarkingButton
            style={{
              background: isBeginin ? "#1D9BF0" : "#f5c242",
            }}
            onClick={() => {
              if (marking.playerState.playbackRate > 1) {
                addToast({
                  type: "warning",
                  title: "Impossível fazer marcação",
                  description:
                    "Para fazer uma marcação é necessário o video estar em velocidade 1x",
                });
              } else {
                if (marking.playerState.playing) {
                  marking.toggleVideoPlay();
                }
                setOpenModal(true);
              }
            }}
          >
            <img src={plusIcon} alt="" />{" "}
            <strong style={{ marginLeft: 10 }}>
              {isBeginin ? "Nova marcação" : "Finalizar"}
            </strong>
          </NewMarkingButton>
        </SubContainerButton>
      </ContainerButton>
    </>
  );
};
