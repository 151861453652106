/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from "react";

import { AxiosError } from "axios";
import { useDispatch } from "react-redux";

import { Container, Body } from "./styles";
import { ModalConfirmation } from "../../../components/ModalConfirmation";
import ModalRight from "../../../components/ModalRight";
import { HeaderHearing } from "../../../features/HeaderHearing";
import HearingDetailsSidebar from "../../../features/HearingDetailsSidebar";
import { MarkingChildrenModal } from "../../../features/MarkingChildren";
import { VideoPlayer } from "../../../features/VideoPlayer";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { markingService } from "../../../services/app/marking";
import { IHearingIndex } from "../../../services/app/marking/interface";
import { HearingService } from "../../../services/hearing";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { useQuery } from "../../../utils/get";
// import video from '../../../assets/videos/b125cb4c8d194752b0bf5459144b6d22_03072023134228.mp4';

export interface IMarkingType {
  toggleMarkingTime: (time: number) => unknown;
  toggleRate: (rate: number) => unknown;
  toggleVideoPlay: () => unknown;
  hearingRecord: any;
  file?: any;
  playerState: {
    playing: boolean;
    progress: number;
    currentTime: string;
    duration: string;
    offset1: string;
    offset2: string;
    secondsCurrentTime: number;
    secondsDuration: number;
    volume: number;
    playbackRate: number;
    marking: any;
  };
}

export interface Transcription {
  id: number;
  guid: string;
  hearingFileId: number;
  userId: number;
  customerTemplateId: number;
  logUserId: number;
  logPoliceUnitId: number;
  status: number;
  message: string;
  active: boolean;
  autoTranscription: boolean;
  sendEmail: boolean;
  textPlain: string;
  textPlainSummary: string;
  title: string;
  createIn: string;
  updateIn: string;
  startDate: string;
  finishDate: string;
  device: string | null;
  ip: string | null;
}

export const HearingDetails: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [marking, setMarking] = useState<IMarkingType>({
    toggleMarkingTime: () => ({}),
    toggleRate: (rate: number) => ({}),
    toggleVideoPlay: () => ({}),
    hearingRecord: {},
    playerState: {
      playing: false,
      progress: 0,
      currentTime: "00:00",
      duration: "00:00",
      offset1: "00:00",
      offset2: "00:00",
      secondsCurrentTime: 0,
      secondsDuration: 0,
      volume: 1,
      playbackRate: 1,
      marking: null,
    },
  });
  const [isBegining, setIsBegining] = useState(true);
  const [subject, setSubject] = useState("");
  const [hearingVideo, setHearingVideo] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [attendantId, setAttendantId] = useState<number>();
  const [procedureItem, setProcedureItem] = useState();
  const [hearingItemDto, setHearingItemDto] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [markingError, setMarkingError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemMarking, setItemMarking] = useState<any>(null);
  const [markingList, setMarkingList] = useState<Array<any>>([]);
  const [transcription, setTranscription] = useState<Transcription[] | never[]>(
    []
  );
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const query = useQuery();
  const manualTranscriptionRef = useRef<any>(null);
  const [showCutButton, setShowCutButton] = useState(false);

  const [openModalFile, setOpenModalFile] = useState(false);

  const hearingId = query.get("hearingId");
  const procedureId = query.get("procedureId");

  const getIndexList = async () => {
    const { data } = await api.get(
      `/HearingIndex/GetAllByHearingId?hearingId=${hearingId}`
    );

    if (data.data.length > 0) {
      const responseSorted = data.data.sort((a: any, b: any) => {
        return a.offSet > b.offSet ? 0 : -1;
      });
      setMarkingList(responseSorted);
      setShowCutButton(true);
    } else {
      setMarkingList(data.data);
    }
  };

  const getProcedureInformation = async () => {
    try {
      const { success, body } = await HearingService.getHearingDto(
        hearingId as any
      );

      if (success) setHearingItemDto(body);

      const { data } = await api.get(
        `/ProcedureCustomer/Get?id=${procedureId}`
      );
      const procedureCustomerResp = data.data;
      if (procedureCustomerResp) {
        const { data: customerSource } = await api.get(
          `/ProcedureCustomerSource/Get?id=${procedureCustomerResp.procedureCustomerSourceId}`
        );
        setProcedureItem({
          ...data.data,
          procedureCustomerSourceName: customerSource.data.name,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsEdit(false);
    setItemMarking(null);
    setCurrentIndex(-1);
    setMarkingError(false);
    setSubject("");
  };

  function timeToSeconds(timeString: string) {
    var timeArray = timeString.split(":");
    var minutes = parseInt(timeArray[0]);
    var seconds = parseInt(timeArray[1]);
    var totalSeconds = minutes * 60 + seconds;
    return totalSeconds;
  }

  const createNewMarking = async () => {
    setIsBegining(false);

    try {
      if (!hearingItemDto.attendantId) {
        addToast({
          title: "Criação de marcação",
          description:
            "Para criar uma marcação é necessário selecionar um participante.",
          type: "warning",
        });

        return setMarkingError(true);
      }

      if (
        !isBegining &&
        marking.playerState.offset2 < marking.playerState.offset1
      ) {
        addToast({
          title: "Marcação inválida",
          description:
            "Não é possível criar uma marcação com o final menor que o inicio",
          type: "warning",
        });

        dispatch(hideLoading());
        closeModal();
        return;
      }

      if (!isBegining && (!subject || subject === "")) {
        addToast({
          title: "Marcação inválida",
          description: "Não é possível criar uma marcação sem descrição",
          type: "warning",
        });

        dispatch(hideLoading());
        // closeModal();
        return;
      }

      if (!isBegining) {
        const body = {
          hearingId: Number(hearingId),
          attendantId: hearingItemDto.attendantId,
          offset1: Number(timeToSeconds(marking.playerState.offset1)),
          offset2: Number(timeToSeconds(marking.playerState.offset2)),
          recordDate: new Date(),
          duration: marking.playerState.secondsDuration,
          subject: subject,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };
        dispatch(showLoading());

        await api.post("/HearingIndex/Add", body);

        await getIndexList();

        closeModal();
        setIsBegining(true);
        addToast({
          title: "Marcação finalizada",
          description: "Sua marcação foi criada com sucesso.",
          type: "success",
        });
      } else {
        addToast({
          title: "Marcação iniciada",
          description: "Marcação iniciada: Passo 1 de 2",
          type: "success",
        });
      }
      closeModal();
      dispatch(hideLoading());
    } catch (error) {
      if (error instanceof AxiosError) {
        addToast({
          type: "error",
          title: "Erro",
          description: error.response?.data.message,
        });
      } else {
        addToast({
          title: "Erro na marcação",
          description:
            "Houve um error ao criar a marcação, tente novamente mais tarde ou entre em contato com o suporte.",
          type: "warning",
        });
      }
      dispatch(hideLoading());
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    closeModal();
  };

  const onDeleteMarking = (item: any) => {
    setItemMarking(item);
    setShowDeleteModal(true);
  };

  const handleUpdateMarking = async () => {
    try {
      dispatch(showLoading());
      const body: IHearingIndex = {
        id: itemMarking.id,
        hearingId: itemMarking.hearingId,
        attendantId: hearingItemDto.attendantId,
        offset: marking.playerState.secondsCurrentTime,
        recordDate: new Date(),
        duration: marking.playerState.secondsDuration,
        subject: subject,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      await markingService.update(body);
      await getIndexList();
      closeModal();

      dispatch(hideLoading());
      addToast({
        title: "Atualização de marcação",
        description: "A atualização da marcação foi feita com sucesso",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Atualização de marcação",
        description: "Ocorreu um error ao atualizar sua marcação",
        type: "warning",
      });
    }
  };

  function secondsToTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + remainingSeconds).slice(-2);
    const timeString = formattedMinutes + ":" + formattedSeconds;
    return timeString;
  }

  const onEditMarking = (item: any) => {
    setItemMarking(item);
    setMarking({
      ...marking,
      playerState: {
        ...marking.playerState,
        offset1: secondsToTime(item.offSet1),
        offset2: secondsToTime(item.offSet2),
      },
    });
    setIsOpen(true);
    setIsEdit(true);
    setSubject(item.subject);
  };

  async function processarCortes() {
    if (markingList.length > 0) {
      try {
        await api.post(
          "/HearingIndex/SplitUpVideo",
          {},
          {
            params: {
              hearingId: Number(hearingId),
            },
          }
        );
        addToast({
          type: "success",
          title: "Sucesso",
          description: "Processando cortes",
        });
        await getIndexList();
      } catch (error) {
        if (error instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Erro",
            description: error.response?.data?.message,
          });
        } else {
          addToast({
            title: "Erro ao processar cortes",
            description: "Houve um erro ao tentar processar os cortes",
            type: "warning",
          });
        }
      }
    }
  }

  const deleteMarking = async () => {
    try {
      closeDeleteModal();
      dispatch(showLoading());
      await markingService.delete(itemMarking.id);
      await LogOperation.postLogOperation("HearingIndex", 3, itemMarking.id);
      await getIndexList();
      dispatch(hideLoading());
      addToast({
        title: "Deletar marcação",
        description: "Sua marcação foi deletada com sucesso",
        type: "success",
      });
    } catch (error) {
      dispatch(hideLoading());
      setCurrentIndex(-1);
      addToast({
        title: "Deletar marcação",
        description: "Ocorreu um error ao deletar sua marcação",
        type: "warning",
      });
    }
  };

  const getTranscription = async () => {
    try {
      dispatch(showLoading());
      const { data } = await api.get(
        `HearingTranscription/GetAllByHearingId?hearingId=${hearingId}`
      );

      setTranscription(data.data);
      //0 - na fila, 2 - extraindo audio 4 - transcrevendo audio, 6 - transcrição concluida, 14 - Saldo insuficiente,
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      addToast({
        type: "warning",
        title: "Recuperar transcrição",
        description: "Não foi possível recuperar a transcrição",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoading());
        await getIndexList();
        await getProcedureInformation();
        await getTranscription();

        dispatch(hideLoading());
      } catch (error) {
        alert("erro ao carregar indexList");
      }
    })();
  }, []);

  async function onClickRefreshStatusCuts() {
    await getIndexList();
  }

  return (
    <Container>
      <HeaderHearing
        procedureItem={procedureItem}
        hearingItemDto={hearingItemDto}
      />
      <Body>
        <VideoPlayer
          marking={marking}
          videoUrl={hearingVideo}
          setMarking={setMarking}
          modalIsOpen={openModalFile}
          openMarkingModal={isOpen}
          isBegining={isBegining}
          videoRef={videoRef}
        />
        <HearingDetailsSidebar
          videoRef={videoRef}
          manualTranscriptionRef={manualTranscriptionRef}
          procedureItem={procedureItem}
          onEditMarking={onEditMarking}
          onDeleteMarking={onDeleteMarking}
          dataMarking={markingList}
          setOpenModal={setIsOpen}
          marking={marking}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          transcription={transcription}
          getTranscription={getTranscription}
          openModalFile={openModalFile}
          setOpenModalFile={setOpenModalFile}
          isBeginin={isBegining}
          processCuts={processarCortes}
          onClickRefreshStatusCuts={onClickRefreshStatusCuts}
        />
      </Body>
      <ModalRight
        onClickAplicar={isEdit ? handleUpdateMarking : createNewMarking}
        setIsOpen={closeModal}
        title={isEdit ? "Editar marcação" : "Criar marcação"}
        isOpen={isOpen}
        showIconDelete={false}
        isEdit={isEdit}
        buttonLabel={isBegining ? "Marcar" : "Finalizar"}
      >
        <MarkingChildrenModal
          isEdit={isEdit}
          setAttendantId={setAttendantId}
          subject={subject}
          setSubject={setSubject}
          toEditItem={itemMarking}
          marking={marking}
          error={markingError}
          isBegining={isBegining}
        />
      </ModalRight>
      <ModalConfirmation
        onClose={closeDeleteModal}
        title="Excluir marcação"
        titleBody="Deseja excluir a marcação?"
        subtitleBody="Esta ação não poderá ser desfeita"
        labelButton="Excluir"
        onClickButton={deleteMarking}
        isOpen={showDeleteModal}
      />
    </Container>
  );
};
