import React from "react";

import {
  Container,
  TitleContainer,
  Title,
  Description,
  DateContainer,
  DateTitle,
  Scheduled,
  ContainerSchedule,
  TextContainer,
} from "./styles";

export type IProcedureItemProps = {
  selected?: boolean;
  title: string;
  description: string;
  date: string;
  isAgended: boolean;
  onClick?: () => unknown;
  statusProcedure: string;
};

export const ProcedureItem: React.FC<IProcedureItemProps> = ({
  selected = false,
  title,
  description,
  isAgended,
  date,
  onClick,
  statusProcedure,
}) => {
  type statusType = {
    status: string;
    type: "inactive" | "warning" | "success" | "error";
  };

  function status(code: string): statusType {
    if (code === "A") {
      return {
        status: "Agendado",
        type: "inactive",
      };
    }
    if (code === "G") {
      return {
        status: "Em andamento",
        type: "warning",
      };
    }
    if (code === "Q") {
      return {
        status: "Publicando",
        type: "success",
      };
    }
    if (code === "P") {
      return {
        status: "Publicada",
        type: "success",
      };
    }
    if (code === "I") {
      return {
        status: "Inconsistente",
        type: "error",
      };
    }
    if (code === "C") {
      return {
        status: "Cancelada",
        type: "error",
      };
    }
    if (code === "D") {
      return {
        status: "Downloading",
        type: "warning",
      };
    }
    if (code === "U") {
      return {
        status: "Uploading",
        type: "warning",
      };
    }
    if (code === "S") {
      return {
        status: "Processando",
        type: "warning",
      };
    }
    if (code === "E") {
      return {
        status: "Encerrada",
        type: "success",
      };
    }
    return {
      status: "Invalido",
      type: "error",
    };
  }

  return (
    <Container onClick={onClick} selected={selected}>
      <TextContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleContainer>
      </TextContainer>
      {isAgended && (
        <DateContainer>
          <DateTitle>{date}</DateTitle>
          <ContainerSchedule status={status(statusProcedure).type || "success"}>
            <Scheduled status={status(statusProcedure).type}>
              {status(statusProcedure).status}
            </Scheduled>
          </ContainerSchedule>
        </DateContainer>
      )}
    </Container>
  );
};
