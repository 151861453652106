import { useEffect, useState } from "react";

import { AxiosError } from "axios";

import { CheckBoxGlobal } from "../../../../components/CheckBoxGlobal";
import { DropdownUnit } from "../../../../components/DropdownUnit";
import { InputDateUserUnit } from "../../../../components/InputDateUserUnit";
import { InputSwitch } from "../../../../components/InputSwitch";
import { RadiusGlobal } from "../../../../components/RadiusGlobal";
import { PoliceUnitService } from "../../../../services/policeUnit";
import { profilesService } from "../../../../services/profiles";
import { returnResponse } from "../../../../services/utils";
import {
  ListProfileContainer,
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
  Drawer,
  Title,
} from "../../StyleCSS/bodyCreateEdit";

interface UserCreateEdit {
  isEdit: boolean;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  setUnitId: (value: any) => void;
  setProfileId: (id: number) => unknown;
  setExpiredDate: (date: any) => unknown;
  setHasExpireIn: (active: boolean) => unknown;
  setTypeItem: (type: number) => unknown;
  typeItem: any;
  currentSelectedType?: any;
  unitNameError?: boolean;
  dateError: boolean;
  customerId?: any;
  profileId?: any;
  expiredDate: any;
  dataUnitItem?: any;
  userId?: any;
}

export const EditUnit = (props: UserCreateEdit): JSX.Element => {
  const [newData, setNewData] = useState([]);
  const [listProfiles, setListProfiles] = useState<any[]>([]);
  const [checked, setChecked] = useState(
    props.dataUnitItem ? !props.dataUnitItem.expireIn : true
  );
  const [newDataUnit, setNewDataUnit] = useState<any>();
  const [selectedType, setSelectedType] = useState<number>(
    props.currentSelectedType ?? 2
  );

  async function getDataUnit() {
    let dataUnitEdit = await JSON.parse(localStorage.getItem("editUnit")!);
    setNewDataUnit(dataUnitEdit);

    const { data } = await PoliceUnitService.GetAllByCustomerIdUserId(
      props.customerId,
      props.dataUnitItem && props.dataUnitItem.userId
        ? props.dataUnitItem.userId
        : props.userId
    );
    setNewData(data);
  }
  const getProfiles = async () => {
    try {
      const { body, status } = await profilesService.getAllByCustomerId2(
        selectedType
      );

      if (status) {
        setListProfiles(body);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  };

  useEffect(() => {
    if (newDataUnit) {
      props.setUnitId(newDataUnit?.unitId);
      props.setProfileId(newDataUnit?.userCustomerProfileId);
    }
  }, [newDataUnit]);

  useEffect(() => {
    getProfiles();
    getDataUnit();

    if (props.isEdit && props.dataUnitItem) {
      props.setExpiredDate(props.dataUnitItem.expireIn);
    } else {
      props.setExpiredDate(null);
    }
  }, [props.isEdit]);

  useEffect(() => {
    getProfiles();
  }, [selectedType]);

  useEffect(() => {
    props.setHasExpireIn(!checked);
    if (checked) {
      props.setExpiredDate("");
    } else {
      props.setExpiredDate(
        props.dataUnitItem && props.dataUnitItem.expireIn
          ? props.dataUnitItem.expireIn
          : null
      );
    }
  }, [checked]);

  return (
    <Container>
      <ContainerBody>
        <ContainerSwitch>
          <InputSwitch
            text="Ativo"
            checked={props.active}
            onChange={() => props.onChangeSwitch(!props.active)}
          />
        </ContainerSwitch>
        <DropdownUnit
          labelName="Nome"
          options={newData}
          setSelected={(item: any) => {
            setSelectedType(item.type);
            props.setTypeItem(item.id);
            if (props.isEdit) {
              props.dataUnitItem.current = item?.id;
            }
          }}
          defaultValue={newDataUnit?.policeUnit}
          required
          editDropdown={"Selecione"}
          error={props.unitNameError}
        />
        <ContainerInputs>
          <InputDateUserUnit
            label="Acesso válido até"
            required
            onChange={(date: any) => props.setExpiredDate(date)}
            value={checked ? "" : props.expiredDate}
            disabled={checked}
            error={props.dateError}
          />
          <CheckBoxGlobal
            id={1}
            label="indeterminado"
            checked={checked}
            onChecked={() => setChecked(!checked)}
            style={{ marginLeft: 24 }}
          />
        </ContainerInputs>
        <Drawer />
        <ContainerInputs>
          <Title>Perfil</Title>
        </ContainerInputs>
        <ListProfileContainer>
          {listProfiles?.map((item: any, index: any) => {
            return (
              <RadiusGlobal
                item={item}
                type={item?.type}
                key={index}
                label={item?.name}
                onSelectProfileId={props.setProfileId}
                selectProfileId={props.profileId}
                activeId={props.profileId ?? newDataUnit?.userCustomerProfileId}
              />
            );
          })}
        </ListProfileContainer>
      </ContainerBody>
    </Container>
  );
};
