import React from "react";

import { Tooltip } from "react-tooltip";

import {
  Container,
  ContainerDateText,
  DateArea,
  Day,
  Hour,
  TextArea,
  Title,
  Description,
  IconArea,
} from "./styles";
import iconEditCircle from "../../assets/procedure/edit_circle.svg";
import iconRecCircle from "../../assets/procedure/rec_circle.svg";
import IconDelete from "../../assets/svg/IconDelete.svg";

import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from "react-redux";

import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

type IScheduleItemSidebar = {
  hearing: any;
  day: string;
  hour: string;
  title: string;
  description: string;
  onClick?: () => void;
  onClickGravar: (item: any) => void;
  onClickEditar: (item: any) => void;
  onClickDelete: (item: any) => void;
};

export const ScheduleItemSidebar: React.FC<IScheduleItemSidebar> = ({
  hearing,
  day,
  onClickDelete,
  onClickGravar,
  onClickEditar,
  hour,
  title,
  description,
  onClick,
}) => {
  const { isAdmin } = useSelector((state: any) => state.profile);
  const user = getValueFromlocalStorage("@Kenta:user");
  const partsedUser = user ? JSON.parse(user) : null;

  return (
    <Container className="scheduled_hearing" onClick={onClick}>
      <ContainerDateText>
        <DateArea>
          <Day>{day}</Day>
          <Hour>{hour}</Hour>
        </DateArea>
        <TextArea>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextArea>
      </ContainerDateText>
      {(partsedUser && hearing.isClassified
        ? partsedUser.id === hearing.userId || isAdmin
        : true) && (
        <IconArea className="icons">
          <div>
            <button id="delete">
              <img
                onClick={(item: any) => onClickDelete(item)}
                src={IconDelete}
                alt="Excluir"
              />
            </button>
            <img
              onClick={(item: any) => onClickEditar(item)}
              src={iconEditCircle}
              alt="Editar"
              id="editar"
            />
            <img
              onClick={(item: any) => onClickGravar(item)}
              src={iconRecCircle}
              alt="Gravar"
              id="gravar"
            />
          </div>
        </IconArea>
      )}
      <Tooltip anchorSelect="#delete" place="top" content="Excluir" />
      <Tooltip anchorSelect="#editar" place="top">
        Editar
      </Tooltip>
      <Tooltip anchorSelect="#gravar" place="top" content="Gravar" />
    </Container>
  );
};
