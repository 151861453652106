import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import SkeletonDashboardNormal from "./skeletonNormal";
import { Container, ContainerLIneOne } from "./styles";
import ArrowRight from "../../../assets/svg/arrow-right.svg";
import BoxFolder from "../../../assets/svg/BoxFolder.svg";
import ErrorCircle from "../../../assets/svg/ErrorCircle.svg";
import Player from "../../../assets/svg/Player.svg";
import { GenericMetric } from "../../../components/Metrics/GenericMetric";
import { ProcedureMetrics } from "../../../components/Metrics/ProcedureMetrics";
import { DashboardService } from "../../../services/dashboard";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import {
  HeaderContainer,
  Title,
  TitleContainer,
} from "../../HeaderHearing/styles";

interface ChartProperties {
  chart: string;
  description: string;
  total: number;
}

interface C1 {
  total: number;
  description: string;
  labelButton: string;
  svg: any;
}

export function DashboardNormal() {
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const authToken = getValueFromlocalStorage("@Kenta:access_Token");
  const [charts, setCharts] = useState<Array<ChartProperties>>([]);
  const [c1List, setC1List] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [styleLineOne, setStyleLineOne] = useState({});
  const [isEndScroll, setIsEndScroll] = useState(false);
  const [opacityArrow, setOpacityArrow] = useState("0");
  const dispatch = useDispatch();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScrollToEnd = () => {
    const element = document.getElementById("scrollableDiv");
    if (element) {
      if (!isEndScroll) element.scrollLeft = element.scrollWidth;
      else element.scrollLeft = 0;

      setIsEndScroll(!isEndScroll);
    }
  };

  const handleScroll = (event: any) => {
    const element = event.target;
    const scrollAtBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    setScrollPosition(scrollAtBottom ? 1 : 0);
  };

  async function searchForAllUsers() {
    setLoading(true);
    dispatch(showLoading());
    if (authToken && policeUnit) {
      const response = await DashboardService.getAllForDashboard(
        authToken,
        policeUnit.customerId,
        policeUnit.unitId
      );

      if (response.data) setCharts(response.data);
    }
    setLoading(false);
    dispatch(hideLoading());
  }

  /**
   * get all c1 charts - situação dos usuarios
   */
  function getAllC1(): Array<any> {
    return charts.map((item) => {
      let newItem = null;

      if (item.description === "Procedimentos cancelados") {
        newItem = {
          labelButton: "Visualizar",
          color: "#EB58613D",
          svg: ErrorCircle,
          title: item.description,
          value: item.total,
        };
        return newItem;
      }
      if (item.description === "Procedimentos vencidos") {
        newItem = {
          labelButton: "Visualizar",
          color: "#ecf0f3",
          svg: Player,
          title: item.description,
          value: item.total,
        };
        return newItem;
      }
      if (item.description === "Procedimentos ativos") {
        if (item.total == 0) {
          setStyleLineOne({ width: "98%" });
        }
      }
      const randomString = Math.random() < 0.5 ? BoxFolder : Player;
      newItem = {
        labelButton: "Publicar agora",
        color: "rgba(240, 202, 104, 0.24)",
        svg: randomString,
        title: item.description,
        value: item.total,
      };
      return newItem;
    });
  }

  useEffect(() => {
    searchForAllUsers();
  }, []);

  useEffect(() => {
    if (charts.length > 0) {
      setC1List(getAllC1());
    }
  }, [charts]);

  return (
    <Container>
      <HeaderContainer
        style={{
          width: "100%",
          marginLeft: -3,
          position: "sticky",
          top: 0,
          // , zIndex: 800
        }}
      >
        <TitleContainer>
          <Title>Bem vindo</Title>
        </TitleContainer>
      </HeaderContainer>

      <ContainerLIneOne>
        {c1List.length > 0 && !loading ? (
          c1List.map((item) => (
            <GenericMetric
              key={item.title}
              color={item.color}
              labelButton={item.labelButton}
              onClick={() => {}}
              svg={item.svg}
              title={item.title}
              value={item.value}
            />
          ))
        ) : (
          <SkeletonDashboardNormal />
        )}
      </ContainerLIneOne>
    </Container>
  );
}
