import {
  DropdownGlobal,
  IDropDown,
} from "../../../../components/DropdownGlobal";
import { FloatContainer, FloatChildDropdown, HeigthChild } from "../style";

interface IShortCuts {
  playStop: string;
  setPlayStop: (e: string) => unknown;
  actionNextTime: string;
  setActionNextTime: (e: string) => unknown;
  actionBackTime: string;
  setActionBackTime: (e: string) => unknown;
  timeBackNext: string;
  setTimeBackNext: (e: string) => unknown;
  slowSpeed: string;
  setSlowSpeed: (e: string) => unknown;
  fastSpeed: string;
  setFastSpeed: (e: string) => unknown;
  fieldError: boolean;
  //
  listSecondsToNextBack: Array<IDropDown>;
  listFirstShortcut: Array<IDropDown>;
  listSecondShortcut: Array<IDropDown>;
  //
  valueTimeBackNextName: string;
  valuePlayStopFirstName: string;
  valuePlayStopSecondName: string;
  valueActionBackTimeFirstName: string;
  valueActionBackTimeSecondName: string;
  valueActionNextTimeFirstName: string;
  valueActionNextTimeSecondName: string;
  valueSlowSpeedFirstName: string;
  valueSlowSpeedSecondName: string;
  valueFastSpeedFirstName: string;
  valueFastSpeedSecondName: string;

  setTimeBackNextId: (e: number) => unknown;
  setPlayStopFirstId: (e: number) => unknown;
  setPlayStopSecondId: (e: number) => unknown;
  setActionBackTimeFirstId: (e: number) => unknown;
  setActionBackTimeSecondId: (e: number) => unknown;
  setActionNextTimeFirstId: (e: number) => unknown;
  setActionNextTimeSecondId: (e: number) => unknown;
  setSlowSpeedFirstId: (e: number) => unknown;
  setSlowSpeedSecondId: (e: number) => unknown;
  setFastSpeedFirstId: (e: number) => unknown;
  setFastSpeedSecondId: (e: number) => unknown;
}

export function ShortCuts(props: IShortCuts) {
  return (
    <FloatContainer>
      <DropdownGlobal
        labelName={"Tempo de avanço ou retrocesso vídeo"}
        options={props.listSecondsToNextBack}
        error={props.fieldError}
        setSelected={props.setTimeBackNextId}
        editDropdown={props.valueTimeBackNextName}
        defaultValue={props.valueTimeBackNextName}
        //width="439px"
      />
      {/*  */}
      <HeigthChild>
        <FloatChildDropdown>
          <DropdownGlobal
            labelName={"Iniciar/Parar"}
            options={props.listFirstShortcut}
            error={props.fieldError}
            setSelected={(e: any) => props.setPlayStopFirstId(e)}
            editDropdown={props.valuePlayStopFirstName}
            defaultValue={props.valuePlayStopFirstName}
            width="170px"
          />
        </FloatChildDropdown>
        <FloatChildDropdown
          style={{
            height: "80px",
            width: "5px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            paddingTop: "8px",
          }}
        >
          +
        </FloatChildDropdown>
        <FloatChildDropdown>
          <div style={{ marginTop: "21px" }}>
            <DropdownGlobal
              labelName={""}
              options={props.listSecondShortcut}
              error={props.fieldError}
              setSelected={(e: any) => props.setPlayStopSecondId(e)}
              editDropdown={props.valuePlayStopSecondName}
              defaultValue={props.valuePlayStopSecondName}
              width="170px"
            />
          </div>
        </FloatChildDropdown>
      </HeigthChild>
      {/* handleActionBackTime */}
      <HeigthChild>
        <FloatChildDropdown>
          <DropdownGlobal
            labelName={"Atalho voltar tempo vídeo"}
            options={props.listFirstShortcut}
            error={props.fieldError}
            setSelected={props.setActionBackTimeFirstId}
            editDropdown={props.valueActionBackTimeFirstName}
            defaultValue={props.valueActionBackTimeFirstName}
            width="170px"
          />
        </FloatChildDropdown>
        <FloatChildDropdown
          style={{
            height: "80px",
            width: "5px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            paddingTop: "8px",
          }}
        >
          +
        </FloatChildDropdown>
        <FloatChildDropdown>
          <div style={{ marginTop: "21px" }}>
            <DropdownGlobal
              labelName={""}
              options={props.listSecondShortcut}
              error={props.fieldError}
              setSelected={props.setActionBackTimeSecondId}
              editDropdown={props.valueActionBackTimeSecondName}
              defaultValue={props.valueActionBackTimeSecondName}
              width="170px"
            />
          </div>
        </FloatChildDropdown>
      </HeigthChild>
      {/* TimeNextTime */}
      <HeigthChild>
        <FloatChildDropdown>
          <DropdownGlobal
            labelName={"Atalho avançar tempo vídeo"}
            options={props.listFirstShortcut}
            error={props.fieldError}
            setSelected={props.setActionNextTimeFirstId}
            editDropdown={props.valueActionNextTimeFirstName}
            defaultValue={props.valueActionNextTimeFirstName}
            width="170px"
          />
        </FloatChildDropdown>
        <FloatChildDropdown
          style={{
            height: "80px",
            width: "5px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            paddingTop: "8px",
          }}
        >
          +
        </FloatChildDropdown>
        <FloatChildDropdown>
          <div style={{ marginTop: "21px" }}>
            <DropdownGlobal
              labelName={""}
              options={props.listSecondShortcut}
              error={props.fieldError}
              setSelected={props.setActionNextTimeSecondId}
              editDropdown={props.valueActionNextTimeSecondName}
              defaultValue={props.valueActionNextTimeSecondName}
              width="170px"
            />
          </div>
        </FloatChildDropdown>
      </HeigthChild>
      {/* SlowSpeed */}
      <HeigthChild>
        <FloatChildDropdown>
          <DropdownGlobal
            labelName={"Diminuir velocidade vídeo"}
            options={props.listFirstShortcut}
            error={props.fieldError}
            setSelected={props.setSlowSpeedFirstId}
            editDropdown={props.valueSlowSpeedFirstName}
            defaultValue={props.valueSlowSpeedFirstName}
            width="170px"
          />
        </FloatChildDropdown>
        <FloatChildDropdown
          style={{
            height: "80px",
            width: "5px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            paddingTop: "8px",
          }}
        >
          +
        </FloatChildDropdown>
        <FloatChildDropdown>
          <div style={{ marginTop: "21px" }}>
            <DropdownGlobal
              labelName={""}
              options={props.listSecondShortcut}
              error={props.fieldError}
              setSelected={props.setSlowSpeedSecondId}
              editDropdown={props.valueSlowSpeedSecondName}
              defaultValue={props.valueSlowSpeedSecondName}
              width="170px"
            />
          </div>
        </FloatChildDropdown>
      </HeigthChild>
      {/* FastSpeed */}
      <HeigthChild>
        <FloatChildDropdown>
          <DropdownGlobal
            labelName={"Aumentar velocidade vídeo"}
            options={props.listFirstShortcut}
            error={props.fieldError}
            setSelected={props.setFastSpeedFirstId}
            editDropdown={props.valueFastSpeedFirstName}
            defaultValue={props.valueFastSpeedFirstName}
            width="170px"
          />
        </FloatChildDropdown>
        <FloatChildDropdown
          style={{
            height: "80px",
            width: "5px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            paddingTop: "8px",
          }}
        >
          +
        </FloatChildDropdown>
        <FloatChildDropdown>
          <div style={{ marginTop: "21px" }}>
            <DropdownGlobal
              labelName={""}
              options={props.listSecondShortcut}
              error={props.fieldError}
              setSelected={props.setFastSpeedSecondId}
              editDropdown={props.valueFastSpeedSecondName}
              defaultValue={props.valueFastSpeedSecondName}
              width="170px"
            />
          </div>
        </FloatChildDropdown>
      </HeigthChild>
    </FloatContainer>
  );
}
