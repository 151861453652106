import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { Body, Container } from "./styles";
import { MenuAdminDinamic } from "../../../features/MenuAdminDinamic";
import { returnPermissionsByFuncionality } from "../../../hooks/permission";
import { LogErros } from "../LogErros";
import { LogOperations } from "../LogOperations";
import { ProceduresByCity } from "../proceduresByCity";
import { ProceduresByPoliceStation } from "../proceduresByPoliceStation";
import { ProceduresPerYer } from "../proceduresPerYer";

export const Reports: React.FC = () => {
  const { permissions } = useSelector((state: any) => state.permissions);
  const [menus, setMenus] = useState<Array<any>>([]);

  function setMenuStorage() {
    let menuStorage = localStorage.getItem("KentaMenu");
    if (menuStorage) {
      setMenus(JSON.parse(menuStorage));
    }
  }

  useEffect(() => {
    setMenuStorage();
  }, [permissions]);

  const componentsList = [
    {
      name: "ProceduresPerYear",
      component: <ProceduresPerYer />,
    },
    {
      name: "ProceduresByPoliceStation",
      component: <ProceduresByPoliceStation />,
    },
    {
      name: "ProceduresPerCity",
      component: <ProceduresByCity />,
    },
    {
      name: "LogOperation",
      component: <LogOperations />,
    },
    {
      name: "LogError",
      component: <LogErros />,
    },
  ];

  function createRoutesDynamic() {
    return (
      <Body>
        <Routes>
          {menus.map((element) =>
            returnPermissionsByFuncionality(element.name, permissions) ? (
              <>
                <Route
                  path={`/${element.name}`}
                  element={
                    componentsList.find((n) => n.name === element.name)
                      ?.component
                  }
                />
              </>
            ) : null
          )}
        </Routes>
      </Body>
    );
  }

  return (
    <Container>
      <MenuAdminDinamic
        title="Relatórios"
        type="report"
        optionsShow={["Procedimentos", "Logs"]}
      />
      {createRoutesDynamic()}
    </Container>
  );
};
