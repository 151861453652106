import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useWebsocket from "react-use-websocket";

import ContainerGlobalDownload from "../features/ContainerGlobalUpload";
import { HeaderUpMenu } from "../features/HeaderUpMenu";
import SelectAudioCamera from "../features/SelectAudioCamera";
import { useToast } from "../hooks/toast";
import { customerPreferencesService } from "../services/customerPreferences";
import { lifeSignalService } from "../services/lifesignal";
import { PermissionsService } from "../services/permissions";
import { UserCustomerProfileService } from "../services/user_customer_profile";
import { updateGlobalConfigurationsAction } from "../store/modules/globalConfiguration/actions";
import { hideLoading, showLoading } from "../store/modules/login/actions";
import { setPermissions } from "../store/modules/permission/actions";
import { setIsAdmin } from "../store/modules/profile/actions";
import { changeUserPreferences } from "../store/modules/user/actions";
import { LogOperation } from "../utils/entities/logOperation";
import {
  FORMS_ADMIN,
  FORMS_RELATORIOS,
  Permission,
} from "../utils/entities/permission";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../utils/sessionStorageEncrypt";

type Props = {
  children: JSX.Element;
  role?: string;
};

export function ProtectedRoute({ children, role }: Props) {
  const user = getValueFromlocalStorage("@Kenta:user");
  const userLocal = getValueFromlocalStorage("@Kenta:user");
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openPreferencesDrawer, setOpenPreferencesDrawer] =
    useState<boolean>(false);
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const { permissions } = useSelector((state: any) => state.permissions);
  const [qtdNotification, setQtdNotification] = useState(0);
  const [lifeSignal, setLifeSignal] = useState(true);
  const navigate = useNavigate();
  const WS_URL_NOTIFICATION = `${process.env.REACT_APP_WSS_BASE_URL}/notifications`;
  const WS_URL_LIFE_SIGNAL = `${process.env.REACT_APP_WSS_BASE_URL}/activeAccess`;
  const TIMEOUT_SIGNAL_LIFE = 30 * 1000;
  const TIMEOUT_NOTIFICATION = 20 * 1000;

  useEffect(() => {
    const checkPreferences = async () => {
      let storedPreferences = getValueFromlocalStorage(
        "@Kenta:user_preferences"
      );
      const userId = LogOperation.getUserId();
      if (userId) {
        const { body } = await customerPreferencesService.GetByUserId(userId);
        if (body) {
          dispatch(changeUserPreferences(body));
          insertTolocalStorage("@Kenta:user_preferences", JSON.stringify(body));
        }

        if (!body || !body.id) {
          setOpenPreferencesDrawer(true);
        }

        if (storedPreferences) {
          const parsedPreferences = JSON.parse(storedPreferences);
          dispatch(changeUserPreferences(parsedPreferences));

          if (!parsedPreferences.id && !body) {
            setOpenPreferencesDrawer(true);
          }
          return;
        }
      }
    };

    checkPreferences();
  }, []);

  const { sendMessage: sendMessageNotification } = useWebsocket(
    WS_URL_NOTIFICATION,
    {
      onMessage: (event: MessageEvent<any>) => {
        if (event.data !== "-1") setQtdNotification(Number(event.data));
      },
      onOpen: () => {}, // console.log("WEBSOCKET OPENED - NOTIFICATION"),
      onClose: () => {}, //console.log("WEBSOCKET CLOSED - NOTIFICATION"),
      shouldReconnect: () => true,
      reconnectInterval: 1000,
    }
  );

  useWebsocket(WS_URL_LIFE_SIGNAL, {
    onMessage: (event: MessageEvent<any>) => {
      if (event.data !== "-1") setLifeSignal(event.data);
    },
    onOpen: () => {}, //console.log("WEBSOCKET OPENED - ACTIVE_ACCESS"),
    onClose: () => {}, //console.log("WEBSOCKET CLOSED - ACTIVE_ACCESS"),
    shouldReconnect: () => true,
    reconnectInterval: 1000,
  });

  function getMenuStorage() {
    let globalConfigurations = localStorage.getItem("globalConfiguration");

    if (globalConfigurations) {
      const globalConfigurationsParsed = JSON.parse(globalConfigurations);

      dispatch(updateGlobalConfigurationsAction(globalConfigurationsParsed));
    }
  }

  const updatePermissions = async () => {
    dispatch(showLoading());
    const authToken = getValueFromlocalStorage("@Kenta:access_Token");
    if (authToken && policeUnit) {
      const { data } = await PermissionsService.getPermissions(
        authToken,
        policeUnit.customerId,
        policeUnit.profileId
      );
      if (data && data.length > 0) {
        dispatch(setPermissions(data));

        if (user || userLocal) {
          const parsedUser = user
            ? JSON.parse(user)
            : userLocal
            ? JSON.parse(userLocal)
            : "";
          const { success, body } =
            await UserCustomerProfileService.getPermissionToSeeIfHasPermissionClassified(
              parsedUser.id,
              policeUnit.profileId,
              policeUnit.unitId
            );

          if (success) {
            dispatch(setIsAdmin(body as boolean));
          }
        }
      }
    }
    setLoading(false);
    dispatch(hideLoading());
  };

  useEffect(() => {
    getMenuStorage();
    if (LogOperation.getUserId()) {
      sendMessageNotification(String(LogOperation.getUserId()));
    }
  }, []);

  useEffect(() => {
    if (!lifeSignal) {
      addToast({
        type: "warning",
        title: "Acesso finalizado",
        description: "Sua sessão foi encerrada, por favor conecte-se novamente",
      });
      localStorage.clear();
    }
  }, [lifeSignal]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        if (user || userLocal) {
          const userPassed = user
            ? JSON.parse(user)
            : userLocal
            ? JSON.parse(userLocal)
            : "";
          const { data, success } = await lifeSignalService.put(
            userPassed.hash
          );
          if (success) {
            setLifeSignal(data);
          } else {
            setLifeSignal(false);
          }
        }
      })();
    }, TIMEOUT_SIGNAL_LIFE);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalIdNotification = setInterval(() => {
      (async () => {
        if (user || userLocal) {
          const userPassed = user
            ? JSON.parse(user)
            : userLocal
            ? JSON.parse(userLocal)
            : "";
          sendMessageNotification(userPassed.id);
        }
      })();
    }, TIMEOUT_NOTIFICATION);

    return () => clearInterval(intervalIdNotification);
  }, []);

  function verifyAdministrationRole() {
    let haveAnyAdminPermission = false;
    let permissionsTyped = permissions as Permission[];

    for (let form of Object.values(FORMS_ADMIN)) {
      if (
        permissionsTyped.length > 0 &&
        permissionsTyped.filter((item) => item.form === form).length > 0
      )
        haveAnyAdminPermission = true;
      else continue;
    }
    return haveAnyAdminPermission;
  }

  function verifyRelatorioRole() {
    let haveAnyRelatorioPermission = false;
    let permissionsTyped = permissions as Permission[];

    for (let form of Object.values(FORMS_RELATORIOS)) {
      if (
        permissionsTyped.length > 0 &&
        permissionsTyped.filter((item) => item.form === form).length > 0 &&
        permissionsTyped.filter((item) => item.form === form)[0]
          .listObjectUserProfileActionDTOs.length > 0
      )
        haveAnyRelatorioPermission = true;
      else continue;
    }

    return haveAnyRelatorioPermission;
  }

  useEffect(() => {
    if (
      !permissions ||
      role === "" ||
      (permissions.length > 0 &&
        role === "admin" &&
        !verifyAdministrationRole())
    ) {
      navigate("/dashboard/procedure");
    }
  }, [permissions]);

  useEffect(() => {
    updatePermissions();
  }, [policeUnit]);

  // user is not authenticated
  if ((children && !user) || !userLocal) {
    return <Navigate to="/" />;
  }

  if (role && role === "relatorio" && !verifyRelatorioRole()) {
    navigate("/dashboard/procedure");
  }

  return (
    <>
      <HeaderUpMenu
        sendMessageNotification={sendMessageNotification}
        qtdNotification={qtdNotification}
      />
      {!loading && <>{children}</>}
      <ContainerGlobalDownload />
      <SelectAudioCamera
        setOpen={setOpenPreferencesDrawer}
        open={openPreferencesDrawer}
      />

      {/* <Footer />       */}
    </>
  );
}
