import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 400;
  font-size: 12px;
  color: ${stylesGlobalDefault.gray};
`;

export const Description = styled.div`
  margin-top: 8px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 600;
  font-size: 12px;
  color: ${stylesGlobalDefault.black};
`;
