import { stylesGlobalDefault } from "@kentarepo/kcf-assets";
import { shade } from "polished";
import styled, { css } from "styled-components";

interface IButton {
  width?: number;
}

export const Container = styled.div`
  width: 30%;
  min-width: 360px;
  height: 81.8vh;
  background: #fff;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
`;

export const HeaderContainer = styled.nav`
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
    padding: 0 20px;
    width: 100% -20px;
    height: 64px;
    background: white;
    text-decoration: none;
    border-bottom: 1px solid #cbcbcb;
  }
`;

export const ItemListMenu = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
    `}

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #e98d0b;
    margin-top: 5px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`;

export const Content = styled.div`
  width: 100%;
  height: 70vh;
`;

export const ContainerTemplate = styled.div`
  width: 100%;
`;

//****************** */

export const TabContainer = styled.div`
  width: 30%;
  min-width: 360px;
  height: 60px;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const TabContainerTextAndLine = styled.div`
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  margin-bottom: 5px;

  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
      height: 56px;
    `}

  &:hover {
    ${(props: { selected?: boolean }) =>
      props.selected === true
        ? css`
            border-bottom: 4px solid #e98d0b;
          `
        : css`
            border-bottom: 1px solid #e98d0b;
          `}
  }
`;

export const TabText = styled.div`
  text-align: center;
  /* 14 Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
`;

//#region Footer

export const ContainerButton = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 30px;
  background: #d1dbe3;
  padding: 24px 20px;
  justify-content: flex-end;
  margin-top: auto;

  @media (max-width: 1366px) {
    height: 36px;
  }
`;

export const SubContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  > button:first-child {
    margin-right: 16px;
  }
`;

export const NewMarkingButton = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? `${props.width}px` : "149px")};
  height: 34px;
  background: ${(props) => (props.disabled ? "#d1dbe3" : "#1d9bf0")};
  border-radius: 50px;
  border: ${(props) =>
    props.disabled ? "2px solid rgba(120, 135, 169, 0.5)" : "none"};
  padding: 8px 16px;
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  strong {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: ${(props) => (props.disabled ? "rgba(120, 135, 169, 0.5)" : "#fff")};
    font-size: 11px;
  }

  &:hover {
    background: ${(props) =>
      props.disabled ? "#d1dbe3" : shade(0.2, stylesGlobalDefault.blueDark)};
    transition: 0.2s;
  }

  &:disabled {
    pointer-events: none;
    &:hover {
      background: #d1dbe3;
    }
  }
`;

export const SpaceButton = styled.div`
  width: calc(24% - 54px);
`;
