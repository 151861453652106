import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const CustomerService = {
  getCustomerById: async (customerId: number) => {
    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados do dashboard sem o customerId",
      };
    }

    try {
      const { data } = await api.get(`Customer/Get`, {
        params: {
          id: customerId,
        },
      });

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
