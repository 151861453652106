import styled from "styled-components";

export const ContainerItems = styled.div`
  width: 100%;
  height: 94%;
  padding: 20px 20px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
`;
