import styled from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  width: 700px;
  background: ${stylesGlobalDefault.white};
  padding: 24px;
  height: 90vh;
  min-width: 300px;
  max-width: 300px;
  overflow-y: auto;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.12);
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${stylesGlobalDefault.white};
  display: flex;
  flex-direction: row;
  align-items: center;

  .closeButton {
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${stylesGlobalDefault.black};
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 80%;
  background-color: ${stylesGlobalDefault.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const styleDelete = {
  border: 1,
  borderStyle: "solid",
  borderColor: stylesGlobalDefault.white,
  borderRadius: "20px",
  marginRight: 14,
  cursor: "pointer",
};

export const Button = styled.button`
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s;
  margin-right: 14px;
  background-color: ${stylesGlobalDefault.blue};
  color: ${stylesGlobalDefault.white};
`;
