import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 998;
`;

export const Container = styled.div`
  position: absolute;
  width: 145px;
  background: ${stylesGlobalDefault.white};
  border: 2px solid ${stylesGlobalDefault.gray2};
  right: -15px;
  bottom: -65px;
  overflow: hidden;
  z-index: 999;
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 7px;
  background: ${stylesGlobalDefault.white};
  border: 0;
  cursor: pointer;

  span {
    font-size: 12px;
    font-family: ${stylesGlobalDefault.fontFamily};
    font-weight: 700;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background: ${stylesGlobalDefault.blue};
    cursor: pointer;
  }
`;
