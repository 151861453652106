export const SET_MIC_DEVICES = "@MEDIA_DEVICES/SET_MIC_DEVICES";
export const SET_VIDEO_DEVICES = "@MEDIA_DEVICES/SET_VIDEO_DEVICES";
export const SET_AUDIO_DEVICES = "@MEDIA_DEVICES/SET_AUDIO_DEVICES";

export const SELECT_MIC_DEVICE = "@MEDIA_DEVICES/SELECT_MIC_DEVICE";
export const SELECT_VIDEO_DEVICE = "@MEDIA_DEVICES/SELECT_VIDEO_DEVICE";
export const SELECT_AUDIO_DEVICE = "@MEDIA_DEVICES/SELECT_AUDIO_DEVICE";

export const SET_PERMISSIONS = "@MEDIA_DEVICES/SET_PERMISSIONS";

export const SET_MIC_LEVEL = "@MEDIA_DEVICES/SET_MIC_LEVEL";
export const SET_LOADING = "@MEDIA_DEVICES/SET_LOADING";
