import { createContext, useCallback, useContext, useState } from "react";

import { v4 } from "uuid";

import { ToastList } from "features/ToastList";

export interface ToastMessage {
  id: string;
  type?: "success" | "error" | "warning";
  title: string;
  description?: string;
  timeLimitToast?: number;
}

interface ToastContextData {
  addToast(messages: Omit<ToastMessage, "id">): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider = ({ children }: any) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({
      type,
      title,
      description,
      timeLimitToast,
    }: Omit<ToastMessage, "id">) => {
      const id = v4();

      const toast = {
        id,
        type,
        title,
        description,
        timeLimitToast,
      };

      setMessages((state) => [...state, toast]);
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastList messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}

export { ToastProvider, useToast };
