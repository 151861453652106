import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 360px;
  min-width: 360px;
  height: 95vh;
  background-color: ${stylesGlobalDefault.backgroundListItemColor};

  @media (max-width: 1360px) {
    height: 120vh;
  }
`;

export const ContainerMenu = styled.div`
  padding-left: 24px;
  padding-top: 28px;
  padding-right: 24px;
`;

export const DateContainer = styled.div`
  /* Frame 185 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-top: 25px;
  gap: 4px;

  width: 312px;
  /* //height: 304px; */

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: 0px;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px;
  width: 295px;
  height: 40px;
  background: ${(props: IProps) => (props.selected ? "#1D9BF0" : "#ECF0F3")};
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: 1660px) {
    height: 25px;
  }

  border: 2px;
  cursor: pointer;

  &:hover {
    border: ${(props: IProps) => (props.selected ? null : "2px #d1dbe3")};
    background-color: ${(props: IProps) => (props.selected ? null : "#d1dbe3")};
    background: ${(props: IProps) => (props.selected ? null : "#d1dbe3")};
  }

  //text
  font-family: "Inter";
  font-style: normal;
  font-weight: ${(props: IProps) => (props.selected ? 600 : 400)};
  font-size: 14px;
  padding-left: 12px;
  color: ${(props: IProps) => (props.selected ? "#FFFFFF" : "#000000")};
`;

export const HeadeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${stylesGlobalDefault.blackMenu1};
  background-color: ${stylesGlobalDefault.blackMenu1};
  width: 100%;
  min-height: 80px;
`;

export const Title = styled.div`
  width: 200px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
`;
