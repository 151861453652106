import React, { useEffect } from "react";

import { MdOutbox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  Container,
  ContainerDateText,
  ImgDiv,
  TextArea,
  Title,
  Description,
  InfoArea,
  DateLabel,
  DateBlackDiv,
  DateBlackSpan,
} from "./styles";
import videoIcon from "../../../assets/svg/VideoIcon.svg";
import { useToast } from "../../../hooks/toast";
import { NotifyService } from "../../../services/notify";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { StatusBadge } from "../../Badges/Status";

type IScheduleItem = {
  id: number;
  name: string;
  item: any;
  typeAttendant: string;
  date: string;
  timeVideo: string;
  statusText: string;
  statusType: string;
  openModal: boolean;
  setOpenModal: (hearingItem: object) => any;
  onDeleteItem: (id: number) => unknown;
  itemDataHearingId: any | null;
  setItemData: (data: any) => any | null;
  onClick?: () => unknown;
};

export const HearingItem: React.FC<IScheduleItem> = ({
  id,
  name,
  item,
  setOpenModal,
  typeAttendant,
  date,
  timeVideo,
  statusText,
  statusType,
  itemDataHearingId,
}) => {
  const { addToast } = useToast();
  const { isAdmin } = useSelector((state: any) => state.profile);
  const user = getValueFromlocalStorage("@Kenta:user");
  const parsedUser = user ? JSON.parse(user) : null;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const hearingIdQueryParam = queryParams.get("hearingId");
  const procedureIdQueryParam = queryParams.get("procedureId");

  const validateIfShow = () => {
    // se usuário é o dono da oitiva

    if (!(item.status === "I" || item.status === "G") && isAdmin) {
      return false;
    }

    if (parsedUser && parsedUser.id !== item.userId) {
      return true;
    }

    if (
      (item.status === "I" || item.status === "G") &&
      parsedUser &&
      parsedUser.id !== item.userId
    ) {
      return true;
    }

    if ((item.status === "I" || item.status === "G") && !isAdmin) {
      return true;
    }
    // return parsedUser && parsedUser.id !== item.userId && !isAdmin;
    return false;
  };

  const removeQueryParam = (item: any, isTrusted: boolean) => {
    setOpenModal(item);

    if (hearingIdQueryParam != item.id && isTrusted == true) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("hearingId");

      const newSearch = searchParams.toString();
      const newPath = `${location.pathname}${newSearch ? "?" + newSearch : ""}`;

      navigate(newPath, { replace: true });
    }
  };

  useEffect(() => {
    if (itemDataHearingId) {
      if (
        itemDataHearingId == item.id &&
        hearingIdQueryParam &&
        hearingIdQueryParam == itemDataHearingId
      ) {
        document.getElementById(`hearing-item-${hearingIdQueryParam}`)?.click();
      }
    }
  }, []);

  function returnSelected(item: any) {
    if (hearingIdQueryParam) {
      return hearingIdQueryParam == item.id;
    }

    return itemDataHearingId == item.id;
  }

  function addQueryParamHearing() {
    navigate(
      `/dashboard/procedure?procedureId=${procedureIdQueryParam}&hearingId=${item.id}`,
      {
        replace: true,
      }
    );
  }

  return (
    <Container
      id={`hearing-item-${id}`}
      selected={returnSelected(item)}
      disabled={item.isClassified ? validateIfShow() : false}
      className="schedule"
      onClick={(event) => {
        addQueryParamHearing();
        return (parsedUser && item.isClassified ? validateIfShow() : false)
          ? addToast({
              type: "warning",
              title: "Aviso",
              description:
                "Esta é uma oitiva sigilosa e você não tem permissão para acessa-la",
            })
          : removeQueryParam(item, !event.isTrusted);
      }}
    >
      <ContainerDateText>
        <ImgDiv>
          <img src={videoIcon} alt="" />
        </ImgDiv>
        <TextArea>
          <Title>{name}</Title>
          <Description>{typeAttendant}</Description>
        </TextArea>
      </ContainerDateText>
      <InfoArea className="icons">
        <DateLabel>{date}</DateLabel>
        {timeVideo && (
          <DateBlackDiv>
            <DateBlackSpan>{timeVideo}</DateBlackSpan>
          </DateBlackDiv>
        )}
        <StatusBadge text={statusText} type={statusType} />
        <Tooltip anchorSelect="#notify" place="top" content="Notificar" />
      </InfoArea>
    </Container>
  );
};
