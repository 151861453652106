import {
  SET_MIC_DEVICES,
  SET_VIDEO_DEVICES,
  SET_AUDIO_DEVICES,
  SELECT_MIC_DEVICE,
  SELECT_VIDEO_DEVICE,
  SELECT_AUDIO_DEVICE,
  SET_MIC_LEVEL,
  SET_PERMISSIONS,
  SET_LOADING,
} from "store/modules/mediaDevices/actions/actions.types";

import { DeviceOption } from "utils/formatDevices";

export const setMicDevices = (payload: DeviceOption[]) => ({
  type: SET_MIC_DEVICES,
  payload,
});

export const setVideoDevices = (payload: DeviceOption[]) => ({
  type: SET_VIDEO_DEVICES,
  payload,
});

export const setAudioDevices = (payload: DeviceOption[]) => ({
  type: SET_AUDIO_DEVICES,
  payload,
});

export const selectMicDevice = (payload: DeviceOption) => ({
  type: SELECT_MIC_DEVICE,
  payload,
});

export const selectVideoDevice = (payload: DeviceOption) => ({
  type: SELECT_VIDEO_DEVICE,
  payload,
});

export const selectAudioDevice = (payload: DeviceOption) => ({
  type: SELECT_AUDIO_DEVICE,
  payload,
});

export const setMicLevel = (payload: number) => ({
  type: SET_MIC_LEVEL,
  payload,
});

export const setPermissions = (payload: {
  mic: boolean;
  camera: boolean;
  audio: boolean;
  stream: boolean;
}) => ({
  type: SET_PERMISSIONS,
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: SET_LOADING,
  payload,
});
