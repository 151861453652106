import { AxiosError } from "axios";

import api from "../api";
import { PermissionResponse } from "../user_customer_profile/types";
import { returnResponse } from "../utils";

export const PermissionsService = {
  getPermissions: async (
    authToken: string | null,
    customerId: number,
    userCustomerProfile: number
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    try {
      const { data } = await api.get<PermissionResponse>(
        `SystemObjectUserCustomerProfile/GetAllByObjectsUserCustomerProfile`,
        {
          params: {
            customerId,
            UserCustomerProfileId: userCustomerProfile,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
