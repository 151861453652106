import { useEffect } from "react";

import { useSelector } from "react-redux";

import { checkPermissionsThunk } from "store/modules/mediaDevices/actions/thunks";
import { RootState, useAppDispatch } from "store/rootReducer";

export function useMediaPermissions() {
  const dispatch = useAppDispatch();
  const permissions = useSelector(
    (state: RootState) => state.mediaDevices.permissions
  );

  useEffect(() => {
    dispatch(checkPermissionsThunk());
  }, [dispatch]);

  return permissions;
}
