import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  height: 75vh;
  padding: 30px;
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
