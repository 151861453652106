import React from "react";

import { Container, ItemsContainer } from "./styles";
import { ProcedureDataSidebar } from "../../../components/ProcedureDataSidebar";
import AttachmentComponent from "../../AttachmentComponent";

type IHearingInformation = {
  procedureItem: any;
  openModalFile: boolean;
  setOpenModalFile: (e: boolean) => unknown;
  pattern: string;
};

const HearingInformation: React.FC<IHearingInformation> = ({
  procedureItem,
  openModalFile,
  setOpenModalFile,
  pattern,
}) => {
  return (
    <Container>
      <ItemsContainer>
        {procedureItem ? (
          <ProcedureDataSidebar pattern={pattern} itemData={procedureItem} />
        ) : (
          "carregando..."
        )}
      </ItemsContainer>
      <ItemsContainer>
        <AttachmentComponent
          setOpenModal={setOpenModalFile}
          openModal={openModalFile}
        />
      </ItemsContainer>
    </Container>
  );
};

export default HearingInformation;
