import { useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";

import {
  KeyWordsTitle,
  BottomContainer,
  ContainerCheckboxList,
  ContainerTextEditor,
} from "./styles";
import { editorInit } from "./tiny";
import { CheckBoxGlobal } from "../../../../components/CheckBoxGlobal";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { InputGeneral } from "../../../../components/Input";
import { InputSwitch } from "../../../../components/InputSwitch";
import { InputAuth } from "../../../../components/Login/Input";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "../../StyleCSS/bodyCreateEdit";

interface IFieldError {
  type: boolean;
  title: boolean;
  description: boolean;
  template: boolean;
}
interface ICreateEditCustomerTemplate {
  isEdit: boolean;
  id?: number;
  type: number | undefined;
  title: string;
  description: string;
  template: string;
  keywords: Array<string>;
  actived: boolean;
  setTitle: (title: string) => unknown;
  setDescription: (title: string) => unknown;
  setTemplate: (title: string) => unknown;
  setType: (title: number) => unknown;
  setActived: (actived: boolean) => unknown;
  setKeywords: (prop: Array<string>) => unknown;
  setFieldError: (prop: IFieldError) => unknown;
  fieldError: IFieldError;
}

interface ICheckboxList {
  id: number;
  label: string;
  value: string;
}

export const CreateEditCustomerTemplate = ({
  fieldError,
  setFieldError,
  title,
  description,
  template,
  type,
  actived,
  setTitle,
  keywords,
  setKeywords,
  setType,
  setTemplate,
  setDescription,
  setActived,
}: ICreateEditCustomerTemplate): JSX.Element => {
  const refTiny = useRef<any>(null);
  const documentTypeList = [
    { id: 1, name: "Ata" },
    { id: 2, name: "Transcrição" },
  ];

  const checkboxList: Array<ICheckboxList> = [
    { id: 1, label: "Origem do procedimento", value: "ProcedureSourceName" },
    { id: 2, label: "Tipo do procedimento", value: "ProcedureTypeName" },
    { id: 3, label: "Número do procedimento", value: "ProcedureNumber" },
    { id: 4, label: "Unidade de polícia", value: "PoliceUnitName" },
    { id: 5, label: "Data de abertura", value: "ExpectedDate" },
    { id: 6, label: "Duração", value: "Duration" },
    { id: 7, label: "Responsável", value: "AuthorityName" },
    { id: 8, label: "Prazo final", value: "ProcedureFinishDate" },
    { id: 9, label: "Participante", value: "AttendantName" },
    { id: 10, label: "Tipo de oitiva", value: "HearingTypeName" },
    { id: 11, label: "Transcrição", value: "Transcription" },
  ];

  const onSelectKeyword = (keyword: string) => {
    const isSelected = keywords.includes(keyword);
    const placeholder = `&lt;&lt;${keyword}&gt;&gt;`;
    const wrappedKeyword = `${placeholder}&nbsp`;

    if (isSelected) {
      setTemplate(template.replaceAll(placeholder, ""));
      setKeywords(keywords.filter((k) => k !== keyword));
      return;
    }

    const cleanTemplate = template.trim();
    const hasParagraph =
      cleanTemplate.startsWith("<p>") && cleanTemplate.endsWith("</p>");

    const newContent = hasParagraph
      ? cleanTemplate.replace("</p>", `${wrappedKeyword}</p>`)
      : `${cleanTemplate}${wrappedKeyword}`;

    setTemplate(newContent);
    setKeywords([...keywords, keyword]);
  };

  const onChangeTemplate = (e: string) => {
    const newKeywords: string[] = [];

    for (const checkbox of checkboxList) {
      if (e.includes(`&lt;&lt;${checkbox.value}&gt;&gt;`)) {
        newKeywords.push(checkbox.value);
      }
    }

    setKeywords(Array.from(new Set(newKeywords)));
    setTemplate(e);
  };

  const newCheckList =
    type === 1
      ? checkboxList.filter((x: ICheckboxList) => x.id !== 11)
      : checkboxList;

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <InputSwitch
              text="Ativo"
              checked={actived}
              onChange={() => setActived(!actived)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Tipo de documento"
              defaultValue={
                type ? documentTypeList.find((t) => t.id === type)?.name : ""
              }
              setSelected={(e: any) => {
                setFieldError({
                  template: fieldError.template,
                  type: false,
                  description: fieldError.description,
                  title: fieldError.title,
                });
                setType(e);
              }}
              required
              showDefaultValue={!!type}
              marginRight="10px"
              height="24px"
              width={"195px"}
              options={documentTypeList}
              error={fieldError.type}
            />
            <InputAuth
              error={fieldError.title}
              value={title}
              onChange={(e) => {
                setTitle(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: false,
                });
              }}
              marginBottom="20px"
              type="text"
              label="Título"
              required={true}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={fieldError.description}
              value={description}
              onChange={(e) => {
                setDescription(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: false,
                  title: fieldError.title,
                });
              }}
              type="text"
              label="Descrição"
              startFocus={false}
              required
            />
          </ContainerInputs>
          <ContainerTextEditor error={fieldError.template}>
            <Editor
              onKeyDown={(event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                  event.preventDefault();
                  alert("Não é possivel copiar esse texto");
                }
              }}
              ref={refTiny}
              inline={false}
              onEditorChange={(e) => {
                setFieldError({
                  template: false,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: fieldError.title,
                });

                onChangeTemplate(e);
              }}
              value={template}
              apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
              onInit={(evt, editor) => (refTiny.current = editor)}
              init={{ ...editorInit, newline_behavior: "linebreak" }}
              // initialValue={transcriptions[0].textPlain}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              //onPaste={(e) => e.preventDefault()}
            />
          </ContainerTextEditor>
          <BottomContainer>
            <KeyWordsTitle>Palavras chaves</KeyWordsTitle>
            <ContainerCheckboxList>
              {newCheckList.map((item: ICheckboxList) => (
                <CheckBoxGlobal
                  checked={keywords.includes(item.value)}
                  id={item.id}
                  label={item.label}
                  onChecked={() => onSelectKeyword(item.value)}
                />
              ))}
            </ContainerCheckboxList>
          </BottomContainer>
        </ContainerBody>
      </Container>
    </>
  );
};
