import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
`;

export const ContainerCharts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 98%;
  /* height: 85vh; */
  padding-right: 12px;
  padding-left: 12px;
  /* flex-wrap: wrap; */
  /* overflow: auto; */

  @media (max-width: 1920px) {
    /* height: 85vh; */
  }

  @media (max-width: 1700px) {
    overflow-x: hidden;
    /* height: 65vh; */
  }
`;

export const ContainerLineOne = styled.div`
  width: 100%;
  height: 54%;
  display: flex;
  flex-direction: row;

  @media (max-height: 1280px) {
    height: 50%;
  }
`;

export const ContainerLineTwo = styled.div`
  width: 100%;
  height: 44%;
  display: flex;
  flex-direction: row;
  @media (max-height: 1280px) {
    height: 48%;
  }
`;

export const ContainerLineOneTest = styled.div`
  width: 100%;
  height: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ContainerLineTwoTest = styled.div`
  width: 100%;
  height: 35%;
  padding-top: 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerLIneTwo = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 99%;
  height: 35%;
  padding-right: 8px;
  padding-left: 14px;
`;

export const ContainerLineTwoColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* padding-top: 20px; */
  width: 33%;
  padding-left: 4px;

  padding-right: 4px;
  height: 100%;
  margin: 0px 4px;
  margin-top: -2px;
`;
