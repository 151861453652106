import { useSelector } from "react-redux";

import { Permission } from "utils/entities/permission";

interface UserPermissions {
  create: boolean;
  edit: boolean;
  exclude: boolean;
  read: boolean;
  export?: boolean;
  proceduresByPoliceStation?: boolean;
  proceduresPerPoliceUnit?: boolean;
  proceduresPerYear?: boolean;
  logOperation?: boolean;
  logErrors?: boolean;
}

export function actionValid(action: string) {
  if (action === "ProceduresPerPoliceUnit") return "ProceduresByPoliceStation";

  return action;
}

export function returnPermissionsByFuncionality(
  form: string,
  permissions: Permission[]
): UserPermissions {
  if (permissions.length === 0)
    return {
      create: false,
      edit: false,
      exclude: false,
      read: false,
      export: false,
      proceduresByPoliceStation: false,
      proceduresPerPoliceUnit: false,
      proceduresPerYear: false,
      logOperation: false,
      logErrors: false,
    };

  let permissionsByForm = permissions.filter((item) => item.form === form);
  if (permissionsByForm.length === 0) {
    return {
      create: false,
      edit: false,
      exclude: false,
      read: false,
      export: false,
      proceduresByPoliceStation: false,
      proceduresPerPoliceUnit: false,
      proceduresPerYear: false,
      logOperation: false,
      logErrors: false,
    };
  }

  let permission = permissionsByForm[0].listObjectUserProfileActionDTOs.reduce(
    (a: any, b: any) => {
      a.push(b.action);
      return a;
    },
    []
  );

  return {
    create: permission.includes("Insert"),
    edit: permission.includes("Update"),
    exclude: permission.includes("Delete"),
    read: permission.includes("Read"),
    export: permission.includes("Export"),
    proceduresByPoliceStation: permission.includes("ProceduresByPoliceStation"),
    proceduresPerPoliceUnit: permission.includes("ProceduresPerPoliceUnit"),
    proceduresPerYear: permission.includes("ProceduresPerYear"),
    logOperation: permission.includes("LogOperation"),
    logErrors: permission.includes("LogError"),
  };
}

export function returnReportPermisison({ name, permissions }: any) {
  let reportPermission = {
    read: false,
  };

  permissions?.forEach((element: any) => {
    if (element.form === "Report") {
      element.listObjectUserProfileActionDTOs?.forEach((report: any) => {
        if (actionValid(report.action) === name)
          reportPermission = {
            read: actionValid(report.action) === name,
          };
      });
    }
  });

  return reportPermission;
}

export function usePermissions(form: string): UserPermissions {
  const { permissions } = useSelector((state: any) => state.permissions);

  const userPermissions = returnPermissionsByFuncionality(form, permissions);
  return userPermissions;
}
