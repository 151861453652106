import React from "react";

import { Container, Label, LabelContainer, Input } from "./styles";
import { CircleBadge } from "../../Badges/Circle";

type IInput = {
  style?: object;
  label: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange: (e: string) => unknown;
};

export const InputComponent: React.FC<IInput> = ({
  style,
  label,
  disabled = false,
  required = false,
  placeholder,
  defaultValue,
  onChange,
}) => {
  return (
    <Container style={style}>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <CircleBadge radius="50px" widthHeigt="8px" />}
      </LabelContainer>
      <Input
        defaultValue={defaultValue}
        placeholder={placeholder ? placeholder : label}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        type="text"
      />
    </Container>
  );
};
