import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const ContainerListEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 108px;
  background: ${stylesGlobalDefault.inputsBorderFocusOff};
  border-radius: 50%;
  margin-bottom: 16px;
`;

export const ImgCircle = styled.div`
  min-height: 45px;
  min-width: 55px;
  width: 45px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextCircle = styled.label`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${stylesGlobalDefault.black};
`;
