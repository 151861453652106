import { useEffect, useState } from "react";

import Avatar from "react-avatar";
import { BsFillCloudArrowDownFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  ContainerLeft,
  ContainerMiddle,
  ContainerProfile,
  ContainerUnidade,
  PictureContainer,
  styleButton,
  ContainerRight,
  ContainerNotification,
  ContainerQtdNotification,
} from "./styles";
import logo from "../../assets/Kenta 4.0_Seg_Pub.png";
import dashboardIcon from "../../assets/svg/dash-icon.svg";
import notificacaoOff from "../../assets/svg/notificacaoOff.svg";
import { CardDownloadFile } from "../../components/CardDownloadFile";
import { ContainerItems } from "../../features/DownloadFiles/styles";
import { SelecionarUnidade } from "../../features/SelecionarUnidade";
import { usePermissions } from "../../hooks/permission";
import { useToast } from "../../hooks/toast";
import { storageBaseUrl } from "../../services/api";
import { SystemNotificationService } from "../../services/systemNotification";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { LogOperation } from "../../utils/entities/logOperation";
import { FORMS } from "../../utils/entities/permission";
import { formatDDMMYYYY } from "../../utils/format";
import { Button } from "../Button";
import { ListEmpty } from "../ListEmpty";
import ModalRight from "../ModalRight";

//Toast

export type HeaderUpMenuProps = {
  item: string;
};

interface PropsHeader {
  activeItemMenu: string;
  onClickProcedure: () => void;
  haveAdminRole: boolean;
  firstAdminRoutePermitted: string;
  haveRelatorioRole: boolean;
  firstRelatorioRoutePermitted: string;
  onClickAdministration: () => void;
  onClickRelatorios: () => void;
  userCustomerProfiles: any;
  user: any;
  unit: any;
  onClickOpenSideModal: (isModalVisible: boolean) => void;
  setOpenDownload: (show: boolean) => unknown;
  showDashboard?: () => void;
  sendMessageNotification?: (userId: string) => void;
  qtdNotification: number;
}

export const HeaderUp = ({
  activeItemMenu,
  onClickProcedure,
  haveRelatorioRole,
  firstRelatorioRoutePermitted,
  haveAdminRole,
  firstAdminRoutePermitted,
  onClickAdministration,
  onClickRelatorios,
  userCustomerProfiles,
  user,
  unit,
  onClickOpenSideModal,
  setOpenDownload,
  showDashboard,
  qtdNotification,
  sendMessageNotification,
}: PropsHeader) => {
  const { read } = usePermissions(FORMS.PROCEDURE_CUSTOMER);
  const [labelProcedureOrDashboard, setLabelProcedureOrDashboard] =
    useState("");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [listnotifications, setListNotifications] = useState([]);
  const [logoConfiguration, setLogoConfiguration] = useState("");
  const { record } = useSelector((state: any) => state.screen);
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const { globalConfigurations } = useSelector(
    (state: any) => state.configurations
  );

  useEffect(() => {
    if (read) {
      setLabelProcedureOrDashboard("Procedimentos");
    } else {
      setLabelProcedureOrDashboard("Dashboard");
    }
  }, [read]);

  useEffect(() => {
    onLoadList();
  }, [openModal]);

  useEffect(() => {
    if (globalConfigurations && globalConfigurations.length) {
      globalConfigurations.forEach((element: any) => {
        if (element && element.name === "LogoCustomer") {
          setLogoConfiguration(element.value);
        }
      });
    }
  }, [globalConfigurations]);

  async function onLoadList() {
    let userId = parseInt(LogOperation.getUserId());
    const { data } = await SystemNotificationService.getAllByUser(userId);

    if (data.data.length > 0) {
      let filterReaded = data.data.filter((item: any) => item.read === false);
      setListNotifications(filterReaded);
    } else {
      setListNotifications([]);
    }
  }

  const readNotification = async (notificationId: number) => {
    dispatch(showLoading());
    let response = await SystemNotificationService.setReaded(notificationId);

    if (response.data) {
      await onLoadList();

      sendMessageNotification &&
        sendMessageNotification(LogOperation.getUserId());
    }
    dispatch(hideLoading());
  };

  const isRecordScreen = () => {
    addToast({
      type: "warning",
      title: "Aviso",
      description: "Esta ação está indisponível nesta tela",
    });
  };

  return (
    <>
      <Container>
        <ContainerLeft hasOtherLogo={!!logoConfiguration}>
          <img src={logoConfiguration || logo} alt="" />
        </ContainerLeft>
        <ContainerMiddle>
          <Button
            styles={styleButton}
            color={activeItemMenu !== "Procedimentos" ? "secondary" : "primary"}
            onClick={record ? isRecordScreen : onClickProcedure}
          >
            {labelProcedureOrDashboard}
          </Button>
          {haveAdminRole && firstAdminRoutePermitted !== "" ? (
            <Button
              color={
                activeItemMenu !== "Administracao" ? "secondary" : "primary"
              }
              onClick={record ? isRecordScreen : onClickAdministration}
              styles={{ marginLeft: "8px", marginRight: "8px" }}
            >
              Administração
            </Button>
          ) : null}
          {haveRelatorioRole && firstRelatorioRoutePermitted !== "" ? (
            <Button
              color={activeItemMenu !== "Relatorios" ? "secondary" : "primary"}
              onClick={record ? isRecordScreen : onClickRelatorios}
              styles={{ marginRight: "8px" }}
            >
              Relatórios
            </Button>
          ) : null}
        </ContainerMiddle>
        <ContainerUnidade>
          {userCustomerProfiles && userCustomerProfiles.length > 0 ? (
            <SelecionarUnidade
              record={record}
              unidades={userCustomerProfiles}
            />
          ) : null}
        </ContainerUnidade>
        <ContainerRight>
          <img
            src={dashboardIcon}
            alt=""
            width={24}
            height={24}
            title={"Dashboard"}
            onClick={
              record
                ? isRecordScreen
                : () => (showDashboard ? showDashboard() : () => {})
            }
          />
          <ContainerNotification onClick={() => setOpenModal(true)}>
            <img src={notificacaoOff} alt="" title="Notificações" />
            {qtdNotification ? (
              <ContainerQtdNotification
                className="notifications"
                title="Notificações"
              >
                <span>{qtdNotification > 9 ? "9+" : qtdNotification}</span>
              </ContainerQtdNotification>
            ) : null}
          </ContainerNotification>
          <BsFillCloudArrowDownFill
            onClick={() => setOpenDownload(true)}
            color="#7887A9"
            size={24}
            title="Aplicativos"
          />
        </ContainerRight>
        <ContainerProfile
          onClick={() => onClickOpenSideModal(true)}
          title="Minha conta"
        >
          {userCustomerProfiles && userCustomerProfiles.length > 0 ? (
            <>
              {user?.picture && unit?.containerName ? (
                <PictureContainer
                  className="abrir_avatar"
                  picture={`${storageBaseUrl}/${unit.containerName}/${
                    user.picture
                  }?v=${Date.now()}`}
                />
              ) : (
                <Avatar
                  className="abrir_avatar"
                  name={user ? user.name : "user test"}
                  size="48"
                  style={{ cursor: "pointer" }}
                  round={true}
                />
              )}
            </>
          ) : null}
        </ContainerProfile>
      </Container>
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
        title={"Notificações"}
        onClickAplicar={() => {}}
        haveFooter={false}
      >
        <>
          <ContainerItems>
            {qtdNotification && qtdNotification > 0 ? (
              listnotifications.map((item: any) => {
                return (
                  <CardDownloadFile
                    tipo={4}
                    key={String(item.id)}
                    description={item.message}
                    name={formatDDMMYYYY(item.date)}
                    onClick={() => readNotification(item.id)}
                    titleTooltip={"Marcar como lida"}
                    onLoadList={onLoadList}
                    showButton={true}
                    openModal={openModal}
                  />
                );
              })
            ) : (
              <div
                style={{
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <ListEmpty titleCustom="Nenhuma notificação" />
              </div>
            )}
          </ContainerItems>
        </>
      </ModalRight>
    </>
  );
};
