import {
  CONTAINER_PROFILE,
  SELECT_CUSTOMER_PROFILE,
  SET_IS_ADMIN,
  SET_MENU_LIST,
  SET_PROFILE_NAME,
} from "./actios.types";
import { IMenuList } from "../../../../routers/interface";

export const selectCustomerProfile = (userCustomerProfile: number) => {
  return {
    type: SELECT_CUSTOMER_PROFILE,
    userCustomerProfile,
  };
};

export const setIsAdmin = (isAdmin: boolean) => {
  return {
    type: SET_IS_ADMIN,
    isAdmin,
  };
};

export const setContainerName = (containerName: string) => {
  return {
    type: CONTAINER_PROFILE,
    containerName,
  };
};

export const setProfileNameAction = (profileName: string) => {
  return {
    type: SET_PROFILE_NAME,
    profileName,
  };
};

export const setMenuListAction = (menus: Array<IMenuList>) => {
  return {
    type: SET_MENU_LIST,
    menus,
  };
};
