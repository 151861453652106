import styled, { css } from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface Props {
  active?: boolean;
  disableInteractions?: boolean; // Make it optional
}

type ContainerProps = {
  disableInteractions?: boolean; // Make it optional
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${(props) =>
    props.disableInteractions == true &&
    css`
      cursor: not-allowed;
    `}
`;

export const Wrapper = styled.div<Props>`
  width: 20px;
  height: 20px;
  border: 1px solid
    ${({ active }) => (active ? stylesGlobalDefault.blue : "#C8C8C8")};
  border-radius: 4px;
  background: ${({ active }) => (active ? stylesGlobalDefault.blue : "")};
  display: flex;
  justify-content: center;
  ${(props) =>
    props.disableInteractions == true &&
    css`
      pointer-events: none;
    `}
`;

export const Title = styled.label`
  color: ${stylesGlobalDefault.black};
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
`;
