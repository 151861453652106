import { useEffect, useState } from "react";

import { IResultCheckBox } from "../../../../components/CheckBoxGlobal";
import { CheckBoxMultipleIds } from "../../../../components/CheckBoxMultipleIds";
import { useToast } from "../../../../hooks/toast";
import { procedureCustomerTypeAttendantRoleService } from "../../../../services/procedureCustomerTypeAttendantRole";
import { LogOperation } from "../../../../utils/entities/logOperation";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";
import { IDefaultObject, IHearingTypeAttendantRoleItem } from "../index";

//Toast

interface ICreateEditHearingTypeAttendantRole {
  isEdit: boolean;
  postOrPutTrue: boolean;
  setPostOrPutTrue: (e: boolean) => unknown;
  hearingTypeList: Array<IDefaultObject>;
  attendantRoleList: Array<IDefaultObject>;
  hearingTypeAttendantRoleItem?: IHearingTypeAttendantRoleItem;
  hearingTypeAttendantRoleListToCreate?: Array<IHearingTypeAttendantRoleItem>;
  closeModal: boolean;
  setCloseModal: (e: boolean) => unknown;
}

export const CreateEditProcedureCustomerAttendantRole = (
  props: ICreateEditHearingTypeAttendantRole
): JSX.Element => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [selectecHearingTypeId, setSelectecHearingTypeId] = useState<number>(0);
  const [arrayIds, setArrayIds] = useState<Array<number>>([]);

  const { addToast } = useToast();

  const [listToShow, setListToShow] = useState<Array<IResultCheckBox>>([]);
  const [newListChecks, setNewListChecks] = useState<Array<IResultCheckBox>>(
    []
  );

  const relationshipOriginalList =
    props.hearingTypeAttendantRoleItem?.attendantRoleList ?? [];
  let listIdsToDelete: Array<number> = [];
  let listIdsToAdd: Array<number> = [];

  useEffect(() => {
    setIsLoad(true);

    if (props.isEdit) {
      setListToShow(
        convertTypeList(
          props.hearingTypeAttendantRoleItem?.attendantRoleList ?? [],
          false
        )
      );
      if (props.hearingTypeAttendantRoleItem) {
        setSelectecHearingTypeId(
          props.hearingTypeAttendantRoleItem.hearingTypeId
        );
      }
    }
    setIsLoad(false);
  }, []);

  //OnCLick
  useEffect(() => {
    if (props.postOrPutTrue) {
      putUpdate();
      props.setPostOrPutTrue(false);
    }
  }, [props.postOrPutTrue]);

  useEffect(() => {
    if (!props.isEdit) {
      if (selectecHearingTypeId && selectecHearingTypeId > 0) {
        let listToShowFind = props.hearingTypeAttendantRoleListToCreate?.find(
          (x: IHearingTypeAttendantRoleItem) =>
            x.hearingTypeId === selectecHearingTypeId
        );
        let listToShowAdd =
          listToShowFind?.attendantRoleList ?? Array<IDefaultObject>();

        setListToShow(
          convertTypeList(listToShowAdd ?? Array<IDefaultObject>(), true)
        );
      }
    }
  }, [selectecHearingTypeId]);

  useEffect(() => {
    props.setCloseModal(true);
  }, [props.closeModal]);

  function validationFields() {
    if (listIdsToDelete.length === 0 && listIdsToAdd.length === 0) {
      addToast({
        type: "warning",
        title: "Vinculo",
        description:
          "Para atualizar vinculos é necessário marcar e/ou desmarcar pelo menos um tipo de participante.",
      });
      return false;
    }
    return true;
  }

  async function putUpdate() {
    try {
      choiseAction();
      if (!validationFields()) return;

      //ADD
      if (listIdsToAdd.length > 0) {
        for (let i = 0; i < listIdsToAdd.length; i++) {
          await procedureCustomerTypeAttendantRoleService.vincularProcedureTypeEAttendantRole(
            LogOperation.getCustomerId(),
            selectecHearingTypeId,
            listIdsToAdd[i]
          );
        }
      }

      //DELETE
      if (listIdsToDelete.length > 0) {
        for (let i = 0; i < listIdsToDelete.length; i++) {
          await procedureCustomerTypeAttendantRoleService.deletarVinculoProcedureTypeEAttendantRole(
            listIdsToDelete[i]
          );
        }
      }

      addToast({
        type: "success",
        title: "Vinculo",
        description: "Vinculo(s) atualizado(s) com sucesso",
      });

      setArrayIds([]);
      listIdsToDelete = [];
      listIdsToAdd = [];
      props.setCloseModal(false);
    } catch (error) {
      addToast({
        type: "warning",
        title: "Vinculo",
        description:
          "Para criar um vinculo é necessário escolher um tipo de procedimento.",
      });
      return false;
    }
  }

  function convertTypeList(
    arrayAttendant: Array<IDefaultObject>,
    disabled: boolean
  ) {
    let listChecks = Array<IResultCheckBox>();

    props.attendantRoleList.forEach(function (item) {
      let newObj: IResultCheckBox = {
        id: item.id,
        isChecked:
          arrayAttendant.length === 0
            ? false
            : containsIds(arrayAttendant, item.id),
        label: item.name,
        disabled:
          arrayAttendant.length === 0
            ? false
            : disabled &&
              disabled === true &&
              containsIds(arrayAttendant, item.id),
      };
      listChecks.push(newObj);
    });
    return listChecks;
  }

  function containsIds(list: Array<IDefaultObject>, idVerify: number) {
    let exist = list.find((x) => x.id === idVerify);
    if (exist) {
      return true;
    }
    return false;
  }

  function choiseAction() {
    newListChecks.forEach((element) => {
      if (element.isChecked) {
        let exist = containsIds(relationshipOriginalList, element.id);
        if (!exist) {
          listIdsToAdd.push(element.id);
        }
      } else {
        let exist = containsIds(relationshipOriginalList, element.id);
        if (exist) {
          let hearingTypeAttendantRoleID = relationshipOriginalList.find(
            (x) => x.id === element.id
          );
          if (
            hearingTypeAttendantRoleID &&
            hearingTypeAttendantRoleID.hearingTypeAttendantRoleId
          ) {
            listIdsToDelete.push(
              hearingTypeAttendantRoleID.hearingTypeAttendantRoleId
            );
          }
        }
      }
    });
  }

  return (
    <>
      {!isLoad ? (
        <Container>
          <ContainerBody>
            <ContainerInputs>
              <h2>{props.hearingTypeAttendantRoleItem?.hearingTypeName}</h2>
            </ContainerInputs>
            <CheckBoxMultipleIds
              title="Tipo de procedimento"
              listToShow={listToShow}
              getAllIds={setArrayIds}
              getNewList={setNewListChecks}
            />
          </ContainerBody>
        </Container>
      ) : null}
    </>
  );
};
