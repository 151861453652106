/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from "react";

import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Label,
  ListItem,
} from "./styles";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import Selected from "../../assets/svg/Selected.svg";
import { CircleBadge } from "../Badges/Circle";

export interface IDropDown {
  name: string;
  id: number;
  selected?: boolean;
}
export interface iDropdown {
  labelName: string;
  options: IDropDown[];
  defaultValue?: string;
  setSelected: any;
  editDropdown?: any;
  required?: boolean;
  error?: boolean;
  width?: string;
  height?: string;
  marginLeft?: string;
  wantAnObject?: boolean;
  disabled?: boolean;
  onCloseDropDown?: any;
}

export const DropdownReport: React.FC<iDropdown> = ({
  labelName,
  options,
  setSelected,
  editDropdown,
  defaultValue,
  required = false,
  error,
  width = "385px",
  height,
  wantAnObject,
  disabled,
  marginLeft,
  onCloseDropDown,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorDrop, setErrorDrop] = useState(false);
  const [errorDropRequired, setErrorDropRequired] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(
    editDropdown ? editDropdown : defaultValue || "Selecione"
  );
  const statusRef = useRef<any>(null);

  useEffect(() => {
    if (selectedOption && selectedOption !== "Selecione") {
      setErrorDropRequired(false);
      setErrorDrop(false);
    } else {
      error && setErrorDropRequired(error);
    }
  }, [error]);

  const toggling = () => {
    if (isOpen && selectedOption === "Selecione") {
      setErrorDrop(true);
    } else {
      setErrorDrop(false);
    }
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: any, option?: any) => () => {
    setSelectedOption(value);
    // setSelected(option.id);
    setIsOpen(false);

    if (wantAnObject && option) {
      setSelected(option.id);
      setErrorDrop(false);
      setErrorDropRequired(false);
      return;
    }

    if (option) {
      setSelected(option.id);
      setErrorDrop(false);
      setErrorDropRequired(false);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [onCloseDropDown]);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      if (statusRef.current && !statusRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [setIsOpen, statusRef]);

  return (
    <DropDownContainer width={width} id="dropDownContainer" ref={statusRef}>
      <Label>{labelName}</Label>
      {required && !disabled && <CircleBadge radius="50px" widthHeigt="8px" />}
      <DropDownHeader
        id="drop_header"
        disable={disabled || !options || options.length <= 0}
        error={errorDrop || errorDropRequired}
        focus={isOpen}
        height={height}
        onClick={() => {
          if (!disabled && options && options.length > 0) toggling();
        }}
        selected={selectedOption === "Selecione" ? false : true}
      >
        <span>{selectedOption || "---"}</span>
        <img
          className="icon"
          src={isOpen ? ArrowUp : ArrowDown}
          onClick={() => {
            if (options && options.length > 0) toggling();
          }}
          style={{ cursor: options?.length > 0 ? "pointer" : "not-allowed" }}
          alt=""
        />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList width={width}>
            {options.map((item: any, index: any) => {
              return (
                <ListItem
                  onClick={onOptionClicked(item.name || item, item)}
                  key={index}
                  selected={item.name === selectedOption}
                >
                  {item.name || item}
                  {item.name === selectedOption ? (
                    <img src={Selected} alt="" />
                  ) : null}
                </ListItem>
              );
            })}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
