import { userCreateProfileTypes } from "./actios.types";

export interface SetUserCreateProfile {
  type: userCreateProfileTypes.SET_USERCREATE_PROFILE;
  payload: userCreateProfileTypes;
}

export const setUserCreateProfile = (
  userCreateProfile: any
): SetUserCreateProfile => ({
  type: userCreateProfileTypes.SET_USERCREATE_PROFILE,
  payload: userCreateProfile,
});
