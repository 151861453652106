import { useEffect, useRef, useState } from "react";

import ReactLoading from "react-loading";
import { useNavigate, useLocation } from "react-router-dom";

import { useToast } from "hooks/toast";
import { useMediaPermissions } from "hooks/useMediaPermissions";

import { setScreenStatus } from "store/modules/screen_controll/actions";
import { useAppDispatch } from "store/rootReducer";

import {
  BodyContainer,
  ButtonFinish,
  Container,
  ContainerButtons,
  ContainerDescription,
  FooterContainer,
  HeaderContainer,
  Subtitle,
  Title,
} from "components/HearingRecord/style";
import { ModalConfirmation } from "components/ModalConfirmation";

import { HearingService } from "services/hearing";

import BackArrow from "assets/svg/bx-arrow-back.svg";
import RecordButtonDisabled from "assets/svg/record-button-disabled.svg";
import RecordButton from "assets/svg/record-button.svg";
import Share from "assets/svg/share.svg";
import V from "assets/svg/v.svg";

import { getValueFromlocalStorage } from "utils/sessionStorageEncrypt";

const HearingRecord = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const [loadingStartRecording, setLoadingStartRecording] = useState(true);
  const [loadingStopRecording, setLoadingStopRecording] = useState(false);
  const [exibirSaindoComponent, setExibirSaindoComponent] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [showModalConfirmationSair, setShowModalConfirmationSair] =
    useState(false);
  const [recordStarted, setRecordStarted] = useState(false);
  const timeoutsRef = useRef<any[]>([]);
  const dispatch = useAppDispatch();

  const permissions = useMediaPermissions();

  useEffect(() => {
    if (!location.state) {
      navigate("/dashboard/procedure");
    } else {
      if (
        location.state.backToHearing &&
        (localStorage.getItem("isRecordStarted") ||
          localStorage.getItem("isRecordStarted") === "true")
      ) {
        setRecordStarted(true);
        setLoadingStartRecording(false);
      } else {
        setRecordStarted(false);
        setLoadingStartRecording(false);
        localStorage.removeItem("isRecordStarted");
      }

      if (!location.state.backToHearing) {
        setTimeout(() => {
          setLoadingStartRecording(false);
        }, 5000);
        addToast({
          type: "warning",
          title: "Aviso",
          description:
            "Lembre-se de clicar em gravar quando todos os participantes estiverem na sala.",
        });
      }
    }

    dispatch(setScreenStatus(true));

    return () => {
      timeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  const goBack = () => {
    dispatch(setScreenStatus(false));
  };

  const copyFunction = async () => {
    let copyText: any = document.getElementById("myInput");

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      await navigator.clipboard.writeText(copyText.value);
      addToast({
        type: "success",
        title: "Link copiado",
        description: "O link está salvo no seu ctrl+v",
      });
    }
  };

  const startVideoRecord = async () => {
    if (!permissions.camera) {
      addToast({
        type: "error",
        title: "Permitir câmera",
        description:
          "É necessário que seja permitido o uso da câmera para prosseguir",
      });
      return;
    }

    if (location.state) {
      setLoadingStartRecording(true);
      localStorage.setItem("isRecordStarted", "true");
      const { status } = await HearingService.startVideoRecording(
        location.state?.videoRoomId,
        location.state.authToken,
        location.state.deviceToken
      );

      if (status) {
        let time = getValueFromlocalStorage("timeToCount", "encrypt-time");
        if (!time) {
          addToast({
            type: "success",
            title: "Gravação iniciada",
            description: "Gravação iniciada",
          });
        }

        setLoadingStartRecording(false);
        setRecordStarted(true);
        verificarEChamarlimiteDeTempo();
      } else {
        addToast({
          type: "error",
          title: "Erro inesperado",
          description: "Não foi possível iniciar a gravação",
        });
        setLoadingStartRecording(false);
        setRecordStarted(false);
      }
    }
  };

  const stopVideoRecord = async () => {
    if (location.state) {
      setLoadingStopRecording(true);
      const { status } = await HearingService.stopVideoRecording(
        location.state?.videoRoomId,
        location.state.authToken,
        location.state.deviceToken
      );

      if (status) {
        setExibirSaindoComponent(true);
        setLoadingStopRecording(false);

        await HearingService.logoutUna();
        navigate(
          `/dashboard/procedure?procedureId=${location.state?.procedureNumber}&hearingId=${location.state?.hearingId}`,
          {
            state: {
              procedureNumber: location.state?.procedureNumber,
            },
          }
        );
      } else {
        addToast({
          type: "error",
          title: "Não foi possível finalizar o vídeo",
          description: "O vídeo não foi finalizado como deveria.",
        });
        setExibirSaindoComponent(true);
        setLoadingStopRecording(false);
      }
    }
  };

  function verificarEChamarlimiteDeTempo() {
    let time = getValueFromlocalStorage("timeToCount", "encrypt-time");
    if (time) {
      addToast({
        type: "warning",
        title: "Versão de demonstração",
        description: `Tempo limite de gravação de ${time} minutos.`,
        timeLimitToast: 15000,
      });
      setLoadingStopRecording(false);

      let milissegundos = Number(time) * 60000;

      const timeoutFinish = setTimeout(() => {
        stopVideoRecord();
        setShowModalConfirmation(false);
      }, milissegundos);

      const timeout30SecondsLeft = setTimeout(() => {
        addToast({
          type: "warning",
          title: "Aviso",
          description: `Você só tem mais 30 segundos até finalizar`,
          timeLimitToast: 15000,
        });
      }, milissegundos - 30000);

      timeoutsRef.current = [timeoutFinish, timeout30SecondsLeft];
    }
  }

  async function onClickBackButton() {
    setShowModalConfirmationSair(true);
    setExibirSaindoComponent(true);

    navigate(
      `/dashboard/procedure?procedureId=${location.state?.procedureNumber}&hearingId=${location.state?.hearingId}`,
      {
        state: {
          procedureNumber: location.state?.procedureNumber,
        },
      }
    );
  }

  const handleIframeLoad = () => {
    let unaIframe: HTMLIFrameElement | null = document.getElementById(
      "unaIframe"
    ) as HTMLIFrameElement;

    const observer = new MutationObserver(() => {
      const iframeDoc = unaIframe?.contentWindow?.document;
      if (!iframeDoc) return;

      try {
        const existsCall = iframeDoc.getElementsByClassName(
          "video-call-container"
        )[0];
        const errorContent = iframeDoc.getElementsByClassName(
          "error-content__text"
        )[0];

        if (existsCall && !errorContent) {
          setDisabledButtons(false);
        }

        const footerBar = iframeDoc.getElementsByClassName(
          "video-call-footer-bar"
        )[0];

        if (footerBar) {
          const buttonsContainer = footerBar.getElementsByClassName(
            "video-call-footer-bar__buttons"
          )[0];

          if (buttonsContainer) {
            const buttons = buttonsContainer.getElementsByTagName("div");

            for (let i = 0; i < 3; i++) {
              if (buttons[i]) buttons[i].style.display = "none";
            }
          }
        }

        let leaveButton = iframeDoc.querySelector(
          'div[id^="ember923"]'
        ) as HTMLElement;

        if (leaveButton) leaveButton.style.display = "none";
      } catch (error) {
        console.error("Erro ao modificar iframe:", error);
      }
    });

    if (!unaIframe?.contentWindow) return;

    observer.observe(unaIframe.contentWindow.document.body, {
      childList: true,
      subtree: true,
    });
  };

  const getUnaIframeBaseURL = () =>
    process.env.NODE_ENV === "development" &&
    window.location.hostname.includes("trycloudflare")
      ? process.env.REACT_APP_CLOUDFLAREDUNABASEURL
      : process.env.REACT_APP_UNABASEURL;

  return (
    <Container>
      <HeaderContainer>
        <img
          src={BackArrow}
          alt=""
          style={{ cursor: "pointer", paddingLeft: 14 }}
          onClick={() => setShowModalConfirmationSair(true)}
        />
        <ContainerDescription>
          <Title style={{ paddingLeft: 20 }}>
            Procedimento {location.state?.procedureNumber}
          </Title>
          <Subtitle>{location.state?.procedureCustomerSourceName}</Subtitle>
        </ContainerDescription>
      </HeaderContainer>

      <BodyContainer>
        {exibirSaindoComponent ? (
          SaindoComponent()
        ) : (
          <iframe
            id="unaIframe"
            onLoad={handleIframeLoad}
            style={{ width: "100%", height: "100%" }}
            src={`${getUnaIframeBaseURL()}/una/onlyVideoRoom?roomId=${
              location.state?.urlModerator.split("=")[1]
            }&inviteUsers=false`}
            title="UNA"
          />
        )}
      </BodyContainer>

      <FooterContainer>
        <ContainerButtons>
          <input
            type="text"
            value={location.state?.urlAttendee}
            id="myInput"
            style={{ display: "none" }}
          />

          {loadingStartRecording ? (
            <ReactLoading type="spin" color="#F2A60D" width={24} height={24} />
          ) : (
            <img
              src={
                recordStarted || disabledButtons
                  ? RecordButtonDisabled
                  : RecordButton
              }
              alt=""
              onClick={() => {
                if (!recordStarted && !disabledButtons) startVideoRecord();
              }}
              style={{
                cursor:
                  recordStarted || disabledButtons ? "not-allowed" : "pointer",
              }}
            />
          )}

          <ButtonFinish color="primary" onClick={copyFunction}>
            <img
              src={Share}
              alt=""
              width={24}
              height={24}
              style={{ marginRight: 4 }}
            />
            Compartilhar link
          </ButtonFinish>

          {loadingStopRecording ? (
            <ReactLoading type="spin" color="#a50303" width={24} height={24} />
          ) : (
            <ButtonFinish
              id="finish-record"
              color="forth"
              disabled={
                !recordStarted ||
                !(localStorage.getItem("isRecordStarted") === "true")
              }
              onClick={() => {
                if (recordStarted) setShowModalConfirmation(true);
              }}
            >
              <img src={V} alt="" style={{ marginRight: 4 }} />
              Finalizar gravação
            </ButtonFinish>
          )}
        </ContainerButtons>
      </FooterContainer>

      <ModalConfirmation
        onClose={() => setShowModalConfirmation(false)}
        title="Finalizar gravação"
        titleBody="Deseja realmente finalizar a gravação?"
        subtitleBody="Esta ação não poderá ser desfeita"
        labelButton="Finalizar"
        onClickButton={() => {
          stopVideoRecord();
          setShowModalConfirmation(false);
          goBack();
        }}
        isOpen={showModalConfirmation}
      />

      <ModalConfirmation
        onClose={() => setShowModalConfirmationSair(false)}
        title="Sair da sala"
        titleBody="Deseja realmente sair da sala de gravação?"
        subtitleBody={
          !recordStarted
            ? "Para sair da gravação iniciada, clique em finalizar a gravação."
            : "Encerre a gravação antes de sair."
        }
        labelButton="Sair"
        onClickButton={() => {
          onClickBackButton();
          setShowModalConfirmation(false);
          goBack();
        }}
        showButtonSair={!recordStarted}
        isOpen={showModalConfirmationSair}
      />
    </Container>
  );
};

function SaindoComponent() {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title style={{ fontSize: 40, color: "black" }}>Saindo da sala...</Title>
    </Container>
  );
}

export default HearingRecord;
