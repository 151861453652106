import {
  ADD_NEW_ATTACHMENT,
  SET_ATTACHMENT_LIST,
  REMOVE_ATTACHMENT_FILE,
  ADD_ATTACHMENT_UPLOADED,
  CLEAN_ATTACHMENT_UPLOADS,
} from "./actios.types";
import { IUpload } from "../interface";

export const addNewAttachment = (attachment: IUpload) => {
  return {
    type: ADD_NEW_ATTACHMENT,
    attachment,
  };
};

export const setAttachmentList = (attachmentList: Array<any>) => {
  return {
    type: SET_ATTACHMENT_LIST,
    attachmentList: attachmentList,
  };
};

export const addUploadedFile = (attachment: IUpload) => {
  return {
    type: ADD_ATTACHMENT_UPLOADED,
    attachment: attachment,
  };
};

export const cleanUpload = () => {
  return {
    type: CLEAN_ATTACHMENT_UPLOADS,
  };
};

export const removeAttachmentFile = (id: string) => {
  return {
    type: REMOVE_ATTACHMENT_FILE,
    id,
  };
};
