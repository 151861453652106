import React, { useState } from "react";

import {
  Container,
  ContainerBody,
  ContainerInputsDate,
  ContainerWithButton,
} from "./style";
import { AddButton } from "../../components/AddButton";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { usePermissions } from "../../hooks/permission";
import { FORMS_ADMIN } from "../../utils/entities/permission";

type IErrorsFields = {
  procedureNumber: boolean;
  finalDate: boolean;
  date: boolean;
};

export type IProcedureType = {
  itemData?: any;
  setSelected: (item: any) => unknown;
  selected: any;
  attendantList: Array<any>;
  modalOpenNewParticipant: () => unknown;
};

export const ProcedureAttendant: React.FC<IProcedureType> = ({
  setSelected,
  modalOpenNewParticipant,
  attendantList,
}): JSX.Element => {
  const [closeDropDown, setCloseDropDown] = useState(false);

  const { create } = usePermissions(FORMS_ADMIN.ATTENDANT);
  return (
    <Container>
      <ContainerBody>
        <ContainerWithButton>
          <ContainerInputsDate hasPermission={create}>
            <DropdownGlobal
              setSelected={setSelected}
              options={attendantList as any}
              labelName="Participante"
              onCloseDropDown={closeDropDown}
              required={true}
            />
          </ContainerInputsDate>
          {create && (
            <AddButton
              onClick={() => {
                setCloseDropDown(!closeDropDown);
                modalOpenNewParticipant();
              }}
            />
          )}
        </ContainerWithButton>
      </ContainerBody>
    </Container>
  );
};
