import React from "react";

import { useToast } from "hooks/toast";

import { Container, ButtonContainer, Overlay } from "./styles";

interface IModalSelectOrTakePicture {
  takePicture: () => unknown;
  onClose: () => unknown;
  setFileImg: (file: any) => unknown;
  preview: string;
}

const ModalSelectOrTakePicture: React.FC<IModalSelectOrTakePicture> = ({
  takePicture,
  setFileImg,
  onClose,
  preview,
}) => {
  const { addToast } = useToast();

  return (
    <>
      <Overlay onClick={onClose} />
      <Container>
        <ButtonContainer onClick={takePicture}>
          <span>{preview ? "Remover foto" : "Tirar foto"}</span>
        </ButtonContainer>
        <ButtonContainer>
          <span>Enviar do computador</span>
          <input
            onChange={(e) => {
              onClose();
              if (e.target.files && e.target.files[0].size < 500000) {
                setFileImg(e.target.files[0]);
              } else {
                addToast({
                  type: "warning",
                  title: "Foto",
                  description: "Tamanho da foto deve ser inferior a 500 KB",
                });
              }
            }}
            type="file"
          />
        </ButtonContainer>
      </Container>
    </>
  );
};

export default ModalSelectOrTakePicture;
