import React from "react";

import { Container, Progress } from "./styles";

type IProps = {
  percent: number;
  changePercentage: (e: number) => unknown;
};

const PercentageBar: React.FC<IProps> = ({ percent, changePercentage }) => {
  return (
    <Container>
      <Progress
        type="range"
        min="0"
        max="100"
        value={percent.toFixed(0)}
        onChange={(e) => changePercentage(Number(e.target.value))}
      />
    </Container>
  );
};

export default PercentageBar;
