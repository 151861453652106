import styled from "styled-components";

interface IProps {
  widthHeight?: number;
}

export const Container = styled.div<IProps>`
  width: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  height: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  border-radius: 50%;
  cursor: pointer;
  background: none;
`;

export const PlusIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
