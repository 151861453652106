import { createAsyncThunk } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import attachmentReducer from "store/modules/attachment/reducer";
import globalConfigurationsReducer from "store/modules/globalConfiguration/reducer";
import loadingReducer from "store/modules/login/reducer";
import mediaDevicesReducer from "store/modules/mediaDevices/reducer";
import permissionReducer from "store/modules/permission/reducer";
import playerReducer from "store/modules/playercontrol/reducer";
import policeUnitReducer from "store/modules/policeUnit/reducer";
import procedureReducer from "store/modules/procedure/reducer";
import profileReducer from "store/modules/profile/reducer";
import userProfileCreateReducer from "store/modules/profileCreate/reducer";
import screenStatusReducer from "store/modules/screen_controll/reducer";
import userReducer from "store/modules/user/reducer";
import userCreateUnitReducer from "store/modules/userCreateUnit/reducer";

export interface ThunkExtraArg {
  addToast: (toast: {
    type?: "success" | "error" | "warning";
    title: string;
    description?: string;
    timeLimitToast?: number;
  }) => void;
}

const rootReducer = combineReducers({
  attachment: attachmentReducer,
  user: userReducer,
  profile: profileReducer,
  loading: loadingReducer,
  permissions: permissionReducer,
  policeUnit: policeUnitReducer,
  player: playerReducer,
  userCreateUnit: userCreateUnitReducer,
  userCreateProfile: userProfileCreateReducer,
  procedure: procedureReducer,
  screen: screenStatusReducer,
  configurations: globalConfigurationsReducer,
  mediaDevices: mediaDevicesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, ThunkExtraArg, AnyAction>;

export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  extra: ThunkExtraArg;
}>();

export default rootReducer;
