import React, { useState } from "react";

import { HeadeContainer, Title } from "./styles";
import { FilterProcedure } from "../../features/FilterProcedure";
import { AddButton } from "../AddButton";
import { DeleteButton } from "../DeleteButton";
import { FilterButton } from "../FilterButton";

export type IHeaderProcedure = {
  title?: string;
  AddButtonClick?: () => unknown;
  DeleteButtonClick?: () => unknown;
  searchProcedureByFilters?: any;
  isFilter: boolean;
};

export const HeaderProcedure: React.FC<IHeaderProcedure> = ({
  searchProcedureByFilters,
  title,
  AddButtonClick,
  DeleteButtonClick,
  isFilter,
}) => {
  const [isOpend, setIsOpend] = useState(false);
  return (
    <HeadeContainer>
      <Title>{title}</Title>
      <div style={{ width: "150px", paddingLeft: "10px", marginRight: "0" }}>
        {AddButtonClick && (
          <AddButton
            id="procedure_add_button"
            widthHeight={40}
            onClick={AddButtonClick}
          />
        )}
        <FilterButton
          id="procedure_filter_button"
          isFilter={isFilter}
          widthHeight={40}
          onClick={() => {
            setIsOpend(!isOpend);
          }}
        />
        {DeleteButtonClick ? (
          <DeleteButton
            id="procedure_delete_button"
            style={{ marginLeft: 4 }}
            widthHeight={40}
            onClick={DeleteButtonClick}
          />
        ) : (
          <DeleteButton
            id="procedure_delete_button"
            style={{ marginLeft: 4 }}
            disabled={true}
            widthHeight={40}
          />
        )}
        <FilterProcedure
          searchProcedureByFilters={searchProcedureByFilters}
          isOpend={isOpend}
          setIsOpend={setIsOpend}
        />
      </div>
    </HeadeContainer>
  );
};
