import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface ICircleButton {
  padding: number;
  size: number;
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% - 48px;
  height: 80px;
  min-height: 80px;
  padding: 0 24px;
  background: ${stylesGlobalDefault.blackMenu2};
`;

export const ProcedureHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-right: 2px solid #666;
  padding: 0 40px 0 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
`;

export const BackButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
`;

export const Title = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  min-width: 200px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  margin-bottom: 4px;
`;

export const SubTitle = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 15px;
  font-style: normal;
  font-size: 12px;
  color: #cbcbcb;
`;

export const AttendantHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AttendantContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CircleIcon = styled.button<ICircleButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: ${(props) => props.padding}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 2px solid #1d9bf0;
  background: none;
  cursor: pointer;
`;
