import React from "react";

import { Container, Value } from "./styles";

export type IQuantityComponent = {
  value?: string | number;
  id?: string;
};

export const QuantityComponent: React.FC<IQuantityComponent> = ({
  value,
  id,
}) => {
  return value ? (
    <Container id={id}>
      <Value>{value}</Value>
    </Container>
  ) : (
    <div />
  );
};
