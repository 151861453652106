import React from "react";

import Webcam from "react-webcam";

import { VideoConstraints } from "components/VideoCamera/constants";

import { Container } from "./styles";

const VideoCamera: React.FC<{ videoConstraints: VideoConstraints }> = ({
  videoConstraints,
}) => {
  return (
    <Container>
      <Webcam
        key={videoConstraints?.deviceId?.exact || "default"}
        audio={false}
        height={"200px"}
        mirrored
        screenshotQuality={1024}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
        style={{ borderRadius: "10px", objectFit: "cover" }}
      />
    </Container>
  );
};

export default VideoCamera;
