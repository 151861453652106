import styled, { css } from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

interface IProps {
  error: boolean;
  notError: boolean;
  height?: number;
  width?: number;
  focus: boolean;
}

interface IContainerProps {
  marginBottom?: string;
}

export const Container = styled.div<IContainerProps>`
  margin-bottom: ${(props) => props.marginBottom || "22px"};
`;

export const ContainerTitleWithBadge = styled.div`
  display: flex;
  align-items: center;
  height: 17px;
`;

export const Label = styled.span`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: #666;
`;

export const ContainerInput = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + "px" : "100% -20px")};
  height: 48px;
  border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
  border-radius: 4px;
  margin-top: 4.5px;

  ${(props) =>
    props.error &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusError};
    `}

  ${(props) =>
    props.notError &&
    css`
      border: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
    `}

    ${(props) =>
    props.focus &&
    css`
      border: 2px solid ${stylesGlobalDefault.blue};
    `}
`;

export const InputText = styled.input`
  width: 100%;
  padding: 5px 10px;
  height: 37px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ContainerIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding-right: 17px;
  padding-left: 17px;
`;

export const ErrorText = styled.span`
  color: ${stylesGlobalDefault.inputsBorderFocusError};
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  display: block;
`;
