import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { Container, ContainerChart, ContainerTitle, TitleOp } from "./styles";
import { Divider } from "../ChartContainer/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function Operations({ operacoes, ocorrencias }: any) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
    minBarLength: 1,
    layout: {
      padding:
        getScreenResolution().width < 1540
          ? {
              right: 30,
            }
          : {},
    },
  };
  function getScreenResolution() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    return { width, height };
  }
  const data = {
    labels: operacoes.map((item: any) => {
      return item.month;
    }),
    datasets: [
      {
        label: "Operações",
        data: operacoes.map((item: any) => {
          return item.total;
        }),
        fill: false,

        tension: 0.1,
        pointRadius: 5,
        pointBackgroundColor: "rgb(75, 192, 192)",
        pointBorderColor: "rgb(75, 192, 192)",
        pointHoverRadius: 10,
        pointHoverBackgroundColor: "rgb(75, 192, 192)",
        pointHoverBorderColor: "rgb(75, 192, 192)",
        barPercentage: 0.2,
        backgroundColor: ["rgba(75, 192, 192, 0.6)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
      },
      {
        label: "Ocorrências",
        data: ocorrencias.map((item: any) => {
          return item.total;
        }),
        fill: false,
        borderColor: "rgb(17, 187, 107)",
        tension: 0.1,
        pointRadius: 5,
        pointBackgroundColor: "rgb(17, 187, 107)",
        pointBorderColor: "rgb(17, 187, 107)",
        pointHoverRadius: 10,
        pointHoverBackgroundColor: "rgb(17, 187, 107)",
        pointHoverBorderColor: "rgb(17, 187, 107)",
        backgroundColor: ["rgb(19, 143, 0)"],
        barPercentage: 0.2,
      },
    ],
  };

  return (
    <Container>
      <ContainerTitle>
        <TitleOp>Desempenho operacional</TitleOp>
      </ContainerTitle>
      <Divider
        style={{
          marginTop: 10,
        }}
      />
      <ContainerChart>
        <Bar
          options={{ ...options }}
          data={data}
          style={{
            width: "100%",
            height: "100%",
            paddingBottom: 8,
          }}
          title=""
        />
      </ContainerChart>
    </Container>
  );
}
