import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Column, ContainerIcon, ContainerItem } from "./styles";
import Desktop from "../../../assets/svg/desktop.svg";
import Mobile from "../../../assets/svg/mobile.svg";
import Web from "../../../assets/svg/web.svg";
import { ChartContainer } from "../ChartContainer";
import { Divider } from "../ChartContainer/styles";

interface ChartProps {
  web: number;
  app: number;
  offline: number;
}

ChartJS.register(ArcElement, Tooltip, Legend);

export function AccessByPlatform({ web, app, offline }: ChartProps) {
  const data = {
    labels: ["web", "app", "offline"],
    datasets: [
      {
        data: [web, app, offline],
        backgroundColor: ["#4CAF50", "yellow", "#FF5252"],
      },
    ],
  };

  return (
    <ChartContainer title="Acessos por plataforma">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Divider
          style={{
            background: "#CCC",
          }}
        />
        <ContainerItem>
          <Column>
            <ContainerIcon
              style={{
                backgroundColor: "#8ee8a9",
              }}
            >
              <img src={Mobile} width={26} height={26} />
            </ContainerIcon>
            <span
              style={{
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              Mobile
            </span>
          </Column>
          <Column>
            <span
              style={{
                fontSize: 12,
              }}
            >
              {app}
            </span>
          </Column>
        </ContainerItem>
        <Divider />
        <ContainerItem>
          <Column>
            <ContainerIcon
              style={{
                background: "#8ec7e8",
              }}
            >
              <img src={Web} width={18} height={18} />
            </ContainerIcon>
            <span
              style={{
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              Web
            </span>
          </Column>
          <Column>
            <span
              style={{
                fontSize: 12,
              }}
            >
              {web}
            </span>
          </Column>
        </ContainerItem>
        <Divider />
        <ContainerItem>
          <Column>
            <ContainerIcon
              style={{
                background: "#e8c58e",
              }}
            >
              <img alt="" src={Desktop} width={24} height={24} />
            </ContainerIcon>
            <span
              style={{
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              Offline
            </span>
          </Column>
          <Column>
            <span
              style={{
                fontSize: 12,
              }}
            >
              {offline}
            </span>
          </Column>
        </ContainerItem>
      </div>
    </ChartContainer>
  );
}
