import styled, { css } from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface IPicture {
  picture: string;
  size?: number;
}

interface ILogo {
  hasOtherLogo: boolean;
}

export const Container = styled.div`
  background-color: ${stylesGlobalDefault.white};
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 0;
`;

export const ContainerLeft = styled.div<ILogo>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 24px;
  border-right: 1px solid #ccc;

  img {
    width: 100px;

    ${(props) =>
      props.hasOtherLogo &&
      css`
        height: 35px;
        width: 100%;
        object-fit: contain;
      `}
  }
`;

export const ContainerMiddle = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-left: 25px;
`;

export const ContainerUnidade = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: 2px solid #d1dbe3;
`;

export const ContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: 2px solid ${stylesGlobalDefault.inputsBorderFocusOff};
  padding: 0 24px;
  cursor: pointer;

  img + img {
    margin-left: 24px;
  }

  svg {
    margin-left: 24px;
  }
`;

export const ContainerProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 20px;
`;

export const PictureContainer = styled.div<IPicture>`
  width: ${(props) => props.size || 48}px;
  height: ${(props) => props.size || 48}px;
  border-radius: 50%;
  background: url(${(props) => props.picture}) no-repeat center;
  background-size: cover;
  cursor: pointer;
`;

export const styleButton = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
  marginLeft: 4,
};

export const ReadAll = styled.div`
  text-align: end;
  align-items: end;
  align-content: end;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ContainerNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 24px;
`;

export const ContainerQtdNotification = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #eb5861;
  margin-bottom: -14px;
  margin-left: 16px;
  padding: 2px;

  color: #fff;
  font-size: 10px;
  font-family: "Inter";
`;
