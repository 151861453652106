import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  width: 100%;
  height: 94%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% - 48px;
  height: 80px;
  min-height: 80px;
  padding: 0 24px;
  background: ${stylesGlobalDefault.blackMenu2};
  margin-left: -3px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  font-size: 18px;
  color: ${stylesGlobalDefault.white};
  margin-right: 12px;
`;

export const Body = styled.div`
  overflow-y: hidden;
  height: 100%;
  display: flex;
`;

export const ScrollContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
