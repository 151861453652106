import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const AttendantService = {
  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/Attendant/GetAllByCustomerId?customerId=${customerId}${
          status !== null ? `&Active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAll2: async (customerId: number, procedureCustomerId: number) => {
    try {
      const { data } = await api.get<any>(
        `/Attendant/GetAllByCustomerId2?customerId=${customerId}&procedureCustomerId=${procedureCustomerId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  post: async (authToken: string | null, attendant: any) => {
    try {
      const body = {
        ...attendant,
      };
      const { data } = await api.post<any>(`/Attendant/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  put: async (authToken: string | null, attendant: any) => {
    try {
      const body = {
        ...attendant,
      };
      const { data } = await api.put<any>(`/Attendant/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  delete: async (authToken: string | null, attendantId: number) => {
    try {
      const body = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/Attendant/update?id=${attendantId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAttedantByType: async (
    procedureCustomerId: number,
    hearingTypeId: number
  ) => {
    try {
      const { data } = await api.get(
        `/attendant/GetAllByHearingType?procedureCustomerId=${procedureCustomerId}&hearingTypeId=${hearingTypeId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error?.response?.data?.message,
      };
    }
  },
  getAttendantByProcedureRoleType: async ({
    procedureCustomerTypeId,
  }: {
    procedureCustomerTypeId: number;
  }) => {
    try {
      const { data } = await api.get(
        `/attendant/GetAttendantRoleProcedureTypeDTO?procedureCustomerTypeId=${procedureCustomerTypeId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
};
