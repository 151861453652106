import { Provider } from "react-redux";
import { initializeStore } from "store";

import { useToast } from "hooks/toast";

const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  const { addToast } = useToast();
  const store = initializeStore(addToast);

  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
