import { ContainerEmptyList } from "./styles";
import FileIconGray from "../../../assets/svg/FileIcon-Gray.svg";
import FileIcon from "../../../assets/svg/FileIcon-White.svg";
import { ChartContainer } from "../ChartContainer";
import {
  Column,
  ContainerIcon,
  ContainerItem,
  Divider,
} from "../ChartContainer/styles";

export function ProcessInQueue({ process }: { process: any[] }) {
  return (
    <ChartContainer title="Processos em andamento">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Divider
          style={{
            background: "#CCC",
          }}
        />
        {process.length > 0 &&
          process.map((item) => (
            <ContainerItem key={item.description}>
              <Column>
                <ContainerIcon
                  style={{
                    backgroundColor: "#05C448",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img alt="" src={FileIcon} width={20} height={20} />
                </ContainerIcon>
                <span
                  style={{
                    fontSize: 12,
                    marginLeft: 8,
                  }}
                >
                  {item.description}
                </span>
              </Column>
              <Column>
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  {item.total}
                </span>
              </Column>
            </ContainerItem>
          ))}
        {process.length === 0 && (
          <ContainerEmptyList>
            <img alt="" src={FileIconGray} width={60} height={60} />
            <span
              style={{
                color: "#9B9797",
                paddingTop: 20,
              }}
            >
              Lista de processos vazia
            </span>
          </ContainerEmptyList>
        )}
      </div>
    </ChartContainer>
  );
}
