import { PermissionTypes } from "./actios.types";
import { Permission } from "../../../../utils/entities/permission";

export interface SetPermissionAction {
  type: PermissionTypes.SET_PERMISSION;
  payload: Permission[];
}

export const setPermissions = (
  permissions: Permission[]
): SetPermissionAction => ({
  type: PermissionTypes.SET_PERMISSION,
  payload: permissions,
});
