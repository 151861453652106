import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export interface ICreateHearingTypeAttendantRole {
  customerId: number;
  hearingTypeId: number;
  attendantRoleIdsList: Array<number>;
}

export interface IUpdateHearingTypeAttendantRole {
  hearingTypeAttendantRoleId: number;
  hearingTypeId: number;
  attendantRoleId: number;
}

export const HearingTypeAttendantRoleService = {
  getAllByCustomerId: async (customerId: number) => {
    try {
      const { data } = await api.get<any>(
        `HearingType/GetAllByCustomerId?customerId=${customerId}`
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getById: async (customerId: number, hearingTypeId: number) => {
    try {
      const { data } = await api.get<any>(
        `HearingTypeAttendantRole/GetHearingTypeAttendantRoleRelation?customerId=${customerId}&hearingType=${hearingTypeId}`
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  addAll: async (bodyPost: ICreateHearingTypeAttendantRole) => {
    try {
      const { data } = await api.post(
        "/HearingTypeAttendantRole/AddAll",
        bodyPost
      );
      return {
        success: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  updateAll: async (idCustomer: number) => {
    const body = [
      {
        hearingTypeAttendantRoleId: 11,
        hearingTypeId: 3,
        attendantRoleId: 1,
      },
      {
        hearingTypeAttendantRoleId: 7,
        hearingTypeId: 3,
        attendantRoleId: 6,
      },
      {
        hearingTypeAttendantRoleId: 3,
        hearingTypeId: 3,
        attendantRoleId: 4,
      },
    ];
  },
  AddSingle: async (
    customerId: number,
    hearingTypeId: number,
    attendantRoleId: number
  ) => {
    try {
      let body = {
        id: 0,
        customerId: customerId,
        hearingTypeId: hearingTypeId,
        attendantRoleId: attendantRoleId,
      };

      const { data } = await api.post("/HearingTypeAttendantRole/Add", body);

      return {
        success: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  deleteAll: async (ids: Array<number>) => {
    try {
      let body: any = ids;
      const { data } = await api.delete(
        "/HearingTypeAttendantRole/DeleteObject",
        body
      );

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
  deleteSingle: async (id: number) => {
    try {
      const { data } = await api.delete(
        `/HearingTypeAttendantRole/Delete?id=${id}`
      );

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
};
