import { ReactElement } from "react";

import { Container, ContainerChart, ContainerTitle, Label } from "./styles";

interface ChartProps {
  title: string;
  children: ReactElement;
  styleContainer?: any;
}

export function ChartContainer({
  title,
  children,
  styleContainer,
}: ChartProps) {
  return (
    <Container style={styleContainer}>
      <ContainerTitle>
        <Label>{title}</Label>
      </ContainerTitle>
      <ContainerChart>{children}</ContainerChart>
    </Container>
  );
}
