import styled from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

interface ProgressBarProps {
  total: number;
  used: number;
  title: string;
  labelUsed: string;
  labelRest: string;
  explanation: string;
  isMinutagem: boolean;
  usersLoggedin: number;
}

const ProgressBarContainer = styled.div<{ background: boolean }>`
  width: 100%;
  height: 40px;
  margin-top: 4px;
  //background-color: rgb(5, 196, 72);
  background-color: ${({ background }) =>
    background === true ? stylesGlobalDefault.green : "none"};
  position: relative;

  @media screen and (max-height: 768px) {
    height: 20px;
  }
`;

const UsedProgressContainer = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #4287f5;
  //width: ${({ progress }) => (progress >= 100 ? 100 : progress)}%;
  width: calc(${({ progress }) => (progress >= 100 ? 100 : progress)}% - 23px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MissingContainer = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  //left: ${({ progress }) => progress}%;
  right: 0;
  background-color: ${stylesGlobalDefault.green};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4px;
`;

const Label = styled.span`
  color: white;
  font-size: 12px;
  font-weight: bold;
`;

export function MinutesTranscriptionCertificate({
  total,
  used,
  title,
  labelUsed,
  labelRest,
  explanation,
  isMinutagem,
  usersLoggedin,
}: ProgressBarProps) {
  let progress = null;
  let isSimultAccess = false;
  if (isMinutagem) {
    if (total <= 0) {
      total = used;
      progress = 100;
    } else {
      progress = (used / (total - used + used)) * 100;
    }
  } else {
    if (title === "Controle de cadastro ativo") {
      progress = (used / total) * 100;
    } else {
      isSimultAccess = true;
      progress = (usersLoggedin / total) * 100;
    }
  }

  return (
    <div
      style={{
        paddingLeft: 18,
        paddingRight: 20,
        height: "100%",
        width: "calc(100% - 38px)",
        marginTop: 20,
      }}
    >
      <span
        style={{
          fontSize: 12,
          color: "#999797",
          marginBottom: "20px",
          height: "40px",
        }}
      >
        {title.charAt(0).toUpperCase() + title.slice(1)}{" "}
        {explanation !== "" ? `(${explanation})` : null}
      </span>
      <ProgressBarContainer background={false}>
        <UsedProgressContainer
          progress={progress}
          style={{ paddingLeft: "20px", paddingRight: "5px" }}
        >
          <Label
            style={{
              position: "absolute",
              //marginRight: progress < 10 ? 1 : 14,
              //marginRight: 14,
            }}
          >
            {`${isSimultAccess ? usersLoggedin : used}`}
          </Label>
        </UsedProgressContainer>
      </ProgressBarContainer>

      <ProgressBarContainer background={true}>
        <MissingContainer
          progress={progress}
          style={{ paddingLeft: "20px", paddingRight: "5px" }}
        >
          {/* {progress > 98 && isMinutagem ? null : <Label>{`${total}`}</Label>} */}
          {/* {<Label style={{marginRight: 10}}>{`${total}`}</Label>} */}
          {<Label>{`${total}`}</Label>}
        </MissingContainer>
      </ProgressBarContainer>
    </div>
  );
}
