import styled from "styled-components";

interface BarProps {
  strongLevel: "weak" | "medium" | "strong";
}

const getColorByLevel = (
  strongLevel: "weak" | "medium" | "strong" | "gray"
): string => {
  switch (strongLevel) {
    case "weak":
      return "#EB5861";
    case "medium":
      return "yellow";
    case "strong":
      return "#30CD82";
    default:
      return "#D1DBE3";
  }
};

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4px;
  width: 140px;
`;

const Bar = styled.div<{ backgroundColor: string }>`
  height: 100%;
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  margin: 1px;
`;

interface SpanProperty {
  colorStrong: "weak" | "medium" | "strong";
}

const Text = styled.span<SpanProperty>`
  font-size: 14px;
  margin-left: 4px;
  color: ${(props) => {
    switch (props.colorStrong) {
      case "weak":
        return "#EB5861";
      case "medium":
        return "#D1DBE3";
      case "strong":
        return "#30CD82";
      default:
        return "#D1DBE3";
    }
  }};
`;

const BarComponent: React.FC<BarProps> = ({ strongLevel }) => {
  let bars: JSX.Element[] = [];

  switch (strongLevel) {
    case "weak":
      bars = [
        <Bar key={0} backgroundColor={getColorByLevel(strongLevel)} />,
        <Bar key={1} backgroundColor={getColorByLevel("gray")} />,
        <Bar key={2} backgroundColor={getColorByLevel("gray")} />,
      ];
      break;
    case "medium":
      bars = [
        <Bar key={0} backgroundColor={getColorByLevel(strongLevel)} />,
        <Bar key={1} backgroundColor={getColorByLevel(strongLevel)} />,
        <Bar key={2} backgroundColor={getColorByLevel("gray")} />,
      ];
      break;
    case "strong":
      bars = [
        <Bar key={0} backgroundColor={getColorByLevel(strongLevel)} />,
        <Bar key={1} backgroundColor={getColorByLevel(strongLevel)} />,
        <Bar key={2} backgroundColor={getColorByLevel(strongLevel)} />,
      ];
      break;
    default:
      break;
  }

  return (
    <BarWrapper>
      {bars}
      {strongLevel === "weak" ? (
        <Text colorStrong={strongLevel}>Fraca</Text>
      ) : null}
      {strongLevel === "medium" ? (
        <Text colorStrong={strongLevel}>Media</Text>
      ) : null}
      {strongLevel === "strong" ? (
        <Text colorStrong={strongLevel}>Forte</Text>
      ) : null}
    </BarWrapper>
  );
};

export default BarComponent;
