import {
  SET_MIC_DEVICES,
  SET_VIDEO_DEVICES,
  SET_AUDIO_DEVICES,
  SELECT_MIC_DEVICE,
  SELECT_VIDEO_DEVICE,
  SELECT_AUDIO_DEVICE,
  SET_MIC_LEVEL,
  SET_PERMISSIONS,
  SET_LOADING,
} from "store/modules/mediaDevices/actions/actions.types";

import { DeviceOption } from "utils/formatDevices";

interface MediaDevicesState {
  micDevices: DeviceOption[];
  videoDevices: DeviceOption[];
  audioDevices: DeviceOption[];
  selectedMic?: DeviceOption | null;
  selectedVideo?: DeviceOption | null;
  selectedAudio?: DeviceOption | null;
  micLevel: number;
  permissions: {
    mic: boolean;
    camera: boolean;
    audio: boolean;
  };
  loading: boolean;
}

const INITIAL_STATE: MediaDevicesState = {
  micDevices: [],
  videoDevices: [],
  audioDevices: [],
  selectedMic: null,
  selectedVideo: null,
  selectedAudio: null,
  micLevel: 0,
  permissions: {
    mic: false,
    camera: false,
    audio: false,
  },
  loading: false,
};

export default function reducer(
  state = INITIAL_STATE,
  action: any
): MediaDevicesState {
  switch (action.type) {
    case SET_MIC_DEVICES:
      return { ...state, micDevices: action.payload };
    case SET_VIDEO_DEVICES:
      return { ...state, videoDevices: action.payload };
    case SET_AUDIO_DEVICES:
      return { ...state, audioDevices: action.payload };

    case SELECT_MIC_DEVICE:
      return { ...state, selectedMic: action.payload };
    case SELECT_VIDEO_DEVICE:
      return { ...state, selectedVideo: action.payload };
    case SELECT_AUDIO_DEVICE:
      return { ...state, selectedAudio: action.payload };

    case SET_MIC_LEVEL:
      return { ...state, micLevel: action.payload };

    case SET_PERMISSIONS:
      return { ...state, permissions: action.payload };

    case SET_LOADING:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
