import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  width: 700px;
  background: ${stylesGlobalDefault.backgroundListItemColor};
  min-width: 300px;
  max-width: 300px;
  padding: 24px;
  height: 90vh;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //background: #f1f1f1;
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${stylesGlobalDefault.blue};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: #555;
    background: ${stylesGlobalDefault.gray};
    overflow-y: auto;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${stylesGlobalDefault.inputsBorderFocusOff};
  padding-bottom: 24px;
  margin-bottom: 26.5px;
`;
