import styled from "styled-components";

import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  border-radius: 10px;
  background-color: ${stylesGlobalDefault.backgroundListItemColor};
  border: 1px solid ${stylesGlobalDefault.backgroundListItemColor};
`;

export const ContainerChart = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 20px;
  margin: 4px;
`;

export const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  transform-origin: center;
`;

export const Label = styled.label`
  font-size: 16px;
  text-anchor: middle;
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: 35%;
  padding: 0px 4px 0px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ContainerIcon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background-color: mediumslateblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #adadad;
`;
