import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 20px;
  height: 100vh;
`;

export const ContentTranscription = styled.div`
  padding: 0px 12px;
  height: 100%;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;

  @media (max-width: 1600px) {
    max-height: 420px;
  }
`;

export const ContainerContent = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  font-size: 14px;
  gap: 4px;
  margin-bottom: 2px;
  white-space: normal;
  word-wrap: break-word;
  flex-wrap: wrap;
`;

export const HighlightedWord = styled.span`
  color: #ff0000;
  background-color: rgb(200, 255, 0);
  cursor: pointer;
  font-weight: normal;
`;

export const NormalWord = styled.span`
  color: #000;
  cursor: pointer;
  font-weight: normal;
`;

export const ParagraphWithoutSpacing = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  font-family: Helvetica, Arial, sans-serif;
`;

export const EmptyMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  text-align: center;
`;
