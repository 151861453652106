import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  widthHeight?: number;
  isFilter: boolean;
}

export const Container = styled.button<IProps>`
  width: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  height: ${(props) => (props.widthHeight ? props.widthHeight + "px" : "40px")};
  margin-left: 6px;
  background-color: ${(props) =>
    props.isFilter ? "rgb(233, 141, 11)" : stylesGlobalDefault.blue};
  border-radius: 50%;
  border: 2px
    ${(props) =>
      props.isFilter ? "rgb(233, 141, 11)" : stylesGlobalDefault.blue}
    solid;
  cursor: pointer;

  &:hover {
    border-color: ${stylesGlobalDefault.white};
    background-color: ${stylesGlobalDefault.blueDark};
    background: ${stylesGlobalDefault.blueDark};
  }
`;

export const PlusIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylesGlobalDefault.white};
  font-size: 20px;
`;
