import styled from "styled-components";

interface TyphoProps {
  size?: "very-bigger" | "bigger" | "medium" | "small" | "very-small";
  fontStyle?: "bold" | "regular" | "semibold" | "medium";
}

export const Text = styled.span<TyphoProps>`
  font-family: Inter;
  font-size: ${(props) => {
    switch (props.size) {
      case "very-bigger":
        return "18px";
      case "bigger":
        return "16px";
      case "medium":
        return "14px";
      case "small":
        return "12px";
      case "very-small":
        return "10px";
      default:
        return "14px";
    }
  }};
  font-weight: ${(props) => {
    switch (props.fontStyle) {
      case "bold":
        return "700";
      case "semibold":
        return "600";
      case "regular":
        return "500";
      case "medium":
        return "400";
      default:
        return "400";
    }
  }};
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
`;
