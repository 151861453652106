import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface Props {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  cursor: pointer;
`;
export const Wrapper = styled.div<Props>`
  width: 20px;
  height: 20px;
  border: 1px solid
    ${({ active }) =>
      active ? stylesGlobalDefault.blue : stylesGlobalDefault.grayLight};
  border-radius: 50%;
  background: ${({ active }) => (active ? stylesGlobalDefault.blue : "")};
  display: flex;
  justify-content: center;
`;
export const Title = styled.label`
  color: ${stylesGlobalDefault.black};
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
`;
