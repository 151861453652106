import React, { useState, useRef, useEffect } from "react";

import { Editor } from "@tinymce/tinymce-react";

import { ISummary } from "./interface";
import {
  Container,
  StatusContainer,
  StatusText,
  ContainerTemplate,
} from "./styles";
import { usePermissions } from "../../../hooks/permission";
import { useToast } from "../../../hooks/toast";
import { transcription } from "../../../services/app/transcription";
import { editorInit } from "../../../services/utils/tinyConfiguration";
import { FORM_EXPORT_FILES } from "../../../utils/entities/permission";
import { ContainerButton, NewMarkingButton, SpaceButton } from "../styles";

export const Summary: React.FC<ISummary> = ({
  buttonFunction = () => {},
  status,
  disabledButton = false,
  getTranscription,
  transcriptions,
}) => {
  const { addToast } = useToast();
  const [_localStatus, setLocalStatus] = useState<number | null>(status);
  const [localText, setLocalText] = useState("");
  const editorRef = useRef<any>(null);

  const permissions = usePermissions(FORM_EXPORT_FILES.HEARING_FILE);

  useEffect(() => {
    if (editorRef.current && localText !== editorRef.current.getContent()) {
      editorRef.current.setContent(localText);
    }
  }, [localText]);

  useEffect(() => {
    if (getTranscription) {
      getTranscription();
    } else {
      console.warn("getTranscription está indefinido!");
    }
  }, []);

  useEffect(() => {
    if (transcriptions && transcriptions.length > 0) {
      const newText = transcriptions[0].textPlainSummary || "";
      const newStatus = transcriptions[0].status ?? null;

      if (newText !== localText) {
        setLocalText(newText);
      }

      setLocalStatus(newStatus);
    }
  }, [transcriptions]);

  const handleSaveSummary = async () => {
    if (!localText || localText.trim() === "") {
      addToast({
        type: "warning",
        title: "Inicie uma transcrição",
        description: "Para salvar inicie uma transcrição automática",
      });
      return;
    }

    if (!transcriptions || transcriptions.length === 0) {
      addToast({
        type: "warning",
        title: "Nenhuma transcrição disponível",
        description: "Não há transcrição para salvar.",
      });
      return;
    }

    const id = transcriptions[0].id;
    const updatedText = editorRef.current?.getContent();

    try {
      const response = await transcription.patch({
        textPlainSummary: localText,
        id,
      });

      if (response.success) {
        addToast({
          type: "success",
          title: "Resumo salvo",
          description: "O resumo foi salvo com sucesso!",
        });
        setLocalText(updatedText);
      } else {
        addToast({
          type: "error",
          title: "Erro ao salvar resumo",
          description:
            response.message || "Ocorreu um erro ao salvar o resumo.",
        });
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro inesperado",
        description: "Não foi possível salvar o resumo.",
      });
      console.error("Erro na requisição ao salvar resumo:", error);
    }
  };

  return (
    <Container hasTranscription={true}>
      <ContainerTemplate>
        {localText && localText.trim() ? (
          <Editor
            onKeyDown={(event) => {
              if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                event.preventDefault();
                alert("Não é possivel copiar esse texto");
              }
            }}
            apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              ...editorInit(permissions.export),
              content_style: "body { font-size: 14px;}",
            }}
            onReset={() =>
              setTimeout(() => {
                return true;
              }, 1000)
            }
            value={localText}
            onEditorChange={(content) => setLocalText(content)}
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          />
        ) : (
          <StatusContainer>
            <StatusText>
              O resumo estará disponível assim que a transcrição estiver
              concluída.
            </StatusText>
          </StatusContainer>
        )}
      </ContainerTemplate>

      <ContainerButton>
        <SpaceButton />
        <NewMarkingButton width={91} onClick={handleSaveSummary}>
          <strong>
            {localText && localText.trim() ? "Salvar" : "Iniciar"}
          </strong>
        </NewMarkingButton>
      </ContainerButton>
    </Container>
  );
};
