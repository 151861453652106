import { useEffect, useState } from "react";

import { ContainerItems } from "./styles";
import { CardDownloadFile } from "../../components/CardDownloadFile";
import ModalRight from "../../components/ModalRight";
import { hearingFileService } from "../../services/hearingFile";
import { LogOperation } from "../../utils/entities/logOperation";

interface Props {
  isOpenDownloadsModal: boolean;
  setIsOpenDownloadsModal: (isOpen: boolean) => void;
  hearingId: number;
}

interface File {
  filename: string;
  hearingFileId: number;
  size: number;
  type: number;
  url: string;
}

export function DownloadFilesModal({
  isOpenDownloadsModal,
  setIsOpenDownloadsModal,
  hearingId,
}: Props) {
  const [listOfDownloads, setListOfDownloads] = useState([]);

  async function findFiles() {
    const { data } = await hearingFileService.getAllDownloadFiles(hearingId);

    if (data.length > 0) {
      setListOfDownloads(data);
    }
  }

  useEffect(() => {
    if (isOpenDownloadsModal) findFiles();
  }, [isOpenDownloadsModal]);

  const type = (tipo: number) => {
    if (tipo === 1) {
      return "Video";
    }
    if (tipo === 2) {
      return "Image";
    }
    if (tipo === 3) {
      return "Audio";
    }
    if (tipo === 4) {
      return "Document";
    }
    if (tipo === 5) {
      return "Video anexado";
    }
  };

  const tamanho = (bytes: number) => {
    let total = (bytes / 1024).toFixed(2);
    if (Number(total) > 1000) {
      return (bytes / 1048576).toFixed(2) + " MB ";
    }
    return total + " KB";
  };

  const clicouEmBaixar = () => {
    LogOperation.postLogOperation("HearingFile", 6, hearingId);
  };

  return (
    <>
      <ModalRight
        isOpen={isOpenDownloadsModal}
        setIsOpen={() => {
          setIsOpenDownloadsModal(!isOpenDownloadsModal);
        }}
        title={"Downloads"}
        onClickAplicar={() => {}}
        haveFooter={false}
      >
        <ContainerItems>
          {listOfDownloads &&
            listOfDownloads.length > 0 &&
            listOfDownloads.map((item: File) => {
              return (
                <CardDownloadFile
                  tipo={item.type}
                  key={String(item.filename)}
                  description={`${type(item.type)} , ${tamanho(item.size)} `}
                  name={item.filename}
                  onClick={() => {
                    clicouEmBaixar();
                    window.open(item.url);
                  }}
                />
              );
            })}
        </ContainerItems>
      </ModalRight>
    </>
  );
}
