import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: 6px; */
  margin-top: 4px;
  margin-bottom: 2px;
  height: 220px;
  border-radius: 10px;
  background-color: #ecf0f3;
  border: 1px solid #ecf0f3;
`;

export const ContainerEmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  padding-top: 10px;
  padding-left: 20px;
  margin: 4px;
`;

export const Title = styled.label`
  font-size: 16px;
  text-anchor: middle;
`;

export const ContainerTable = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid black;
  padding: 4px;
  text-align: left;
  font-size: 14px;
`;

export const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  font-size: 14px;
`;
