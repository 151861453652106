import React, { useState } from "react";

import {
  Container,
  Title,
  EditContainer,
  ShowContainer,
  RightContainerSidebar,
} from "./styles";
import bluePlusIcon from "../../assets/procedure/blue_plus.svg";
import downArrow from "../../assets/procedure/down_arrow.svg";
import editIconOut from "../../assets/procedure/edit.svg";
import editIconOver from "../../assets/procedure/edit_circle.svg";
import whitePlusIcon from "../../assets/procedure/plus.svg";
import upArrow from "../../assets/procedure/up_arrow.svg";
import GreyPlusIcon from "../../assets/svg/grey_plus.svg";
import { useToast } from "../../hooks/toast";
import { QuantityComponent } from "../QuantityComponent";

export type ITitleSidebar = {
  title: string;
  titleSize?: number;
  quantity?: number;
  show?: boolean;
  hasLineTop?: boolean;
  hasLineBottom?: boolean;
  disabled?: boolean;
  onClickEdit?: () => unknown;
  onAddSidebar?: () => unknown;
  onShow?: () => unknown;
};

export const TitleSidebar: React.FC<ITitleSidebar> = ({
  title,
  titleSize,
  quantity,
  onClickEdit,
  onAddSidebar,
  onShow,
  show,
  disabled = false,
  hasLineTop = false,
  hasLineBottom = false,
}) => {
  const [iconPlus, setIconPlus] = useState(bluePlusIcon);
  const [editIcon, setEditIcon] = useState(editIconOut);
  const { addToast } = useToast();

  const hasquantity = !!quantity;

  return (
    <Container hasLineTop={hasLineTop} hasLineBottom={hasLineBottom}>
      <Container
        onClick={onShow}
        style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <Title size={titleSize}>{title}</Title>
        {hasquantity && (
          <QuantityComponent id="hearing_quantity_scheduled" value={quantity} />
        )}
      </Container>
      <RightContainerSidebar>
        {onClickEdit && (
          <EditContainer
            id="procedure_edit_button"
            onClick={onClickEdit}
            onMouseOver={() => setEditIcon(editIconOver)}
            onMouseOut={() => setEditIcon(editIconOut)}
          >
            <img src={editIcon} alt="" />
          </EditContainer>
        )}
        {onAddSidebar && !disabled && (
          <EditContainer
            onClick={onAddSidebar}
            id={title.replace(" ", "") + "_plus_button"}
            onMouseOver={() => setIconPlus(whitePlusIcon)}
            onMouseOut={() => setIconPlus(bluePlusIcon)}
          >
            <img src={iconPlus} alt="" />
          </EditContainer>
        )}
        {onAddSidebar && disabled && (
          <EditContainer
            isDisabled={disabled}
            id="procedure_edit_button"
            onClick={() =>
              addToast({
                type: "warning",
                title: "Aviso",
                description:
                  "Para adicionar uma oitiva é necessario adicionar um participante primeiro",
              })
            }
          >
            <img src={GreyPlusIcon} alt="" />
          </EditContainer>
        )}

        {onShow && (
          <ShowContainer id="show_container" onClick={onShow}>
            <img src={show ? upArrow : downArrow} alt="" />
          </ShowContainer>
        )}
      </RightContainerSidebar>
    </Container>
  );
};
