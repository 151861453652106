import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #3e424e;
`;

export const ContainerHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${stylesGlobalDefault.white};
`;

export const ContainerBody = styled.div`
  flex: 3;
  background: ${stylesGlobalDefault.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  margin-bottom: 40px;
`;

export const TitleBody = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: ${stylesGlobalDefault.gray};
`;

export const SubtitleBody = styled.h4`
  font-size: 14px;
  font-weight: 200;
  color: ${stylesGlobalDefault.gray};
`;

export const ContainerFooter = styled.div`
  flex: 1;
  display: -webkit-inline-box;
  justify-content: end;
  padding-right: 20px;
`;

export const customStyles = {
  content: {
    border: "none",
    backgroundColor: stylesGlobalDefault.white,
    width: "480px",
    height: "360px",
    inset: "auto",
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    zIndex: 999,
  },
  overlay: {
    backgroundColor: "rgba(35, 35, 35, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
};

export const styleImage = {
  top: 0,
  right: 0,
  margin: 26,
  cursor: "pointer",
};
