import React from "react";

import { Container, TimeContainer } from "./styles";
import { InputComponent } from "../../components/Details/InputTime";
import { TextAreaComponent } from "../../components/TextArea";

type IMarkingChildrenModal = {
  marking: any;
  toEditItem: any;
  isEdit: boolean;
  error: boolean;
  subject: string;
  setSubject: (e: string) => unknown;
  setAttendantId: (id: any) => unknown;
  isBegining: boolean;
};

export const MarkingChildrenModal: React.FC<IMarkingChildrenModal> = ({
  marking,
  subject,
  setSubject,
  isBegining,
}) => {
  const isRequiredList = {
    attendant: true,
    selectedTime: true,
    durationTime: true,
    description: true,
  };

  return (
    <Container>
      <TimeContainer>
        <InputComponent
          defaultValue={marking.playerState.offset1}
          disabled={true}
          required={isRequiredList.selectedTime}
          placeholder="00:00"
          onChange={(e) => {}}
          label="Inicio"
        />
        <InputComponent
          defaultValue={marking.playerState.offset2}
          placeholder={marking.playerState.offset2}
          disabled={true}
          required={isRequiredList.durationTime}
          onChange={(e) => {}}
          label="Fim"
        />
      </TimeContainer>
      {marking.playerState.offset1 &&
      marking.playerState.offset2 &&
      !isBegining ? (
        <TextAreaComponent
          style={{ marginTop: 10 }}
          onChange={setSubject}
          value={subject}
          label="Descrição"
          required={true}
        />
      ) : null}
    </Container>
  );
};
