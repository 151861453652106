import styled from "styled-components";

export const Container = styled.div`
  width: 100% -20px;
  padding: 10px;
  background: #ecf0f3;
  height: 76vh;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #d1dbe3;
  padding-bottom: 24px;
  margin-bottom: 26.5px;
`;

export const Empty = styled.div`
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;
