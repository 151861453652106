import React from "react";

import { useSelector } from "react-redux";

import { Loading } from "../Loading";

export const LoadingToast: React.FC = () => {
  const { loading } = useSelector((state: any) => state.loading);
  return <Loading loading={loading} />;
};
