import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import {
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
  Body,
  ScrollContent,
} from "./styles";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { AddButton } from "../AddButton";
import { AddButtonDisabled } from "../AddButtonDisabled";
import { ContainerTitleWithNumber } from "../Body/AdminArea/styles";
import { HearingList } from "../Hearing/List";
import { QuantityComponent } from "../QuantityComponent";

export type IHeaderHearing = {
  title?: string;
  openModal: boolean;
  addButtonClick?: () => unknown;
  procedureItem: null | any;
  setItemData: (data: any) => any | null;
  setIsEditHearing: (attribute: boolean) => any;
  setOpenModal: (openModal: boolean) => unknown;
  reloadList: boolean;
  itemDataHearing: any | null;
  setItemDataHearing: (dataHearing: any) => any | null;
  getAllHearing: () => unknown;
  hearingList: Array<any>;
  selectedHearingId?: string;
};

export const Hearing: React.FC<IHeaderHearing> = ({
  openModal,
  procedureItem,
  title,
  addButtonClick,
  setItemData,
  setIsEditHearing,
  setOpenModal,
  itemDataHearing,
  setItemDataHearing,
  getAllHearing,
  hearingList,
  selectedHearingId,
}) => {
  const { addToast } = useToast();
  const { attendants } = useSelector((state: any) => state.procedure);

  useEffect(() => {
    getAllHearing();
  }, [procedureItem]);

  const onDeleteItem = async (id: number) => {
    setOpenModal(false);

    const body = [
      {
        op: "replace",
        value: false,
        path: "active",
      },
    ];

    await api.patch(`/hearing/update?id=${id}`, body);

    addToast({
      type: "success",
      title: "Oitiva excluída",
      description: "A oitiva foi excluído com sucesso",
    });
  };

  const clickHearing = (hearingItem: any) => {
    if (
      itemDataHearing &&
      hearingItem &&
      itemDataHearing.id === hearingItem.id
    ) {
      setOpenModal(false);
      setItemDataHearing(null);
    } else {
      setOpenModal(true);
      setItemDataHearing(hearingItem);
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title>{title}</Title>
            {hearingList.length > 0 && procedureItem !== null ? (
              <QuantityComponent
                value={hearingList.length || 0}
                id="hearing_quantity"
              />
            ) : null}
          </ContainerTitleWithNumber>
          {attendants.length && addButtonClick && procedureItem ? (
            <AddButton id="hearing_add_button" onClick={addButtonClick} />
          ) : (
            <AddButtonDisabled
              id="hearing_add_button_disabled"
              onClick={() =>
                addToast({
                  type: "warning",
                  title: "Aviso",
                  description:
                    "Para adicionar uma oitiva é necessario adicionar um participante primeiro",
                })
              }
            />
          )}
        </TitleContainer>
      </HeaderContainer>
      <Body>
        <ScrollContent>
          {procedureItem !== undefined ? (
            procedureItem && (
              <HearingList
                itemDataHearing={itemDataHearing}
                setIsEditHearing={setIsEditHearing}
                setItemData={setItemData}
                setOpenModal={clickHearing}
                openModal={openModal}
                onDeleteItem={onDeleteItem}
                hearingList={hearingList}
                selectedHearingId={selectedHearingId}
              />
            )
          ) : (
            <HearingList
              itemDataHearing={itemDataHearing}
              setIsEditHearing={setIsEditHearing}
              setItemData={setItemData}
              setOpenModal={clickHearing}
              openModal={openModal}
              onDeleteItem={onDeleteItem}
              hearingList={[]}
              selectedHearingId={selectedHearingId}
            />
          )}
        </ScrollContent>
      </Body>
    </Container>
  );
};
