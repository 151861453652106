import { useState } from "react";

import Avatar from "react-avatar";
import ReactLoading from "react-loading";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import { showLoading, hideLoading } from "store/modules/login/actions";
import { setScreenStatus } from "store/modules/screen_controll/actions";
import { changeUserPreferences } from "store/modules/user/actions";
import { useAppDispatch, useAppSelector } from "store/rootReducer";

import { MyAccount } from "features/MyAccount";
import SelectAudioCamera from "features/SelectAudioCamera";

import { PictureContainer } from "components/HeaderUp/styles";
import ModalRight from "components/ModalRight";

import { storageBaseUrl } from "services/api";
import { logout } from "services/auth";

import logo from "assets/Kenta 4.0_Seg_Pub.png";
import close from "assets/svg/close.svg";

import { clearCookies } from "utils/cookies";
import { LogOperation } from "utils/entities/logOperation";
import { deleteFromlocalStorage } from "utils/sessionStorageEncrypt";
import { getValueFromlocalStorage } from "utils/sessionStorageEncrypt";

import {
  Container,
  ContainerHeader,
  ContainerMenu,
  customStyles,
  Label,
  Subtitle,
  TitleHeader,
  styleImage,
  avatarStyle,
  ContainerClose,
  VersionContainer,
  TitleMenu,
  Separator,
  LabelLink,
} from "./style";

interface ModalType {
  title?: string;
  isOpen: boolean;
  userName: string;
  profile: string;
  user: any;
  onClickDelete?: () => void;
  onClickAplicar?: () => void;
  onClose: () => void;
  changePicture: boolean;
  setChangePicture: (e: boolean) => void;
}
export const onClickSair = async ({
  setIsLoading,
  dispatch,
  navigate,
}: any) => {
  dispatch(showLoading());
  setIsLoading(true);
  await LogOperation.postLogOperation("Logoff", 5, 0);
  const user = getValueFromlocalStorage("@Kenta:user");
  if (user) {
    const parsedUser = JSON.parse(user);
    localStorage.removeItem("IndexProfileSelected");

    //removendo time token caso exista
    deleteFromlocalStorage("timeToCount", "encrypt-time");

    try {
      await logout(parsedUser.hash);
      dispatch(changeUserPreferences(null));
    } catch (error) {
      console.log(error);
    }
  }

  localStorage.clear();
  localStorage.clear();
  navigate("/");
  setIsLoading(false);
  dispatch(hideLoading());
};

export default function ProfileModal(props: ModalType) {
  const [isLoading, setIsLoading] = useState(false);
  const [openPreferencesDrawer, setOpenPreferencesDrawer] =
    useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [onClickModal, setOnClickModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { policeUnit } = useAppSelector((state) => state.policeUnit);
  const { user } = useAppSelector((state) => state.user);

  return (
    <>
      <Modal
        testId="profile_modal_container"
        isOpen={props.isOpen}
        style={{ ...customStyles }}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
      >
        <Container>
          <ContainerClose>
            <img
              src={close}
              style={{ ...styleImage }}
              onClick={() => props.onClose()}
              alt=""
            />
          </ContainerClose>
          <ContainerHeader>
            {user?.picture && policeUnit ? (
              <PictureContainer
                size={60}
                picture={`${storageBaseUrl}/${policeUnit.containerName}/${
                  user.picture
                }?v=${Date.now()}`}
                style={{ cursor: "default" }}
              />
            ) : (
              <Avatar
                name={user?.name || "Avatar"}
                size="60"
                round={true}
                style={{ ...avatarStyle }}
              />
            )}
            <TitleHeader>{user?.name}</TitleHeader>
            <Subtitle>{props.profile}</Subtitle>
          </ContainerHeader>
          {isLoading ? (
            <ReactLoading type="spin" color="#F2A60D" width={24} height={24} />
          ) : (
            <ContainerMenu>
              <Label
                style={{ cursor: "pointer" }}
                onClick={() => setOpenModal(!openModal)}
              >
                Minha conta
              </Label>
              <Label
                style={{ cursor: "pointer", marginTop: 10 }}
                onClick={() => setOpenPreferencesDrawer(true)}
              >
                Configurações de dispositivos
              </Label>
              <Separator />
              <TitleMenu>Suporte</TitleMenu>
              <LabelLink
                href="https://www.kenta.com.br/contato/"
                target="blank"
              >
                Contato do suporte
              </LabelLink>
              {/* 1.features.bugs */}
              <Label>Versão 1.47.232 - 02/04/2025</Label>
              <Separator />
              <Label
                id="profile_modal_sair"
                onClick={() => {
                  onClickSair({ setIsLoading, dispatch, navigate });
                  dispatch(setScreenStatus(false));
                  clearCookies();
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Sair
              </Label>
            </ContainerMenu>
          )}
          <VersionContainer>
            <img src={logo} alt="" />
          </VersionContainer>
        </Container>
      </Modal>
      <ModalRight
        title="Minha Conta"
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
        //haveFooter={false}
        onClickAplicar={() => setOnClickModal(true)}
        showApplyButton={!openModal}
      >
        <MyAccount
          onClickModal={onClickModal}
          setOnClickModal={setOnClickModal}
          changePicture={props.changePicture}
          setChangePicture={props.setChangePicture}
          closeModal={() => setOpenModal(false)}
        />
      </ModalRight>

      <SelectAudioCamera
        open={openPreferencesDrawer}
        setOpen={setOpenPreferencesDrawer}
      />
    </>
  );
}
