import styled, { css } from "styled-components";

type ContainerProps = {
  disableInteractions?: boolean; // Make it optional
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 87%;
  padding: 25px;

  /* Conditional styles based on the prop */
  ${(props) =>
    props.disableInteractions == true &&
    css`
      cursor: not-allowed;
      /* pointer-events: none; */
    `}
`;

export const ContainerBlock = styled.div<ContainerProps>`
  /* Conditional styles based on the prop */
  ${(props) =>
    props.disableInteractions == true &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: none;
      position: absolute;
      width: 31%;
      height: 100%;
      z-index: 2;
    `}
`;
