import { Container, ContainerCircle, Label } from "./styles";
import Users from "../../../assets/svg/users.svg";
import { ChartContainer } from "../ChartContainer";
import { Divider } from "../ChartContainer/styles";
interface UsersLoggedIn {
  total: number;
}

export function UsersLoggedIn({ total }: UsersLoggedIn) {
  return (
    <ChartContainer title="Usuários logados">
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Divider
          style={{
            background: "#CCC",
          }}
        />
        <Container>
          {/* <img src={Users} width={200} height={200}/> */}
          <ContainerCircle>
            <Label>{total ? total : 0}</Label>
          </ContainerCircle>
        </Container>
      </div>
    </ChartContainer>
  );
}
