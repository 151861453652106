import { Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./ProtectoredRoute";
import HearingRecord from "../components/HearingRecord";
import { Dashboard } from "../features/Dashboard";
import { Administration } from "../pages/app/administration";
import { HearingDetails } from "../pages/app/hearingDetails";
import { ProcedureScreen } from "../pages/app/procedure";
import { Reports } from "../pages/app/reports";
import { CreatePassword } from "../pages/auth/createPassword";
import { ForgotPassword } from "../pages/auth/forgotPassword";
import { ImportCertificate } from "../pages/auth/importCertificate";
import { SignIn } from "../pages/auth/signIn";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <div />
          </ProtectedRoute>
        }
      />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/importCertificate" element={<ImportCertificate />} />
      <Route path="/changePassword/guid/:guid" element={<CreatePassword />} />
      <Route
        path="/dashboard/procedure"
        element={
          <ProtectedRoute>
            <ProcedureScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/metricas"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/hearingRecord/una"
        element={
          <ProtectedRoute>
            <HearingRecord />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/administracao/*"
        element={
          <ProtectedRoute role={"admin"}>
            <Administration />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/relatorio/*"
        element={
          <ProtectedRoute role={"relatorio"}>
            <Reports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/procedure/:idProcedure?/hearingDetails"
        element={
          <ProtectedRoute>
            <HearingDetails />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
