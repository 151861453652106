import React, { useEffect } from "react";

import { format } from "date-fns";

import { HearingItem } from "./item";
import { HearingListContainer } from "./styles";
import { ListEmpty } from "../../ListEmpty";

interface HearingList {
  hearingList: Array<any>;
  openModal: boolean;
  setOpenModal: (hearingItem: object) => any;
  //edit
  itemDataHearing: any | null;
  setIsEditHearing: (attribute: boolean) => any;
  onDeleteItem: (id: number) => unknown;
  setItemData: (data: any) => any | null;
  selectedHearingId?: string;
}

export const HearingList = ({
  hearingList,
  onDeleteItem,
  openModal,
  setOpenModal,
  setItemData,
  itemDataHearing,
  selectedHearingId,
}: HearingList) => {
  type statusType = {
    status: string;
    type: string;
  };

  function status(code: string): statusType {
    if (code === "A") {
      return {
        status: "Agendado",
        type: "inactive",
      };
    }
    if (code === "G") {
      return {
        status: "Em andamento",
        type: "warning",
      };
    }
    if (code === "Q") {
      return {
        status: "Publicando",
        type: "success",
      };
    }
    if (code === "P") {
      return {
        status: "Publicada",
        type: "success",
      };
    }
    if (code === "I") {
      return {
        status: "Iniciando",
        type: "progress",
      };
    }
    if (code === "C") {
      return {
        status: "Cancelada",
        type: "error",
      };
    }
    if (code === "X") {
      return {
        status: "Erro notificação",
        type: "error",
      };
    }
    if (code === "N") {
      return {
        status: "Notificado",
        type: "notificated",
      };
    }
    if (code === "D") {
      return {
        status: "Downloading",
        type: "warning",
      };
    }
    if (code === "U") {
      return {
        status: "Uploading",
        type: "warning",
      };
    }
    if (code === "S") {
      return {
        status: "Processando",
        type: "warning",
      };
    }
    if (code === "E") {
      return {
        status: "Encerrada",
        type: "success",
      };
    }
    return {
      status: "Invalido",
      type: "error",
    };
  }

  function ticksToTime(ticks: number) {
    var totalSeconds = Math.floor(ticks / 10000000);
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds % 3600) / 60);
    var seconds = totalSeconds % 60;
    var leftoverTicks = ticks % 10000000;
    if (leftoverTicks >= 6000000) {
      seconds++;
    }
    if (seconds >= 60) {
      seconds = 0;
      minutes++;
    }
    if (minutes >= 60) {
      minutes = 0;
      hours++;
    }
    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  }

  return (
    <HearingListContainer>
      {hearingList.length ? (
        hearingList.map((item: any) =>
          item.active ? (
            <HearingItem
              itemDataHearingId={
                selectedHearingId ? selectedHearingId : itemDataHearing?.id
              }
              openModal={openModal}
              item={item}
              setItemData={setItemData}
              setOpenModal={setOpenModal}
              onDeleteItem={onDeleteItem}
              key={item.id}
              id={item.id}
              name={item.attendantName}
              typeAttendant={item.attendantRole}
              date={format(new Date(item.expectedDate), "dd/MM/yyyy")}
              timeVideo={ticksToTime(item.duration)}
              statusText={status(item.status).status}
              statusType={status(item.status).type}
            />
          ) : null
        )
      ) : (
        <ListEmpty title="oitiva" typeImg="oitiva"></ListEmpty>
      )}
    </HearingListContainer>
  );
};
