import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 1280px) {
    width: 100%;
    height: 720px;
  }
`;

export const ContainerLIneOne = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  align-items: flex-start;
  /* height: 100%; */
  width: 100%;
  padding: 4px 20px 0 8px;
  gap: 2px;
`;

export const Slice = styled.div<{ percentage: number; color: string }>`
  position: absolute;
  width: 50%;
  height: 100%;
  clip-path: ${({ percentage }) =>
    percentage >= 50
      ? `polygon(0 0, 100% 0, 100% 100%, ${100 - percentage}% 100%, 50% 50%)`
      : `polygon(0 0, ${percentage}% 0, 50% 50%, ${percentage}% 100%, 0 100%)`};
  transform-origin: ${({ percentage }) =>
    percentage >= 50 ? "0 50%" : "100% 50%"};
  transform: ${({ percentage }) =>
    percentage >= 50
      ? `rotate(${(percentage - 50) * 3.6}deg)`
      : `rotate(${-180 + percentage * 3.6}deg)`};
  background-color: ${({ color }) => color};
`;
