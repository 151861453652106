export interface IDropDown {
  name: string;
  id: number;
  selected?: boolean;
}

// Provedor 1 => Digitro
// Provedor 2 => Google
// Provedor 3 => OpenAI
// Provedor 4 => Digitro com transcrição com oradores
export const transcriptionProviderList: Array<IDropDown> = [
  { name: "Provedor 1", id: 1 },
  { name: "Provedor 2", id: 2 },
  { name: "Provedor 3", id: 3 },
  { name: "Provedor 4", id: 4 },
];
