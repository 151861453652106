import React, { useEffect, useState } from "react";

import { FiEye, FiEyeOff } from "react-icons/fi";

import {
  Container,
  ContainerIcon,
  ContainerInput,
  InputText,
  Label,
} from "./styles";
import { stringFormat } from "../../utils/stringFormat";
import { CircleBadge } from "../Badges/Circle";

type IInput = {
  id?: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  label: string;
  value?: string | number;
  icon?: boolean;
  maxLength?: number;
  mt?: string;
  type?: "text" | "number";
  error?: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: string) => unknown;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onClick?: any;
  startFocus?: boolean;
  ref?: any; //HTMLInputElement
  pattern?: any;
  onKeyPress?: (e: any) => unknown;
};

export const InputGeneral: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  error = false,
  type = "text",
  setType,
  icon = false,
  width,
  mt,
  height,
  required,
  marginLeft,
  placeholder,
  onClick,
  pattern,
  startFocus = true,
  onKeyPress,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [focusInputError, setFocusInputError] = useState<boolean>(false);

  const activeFocus = () => {
    setFocus(true);
    setFocusInputError(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
    setFocusInputError(false);
  };

  const inputRef = React.useRef<any>(null);

  useEffect(() => {
    if (startFocus) {
      focusInput();
    } else {
      blurInput();
    }
  }, []);

  function focusInput() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function blurInput() {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  function verifyLastCharacterEmpty(target: string) {
    if (target) {
      if (typeof target === "string") {
        let res = target.charAt(target.length - 1);
        if (stringFormat.isEmptyOrSpaces(res)) {
          return true;
        }
        return false;
      }
    }
  }

  return (
    <>
      <Container
        marginTop={mt}
        style={{ marginLeft: marginLeft, width: width }}
      >
        <Label>{label}</Label>
        {required && !disabled && (
          <CircleBadge radius="50px" widthHeigt="8px" />
        )}
        <ContainerInput
          width={width}
          height={height}
          notError={false}
          error={error}
          focus={focus}
        >
          <InputText
            id={id}
            maxLength={maxLength}
            onFocus={activeFocus}
            onBlur={inactiveFocus}
            autoComplete="on"
            autoCapitalize="off"
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            value={value}
            onClick={onClick}
            required={required}
            ref={inputRef}
            pattern={pattern}
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={onKeyPress}
          />
          {icon && setType && (
            <ContainerIcon
              onClick={() => setType(type === "text" ? "password" : "text")}
              type="button"
            >
              {type === "text" ? (
                <FiEyeOff color="#7887A9" size={20} />
              ) : (
                <FiEye color="#7887A9" size={20} />
              )}
            </ContainerIcon>
          )}
        </ContainerInput>
      </Container>
    </>
  );
};
