import { shade } from "polished";
import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

interface ButtonProps {
  backgroundButton?: "primary" | "secondary";
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.backgroundButton !== "secondary"
      ? stylesGlobalDefault.blue
      : stylesGlobalDefault.white};
  color: ${(props) =>
    props.backgroundButton !== "secondary"
      ? stylesGlobalDefault.white
      : stylesGlobalDefault.blue};
  border: ${(props) =>
    props.backgroundButton !== "secondary"
      ? 0
      : `2px solid ${stylesGlobalDefault.blue}`};
  width: 100%;
  height: 40px;
  border-radius: 50px;
  margin-top: 8px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  transition: 0.3s;

  &:hover {
    background: ${shade(0.2, stylesGlobalDefault.blueDark)};
    transition: 0.2s;
    color: ${stylesGlobalDefault.white};
    cursor: pointer;
  }
`;
