import React from "react";

import { BiUnlink } from "react-icons/bi";
import { Tooltip } from "react-tooltip";

import {
  Container,
  IconContainer,
  AttendantContainer,
  Name,
  Role,
  LeftContainer,
  IconArea,
} from "./styles";
import lowyerIcon from "../../assets/procedure/user.svg";
import videoIcon from "../../assets/procedure/video.svg";

export type IAttendantItem = {
  id: number;
  type: string;
  name: string;
  role: string;
  item: any;
  setSelectAttendant: (id: number) => unknown;
};

export const AttendantItem: React.FC<IAttendantItem> = ({
  id,
  item,
  type,
  name,
  role,
  setSelectAttendant,
}) => {
  return (
    <Container>
      <LeftContainer>
        <IconContainer lowyer={role.toLowerCase().includes("advogado")}>
          <img
            src={
              role.toLowerCase().includes("advogado") ? lowyerIcon : videoIcon
            }
            alt=""
          />
        </IconContainer>
        <AttendantContainer>
          <Name>{name}</Name>
          <Role>{role}</Role>
        </AttendantContainer>
      </LeftContainer>
      <IconArea className="icons">
        <div
          onClick={() => setSelectAttendant(item.procedureCustomerAttendantId)}
        >
          <BiUnlink id="desvincular" />
        </div>
      </IconArea>
      <Tooltip anchorSelect="#desvincular" place="top">
        Desvincular
      </Tooltip>
    </Container>
  );
};
