import styled from "styled-components";

import { stylesGlobalDefault } from "../../global/globalStyle";

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  cursor: pointer;
`;

export const DropdownTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownIcon = styled.div`
  margin-left: 0.5rem;
`;

export const DropdownMenu = styled.div`
  width: 100%;
  top: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${stylesGlobalDefault.white};
  overflow: hidden;
`;

export const DropdownOptionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
`;

export const LabalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 50%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: ${stylesGlobalDefault.black};
`;
