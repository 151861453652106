import {
  Button,
  Container,
  ContainerBody,
  ContainerFooter,
  ContainerTitle,
  Content,
  Title,
  styleDelete,
} from "./style";
import close from "../../assets/svg/close.svg";
import IconDelete from "../../assets/svg/IconDelete.svg";

interface ModalType {
  id?: string;
  title?: string;
  isEdit?: boolean;
  isOpen: boolean;
  children: JSX.Element;
  setIsOpen?: () => void;
  onClickDelete?: () => void;
  showIconDelete?: boolean;
  onClickAplicar?: (props: any) => void;
  scroll?: boolean;
  haveFooter?: boolean;
  buttonLabel?: string;
  showApplyButton?: boolean;
}

export default function ModalRight(props: ModalType) {
  let haveFooter = true;
  if (props.haveFooter === false) {
    haveFooter = false;
  }

  return props.isOpen ? (
    <Container id={props.id ? props.id : "modal_right"}>
      <Content>
        <ContainerTitle>
          <Title>{props.title ? props.title : "Titulo da página"}</Title>
          <img src={close} onClick={props.setIsOpen} alt="" />
        </ContainerTitle>
        <ContainerBody scroll={props.scroll}>{props.children}</ContainerBody>
        <ContainerFooter>
          {haveFooter ? (
            <>
              <Button id="button_aplicar" onClick={props.onClickAplicar}>
                <span>{props.buttonLabel ? props.buttonLabel : "Aplicar"}</span>
              </Button>
              {props.isEdit &&
                (props.showIconDelete ||
                  props.showIconDelete === undefined) && (
                  <img
                    src={IconDelete}
                    style={styleDelete}
                    onClick={props.onClickDelete}
                    alt=""
                  />
                )}
            </>
          ) : null}
        </ContainerFooter>
      </Content>
    </Container>
  ) : (
    <div />
  );
}
