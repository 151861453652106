export interface DeviceOption {
  id: string;
  name: string;
  label?: string;
  selected?: boolean;
}

export function formatDevices(
  devices: MediaDeviceInfo[],
  fallback: string
): DeviceOption[] {
  return devices.map((d) => ({
    id: d.deviceId,
    name: d.label || fallback,
    label: d.label || fallback,
    selected: false,
  }));
}
